import {createStore, applyMiddleware, compose} from 'redux'
import {logger, monitorReducerEnhancer} from "./middleware";
import thunk from 'redux-thunk'
import {rootReducer} from "../reducers";
import {history} from ".";
import {routerMiddleware} from 'connected-react-router'

const middlewareEnhancer = applyMiddleware(routerMiddleware(history), logger, thunk)
const composedEnhancers = compose(middlewareEnhancer, monitorReducerEnhancer)
export const store = createStore(rootReducer(history), undefined, composedEnhancers)
export const dispatch = store.dispatch
export const getState = store.getState
