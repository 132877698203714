import React from "react";
import styled from "styled-components/macro";
import StyledDialog from "../../elements/misc/StyledDialog";
import {
  Button,
  ButtonGroup,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useUser } from "../../actions/userActions";
import {
  useClubJoining,
  useClubsOfUser,
  useLastClub,
} from "../../actions/clubActions";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import ProgressSpinner from "../../elements/progress/ProgressSpinner";
import { clubSort } from "../../lib/Sorting";
import { useTranslation } from "react-i18next";

const ClubChangeDialog = ({ onClose, ...props }) => {
  const [user] = useUser();
  const { t } = useTranslation();
  const [currentClub] = useLastClub();
  const { clubs } = useClubsOfUser(user);
  const [joining, , { join }] = useClubJoining();
  const { push, replace } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const joinClub = (clubId) => async (e) => {
    await join(clubId, true);
    onClose?.();
    enqueueSnackbar(
      t(
        "components.club.ClubChangeDialog.club-changed",
        "Gruppe wurde gewechselt"
      )
    );
    replace("/");
    window.location.reload();
  };
  return (
    <>
      <StyledDialog onClose={onClose} {...props}>
        <DialogTitle>
          {t(
            "components.club.ClubChangeDialog.header-title",
            "Gruppe auswählen"
          )}
        </DialogTitle>
        <DialogContent>
          <ClubWrapper variant={"text"} fullWidth orientation={"vertical"}>
            {clubs.sort?.(clubSort.byNameAsc)?.map?.((club, idx) => {
              if ((club?.club_id ?? -1) === (currentClub?.club_id ?? 0)) {
                return null;
              }
              return (
                <Button
                  key={club?.club_id ?? idx}
                  fullWidth
                  onClick={joinClub(club?.club_id ?? 0)}
                >
                  {club?.name ?? "Club"}
                </Button>
              );
            })}
            {joining && (
              <LoaderLayer>
                <ProgressSpinner thickness={6} shadow />
              </LoaderLayer>
            )}
          </ClubWrapper>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            {t("actions.close", "Schließen").capital()}
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

const ClubWrapper = styled(ButtonGroup)`
  position: relative;
`;
const LoaderLayer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  place-items: center;
  background: rgba(0, 0, 0, 0.1);
`;

export default ClubChangeDialog;
