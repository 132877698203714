import { isFunction } from "lodash";
import { CLUB_ACTIONS } from "../actions/clubActions";

const defaultState = {
  list: [],
  last: JSON.parse(localStorage.getItem("last_club") || "null"),
};

export const clubReducer = (
  state = defaultState,
  { type, clubList, club, merge }
) => {
  switch (type) {
    case "RESET":
      return defaultState;
    case CLUB_ACTIONS.SET_LIST: {
      if (isFunction(clubList)) {
        clubList = clubList(state.list);
      }
      const _club = clubList.find((c) => c.club_id === state.last?.club_id);

      const next = {
        ...state,
        list: clubList,
      };
      if (_club) {
        return clubReducer(next, {
          type: CLUB_ACTIONS.SET_LAST,
          club: _club,
          merge: true,
        });
      }
      return next;
    }
    case CLUB_ACTIONS.SET_LAST:
      club = isFunction(club) ? club(state.last) : club;
      if (merge) {
        club = { ...state.last, ...club };
      }
      localStorage.setItem("last_club", JSON.stringify(club));
      return {
        ...state,
        last: club,
      };
    default:
      return state;
  }
};
