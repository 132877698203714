import { useClubJoining, useClubs } from "../../actions/clubActions";
import { useHistory } from "react-router";
import React from "react";
import { Paper } from "@material-ui/core";
import { FitnessCenter } from "@material-ui/icons";
import { OverlayLoader } from "../../tools";
import styled, { createGlobalStyle, keyframes } from "styled-components/macro";
import { Image_Adobe_3 as FitnessImg } from "../../images";
import GlobalStyle from "../../lib/style/GlobalStyle";
import { clubSort } from "../../lib/Sorting";
import { useUserLogout } from "../../actions/userActions";
import { APP_NAME } from "../../constants";
import ProgressSpinner from "../../elements/progress/ProgressSpinner";
import { Trans, useTranslation } from "react-i18next";

const EmptyList = () => {
  const [, , , { logoutUser }] = useUserLogout();
  const { push } = useHistory();
  const { t } = useTranslation();
  return (
    <EmptyNotice>
      <Trans
        t={t}
        i18nKey={"components.frame.ClubSelection.empty-notice"}
        defaults={
          "Aktuell arbeitet {{name}} mit einem Einladungssystem.<br />Bisher haben Sie noch keine Gruppenzuweisung erhalten."
        }
        components={{ br: <br /> }}
        values={{ name: APP_NAME }}
      />
      <LogoutLink
        href={"#"}
        onClick={(e) => {
          e.preventDefault();
          logoutUser().then(() => {
            push("/");
          });
        }}
      >
        {t("components.frame.ClubSelection.logout", "Ausloggen").capital()}
      </LogoutLink>
    </EmptyNotice>
  );
};
const EmptyNotice = styled.p`
  font-size: 1.5rem;
  text-align: center;
  color: white;
  margin-top: 100px;
  padding: 50px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  box-shadow: inset 0 0 1.5px white;
`;
const LogoutLink = styled.a`
  color: #c5d60c;
  text-decoration: none;
  display: block;
  margin-top: 40px;
`;

const ClubSelection = () => {
  const [clubs, loading, , { done }] = useClubs(true);
  const [joining, , { join }] = useClubJoining();
  const { push } = useHistory();
  const { t } = useTranslation();

  if (loading) {
    return (
      <>
        <GlobalStyle />
        <Background />
        <ProgressWrapper>
          <ProgressSpinner thickness={5} size={80} />
          <ProgressInfo>
            {t("components.frame.ClubSelection.loading-groups", "Lade Gruppen")}
          </ProgressInfo>
        </ProgressWrapper>
      </>
    );
  }
  return (
    <>
      <GlobalStyle />
      <Background />
      <Wrapper>
        <Title>
          {t(
            "components.frame.ClubSelection.selection-title",
            "Wähle deine Gruppe"
          )}
        </Title>
        {done && !clubs.length && <EmptyList />}
        <ClubList>
          {clubs.sort(clubSort.byNameAsc).map((club, i) => {
            return (
              <React.Fragment key={club?.club_id ?? i}>
                <Item
                  elevation={5}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    join(club.club_id, true).then(() => {
                      push("/");
                    });
                  }}
                >
                  <IconWrapper>
                    <ClubIcon />
                  </IconWrapper>
                  <ClubTitle>
                    {club?.name ||
                      t(
                        "components.frame.ClubSelection.unknown-club",
                        "unbekanntee Gruppe"
                      )}
                  </ClubTitle>
                </Item>
              </React.Fragment>
            );
          })}
        </ClubList>
        <OverlayLoader active={joining}>
          {t("components.frame.ClubSelection.loading-group", "Lade Gruppe")}
        </OverlayLoader>
      </Wrapper>
    </>
  );
};

const animation = keyframes`
  from {
      background-position: center top;
  }
    to {
        background-position: center bottom;
    }
`;

const ProgressWrapper = styled.div`
  display: grid;
  gap: 2em;
  justify-content: center;
  min-height: 100vh;
  align-content: center;
`;

const ProgressInfo = styled.h2`
  font-weight: bold;
  font-size: 1.5em;
`;

const Background = createGlobalStyle`
    body {
        background-image: url("${FitnessImg}");
        background-size: cover;
        min-height: 100vh;
        backdrop-filter: blur(5px) grayscale(.9) brightness(0.4);
        background-position: top;
        animation: ${animation} 200s infinite alternate;
    }
`;

const Wrapper = styled.div`
  display: grid;
  gap: 50px;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 50px 0;
  @media all and (max-width: 960px) {
    padding: 50px 10px;
    gap: 30%;
  }
`;

const Title = styled.h1`
  //background-image: linear-gradient(180deg, #c5d60c, #808c08);
  color: rgba(255, 255, 255, 0.8);
  display: inline-block;
  -webkit-background-clip: text;
  justify-self: center;
  font-size: 3rem;
  @media all and (max-width: 960px) {
    font-size: 2rem;
  }
`;

const ClubList = styled.div`
  display: grid;
  gap: 20px;
`;

const Item = styled(Paper)`
  background-color: rgba(255, 255, 255, 0.6) !important;
  box-shadow: inset 0 0 0 0.5px rgba(255, 255, 255, 0.8),
    0 3px 5px -1px rgb(0 0 0 / 20%), 0 5px 8px 0px rgb(0 0 0 / 14%),
    0 1px 14px 0px rgb(0 0 0 / 12%) !important;
  padding: 10px;
  cursor: pointer;
  display: grid;
  gap: 20px;
  align-items: center;
  grid-template-columns: min-content 1fr;
`;
const IconWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #8c7f72;
  box-shadow: inset 0 0 0 1.5px rgba(255, 255, 255, 0.9);
`;
const ClubIcon = styled(FitnessCenter)`
  color: rgba(0, 0, 0, 0.8);
`;

const ClubTitle = styled.h2`
  font-weight: 700;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.5rem;
  justify-self: center;
`;

export default ClubSelection;
