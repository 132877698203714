import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { useUser } from "../actions/userActions";
import { useLastClub } from "../actions/clubActions";
import { useHistory } from "react-router";
import ClubSelection from "./frame/ClubSelection";
import GlobalStyle from "../lib/style/GlobalStyle";
import ConfirmUserPage from "../pages/ConfirmUserPage";
import PoweredBy from "./frame/PoweredBy";

const UserFrame = ({ children }) => {
  const [user] = useUser();
  const [lastClub] = useLastClub(true);
  const h = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  if (!user) {
    if (lastClub) {
      h.push(`/club/${lastClub.slug}/login`);
    } else {
      h.push("/user");
    }
    return null;
  }

  if (!user.confirmed) {
    return <ConfirmUserPage user={user} />;
  }
  if (!lastClub) {
    return <ClubSelection />;
  }
  return (
    <>
      <GlobalStyle />
      {children}
      <PoweredBy />
    </>
  );
};

export const Stripe = styled.div`
  z-index: -1;
  width: 600px;
  position: fixed;
  top: -1px;
  height: calc(100vh + 2px);
  left: calc(50% - 300px);
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 150px 50px rgba(0, 0, 0, 0.8),
    inset 0 0 1.5px rgba(255, 255, 255, 0.6);

  @supports (backdrop-filter: blur(5px)) {
    box-shadow: 0 0 150px 50px rgba(0, 0, 0, 0.8),
      inset 0 0 0.5px rgba(255, 255, 255, 0.8);
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px) grayscale(0.5) brightness(0.7);
  }
  @media all and (max-width: 650px) {
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px) grayscale(0.5);
  }
`;

export default UserFrame;
