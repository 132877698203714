import {USER_ACTIONS} from "../actions/userActions";

const defaultState = {
	user: JSON.parse(localStorage.getItem('user') || 'null'),
	users: []
}

export const userReducer = (state = defaultState, {type, user, users}) => {
	switch (type) {
		case 'RESET': return defaultState
		case USER_ACTIONS.SET:
			user = typeof user === 'function' ? user(state.user) : user
			localStorage.setItem('user', JSON.stringify(user))
			return {
				...state,
				user
			}
		case USER_ACTIONS.SET_LIST:
			if (typeof users === 'function') users = users(state.users)
			return {
				...state,
				users
			}
		default:
			return state
	}
}