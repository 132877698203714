import React, { useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import { useHistory, useParams } from "react-router";
import GlobalStyle from "../../../lib/style/GlobalStyle";
import ProgressSpinner from "../../../elements/progress/ProgressSpinner";
import { usePasswordResetConfirm } from "../../../actions/userActions";
import { PageTitle, Styled } from "../../../tools";
import AppLogo from "../../../elements/svg/AppLogo";
import { Link } from "react-router-dom";
import { COLOR } from "../../../constants";
import { useTranslation } from "react-i18next";

const ResetPasswordConfirmPage = () => {
  const { token } = useParams();
  const { t } = useTranslation();
  const { push } = useHistory();
  const { ok, loading, error, confirm } = usePasswordResetConfirm();
  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    confirm(token);
  }, []);
  return (
    <>
      <PageTitle
        title={t(
          "components.pages.password.ResetPasswordConfirmPage.page-title",
          "Passwort bestätigen"
        )}
      />
      <GlobalStyle />
      <Background />
      <Container>
        <StyledAppLogo dual primary onClick={() => push("/")} />
        <LoaderWrapper active={loading}>
          <ProgressSpinner thickness={5} size={60} />
          <LoaderMessage>
            {t(
              "components.pages.password.ResetPasswordConfirmPage.loader-verifying",
              "Daten werden verifiziert"
            )}
          </LoaderMessage>
        </LoaderWrapper>
        <Content active={ok}>
          {t(
            "components.pages.password.ResetPasswordConfirmPage.description",
            "Ihr Passwort wurde zurückgesetzt und Sie sollten eine E-Mail mit dem neuen Passwort in Kürze erhalten."
          )}
          <LinkWrapper>
            <StyledLink to={"/"}>
              {t(
                "components.pages.password.ResetPasswordConfirmPage.startpage-label",
                "Zur Startseite"
              )}
            </StyledLink>
          </LinkWrapper>
        </Content>
        <Error active={Boolean(error)}>
          {error?.message ??
            t(
              "components.pages.password.ResetPasswordConfirmPage.general-error",
              "Ein Fehler ist aufgetreten."
            )}
        </Error>
      </Container>
    </>
  );
};

const Background = createGlobalStyle`
    body {
        background: #2b333f;
        color: rgba(255,255,255,.8);
		    min-height: 100vh;
    }
`;
const StyledAppLogo = styled(AppLogo)`
  height: 5em;
  justify-self: center;
  cursor: pointer;
`;
const Container = styled.div`
  display: grid;
  gap: 2em;
  min-height: 100vh;
  align-content: center;
  justify-content: center;
`;
const LoaderWrapper = styled(Styled("div", "active"))`
  display: none;
  &.active {
    display: grid;
  }
  gap: 1em;
  justify-content: center;
`;
const LoaderMessage = styled.p``;

const Content = styled(Styled("section", "active"))`
  display: none;
  &.active {
    display: inherit;
  }
  text-align: center;
`;
const Error = styled(Styled("section", "active"))`
  display: none;

  &.active {
    display: inherit;
  }

  text-align: center;
  color: #ca1313;
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${COLOR.PRIMARY};
`;
const LinkWrapper = styled.p`
  margin: 2em auto;
`;
export default ResetPasswordConfirmPage;
