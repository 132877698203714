import {
  format as dateFormat,
  formatDistance as dateFormatDistance,
  formatRelative as dateFormatRelative,
  formatDuration as dateFormatDuration,
  // formatD as dateFormatDurationStrict,
  formatDistanceToNow as dateFormatDistanceToNow,
  parse as dateParse,
  addSeconds,
  intervalToDuration,
} from "date-fns";
import { de } from "date-fns/locale";
import { instanceOf } from "prop-types";

const locales = {
  de: de,
};

export const setDefault = (locale = "de") => {
  window.__defaultLocale__ = locale;
};

const dater = (date) => (date instanceof Date ? date : new Date(date));

export const parse = (date, formatStr, referenceDate, options = {}) =>
  dateParse(date, formatStr, referenceDate ?? new Date(), {
    locale: locales[window.__defaultLocale__] ?? de,
    weekStartsOn: 1,
    ...options,
  });
export const format = (date, formatStr = "pp", options = {}) =>
  dateFormat(dater(date), formatStr, {
    locale: locales[window.__defaultLocale__] ?? de,
    ...options,
  });
export const formatDistance = (date, baseDate, options = {}) =>
  dateFormatDistance(dater(date), dater(baseDate), {
    locale: locales[window.__defaultLocale__] ?? de,
    ...options,
  });
export const formatRelative = (date, baseDate, options = {}) =>
  dateFormatRelative(dater(date), dater(baseDate), {
    locale: locales[window.__defaultLocale__] ?? de,
    weekStartsOn: 1,
    ...options,
  });
export const formatDuration = (duration, options = {}) => {
  // const method = options?.strict ?
  if (options?.simple) {
    duration.hours += duration.days * 24;
    return `${String(duration.hours).padStart(2, "0")}:${String(
      duration.minutes
    ).padStart(2, "0")}:${String(duration.seconds).padStart(2, "0")}`;
  }
  return dateFormatDuration(duration, {
    locale: locales[window.__defaultLocale__] ?? de,
    ...options,
  });
};
export const formatDurationOfSeconds = (seconds, options = {}) => {
  const start = new Date();
  const end = addSeconds(start, seconds);
  const duration = intervalToDuration({ start, end });
  return formatDuration(duration, options);
};
export const formatDistanceToNow = (date, options = {}) =>
  dateFormatDistanceToNow(dater(date), {
    locale: locales[window.__defaultLocale__] ?? de,
    ...options,
  });
