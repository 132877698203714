import React from "react";

import styled from "styled-components/macro";
import { Button, Tooltip } from "@material-ui/core";
import { SubdirectoryArrowLeft } from "@material-ui/icons";
import { ProgressBar } from "../../../../elements/progress";
import { useHistory } from "react-router";

const MediaHeader = ({ loading, club }) => {
  const { push } = useHistory();
  return (
    <>
      <StyledProgressBar loading={loading} color={"primary"} />
      <Header>
        <HeaderBar>
          <HeaderTitle>Mediathek - {club.name}</HeaderTitle>
          <HeaderActions>
            <Tooltip title={"Zur Startseite"}>
              <HeaderButton onClick={() => push("/")}>
                <SubdirectoryArrowLeft />
              </HeaderButton>
            </Tooltip>
          </HeaderActions>
        </HeaderBar>
      </Header>
    </>
  );
};

const StyledProgressBar = styled(ProgressBar)`
  background-color: #222 !important;

  > div {
    display: none;
    background-color: var(--color-primary);
  }

  position: sticky !important;
  top: 0;
  z-index: 2;
  opacity: 1 !important;

  &.loading {
    > div {
      display: inherit;
    }
  }
`;
const Header = styled.header`
  min-height: 3rem;
  background: #444;
  margin-top: -2px;
  padding: 0.8em 2.5em;
  box-shadow: 0 0 1px black, inset 0 0 2px gray;
  color: var(--color-primary);
  position: sticky;
  top: 2px;
  width: 100%;

  z-index: 2;
  @media (max-width: 600px) {
    padding: 0.8em 0.5em;
  }
`;
const HeaderBar = styled.div`
  display: flex;
  gap: 1em;
  justify-content: space-between;
`;
const HeaderTitle = styled.h1`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 1.5em;
  @media (max-width: 600px) {
    font-size: 1.1em;
  }
`;
const HeaderActions = styled.div``;
const HeaderButton = styled(Button)`
  color: white !important;
`;

export default MediaHeader;
