import React, { useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import Hls from "hls.js";
import StreamSelector from "./test/StreamSelector";

const playlist = `${window.location.origin}/stream/playlist.m3u8`;

const options = {
  debug: false,
  capLevelOnFPSDrop: true,
  capLevelToPlayerSize: true,
  startPosition: 0,
};

const Player = () => {
  const ref = React.createRef();
  const [hls, setHls] = useState(null);

  useEffect(() => {
    if (Hls.isSupported()) {
      console.debug("yey");
      hls?.destroy();
      const _hls = (window.hls = new Hls(options));
      _hls.loadSource(playlist);
      _hls.attachMedia(ref.current);
      // ref.current.play()

      setHls(_hls);
    } else {
      console.debug("nay");
    }
    return () => {
      hls?.destroy();
      setHls(null);
    };
  }, []);

  return (
    <>
      <Style />
      <VideoContainer>
        <Video ref={ref} controls />
        {hls && <StreamSelector player={ref} hls={hls} />}
      </VideoContainer>
    </>
  );
};

const Style = createGlobalStyle`
	html, body {
		padding: 0;
		margin: 0;
		overflow: hidden;
	}
`;

const VideoContainer = styled.div`
  position: relative;

  video {
    max-width: 100vw;
    max-height: 100vh;
    background: black;
  }
  .hls-stream-selector {
    opacity: 0;
    top: -2em;
    transition: 400ms;
    backgound: rgba(0, 0, 0, 0.8);
  }
  &:hover {
    .hls-stream-selector {
      opacity: 1;
      top: 0;
      right: 0;
    }
  }
`;
const Video = styled.video`
  max-width: 100%;
  width: 100%;
`;

const StreamTest = ({}) => {
  return (
    <>
      <Wrapper>
        <Section>
          <Player />
        </Section>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div``;
const Section = styled.div`
  max-width: 2000px;
  background: #f5f5f5;
  margin: 0 auto;
  //padding: 2rem;
`;

export default StreamTest;
