import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { Tv } from "@material-ui/icons";
import { Styled } from "../../../tools";
import { flatten } from "lodash";

export const TimeItem = ({
  date,
  children,
  reverse,
  gapSpacing,
  timeOverContent,
  highlight,
  useScroll,
}) => {
  const Wrapper = useScroll ? ScrollWrapper(ItemWrapper) : ItemWrapper;
  let data = [
    timeOverContent ? null : <Time key={"time"}>{date}</Time>,
    <Path key={"path"}>
      <IconWrapper>
        <Tv />
      </IconWrapper>
      <Separator />
    </Path>,
    <ItemContent key={"content"} gap={gapSpacing}>
      {timeOverContent && <Time>{date}</Time>}
      <ContentWrapper>{children}</ContentWrapper>
    </ItemContent>,
  ].filter(Boolean);
  if (reverse) data = data.reverse();
  return <Wrapper reverse={reverse}>{data}</Wrapper>;
};

const ScrollWrapper = (Component) =>
  function Comp({ ...props }) {
    const [highlight, setHighlight] = useState(false);
    const ref = useRef();
    const handler = () => {
      const low = window.innerHeight / (4 / 3);
      const high = window.innerHeight / 2;
      const pos = ref?.current?.getBoundingClientRect?.() || {};
      const top = pos?.top || 0;
      const bottom = pos?.bottom || 0;
      const between = !(bottom < high || top > low);
      setHighlight(between);
    };
    useEffect(() => {
      handler();
      window.addEventListener("scroll", handler, { passive: true });
      return () => {
        window.removeEventListener("scroll", handler);
      };
    }, []);

    return <Component highlight={highlight} ref={ref} {...props} />;
  };

const Timeline = ({
  children,
  alternates,
  leftSide,
  gap,
  gapSpacing,
  timeOverContent,
  useScroll,
  ...props
}) => {
  return (
    <Wrapper gap={gap} {...props}>
      {children &&
        flatten(children).map((child, index) => {
          let reverse = Boolean(!alternates && leftSide);
          if (alternates) {
            reverse = Boolean(leftSide ? index % 2 : (index + 1) % 2);
          }
          return React.cloneElement(child, {
            key: index,
            reverse,
            classes: { reverse: reverse },
            gapSpacing,
            useScroll,
          });
        })}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: grid;
  gap: ${(p) =>
    p.gap ? (typeof p.gap === "string" ? p.gap : `${p.gap}px`) : "20px"};
`;
const ItemWrapper = styled(
  Styled("div", "reverse", "timeOverContent", "highlight")
)`
  display: grid;
  justify-content: center;
  grid-template-columns: 300px auto 300px;
  gap: 30px;
  transition: transform 0.8s ease-in-out;
  @media all and (max-width: 960px) {
    gap: 15px;
  }
`;
const Time = styled.div`
  display: flex;
  justify-content: flex-end;
  text-shadow: 0 0 0.3rem black;

  ${ItemWrapper}.reverse & {
    justify-content: flex-start;
  }

  transform: translateY(5px);
`;
const Path = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const IconWrapper = styled.div`
  flex-shrink: 0;
  margin-bottom: 10px;
  border-radius: 50%;
  background: #c5d60c;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0.5px 0.5px rgba(255, 255, 255, 0.8), 0 2px 4px black;
  transition: opacity 0.6s ease-in-out 0.1s, transform 0.6s ease-in-out 0.1s;
  z-index: 2;
  opacity: 0;

  ${ItemWrapper}.highlight & {
    transform: translateY(50%);
    opacity: 1;

    svg {
      transition: transform 0.6s ease-in-out 0.1s;
      transform: scale(1.2);
    }
  }
`;
const Separator = styled.div`
  display: flex;
  flex-grow: 1;
  width: 2px;
  align-items: stretch;
  background: white;
  border-radius: 1px;
  transition: transform 0.6s ease-in-out 0.1s;
  transform: scaleY(2);
`;
const ItemContent = styled.div`
  width: 300px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${(p) =>
    p.gap ? (typeof p.gap === "string" ? p.gap : `${p.gap}px`) : "30px"};

  ${ItemWrapper}.reverse & {
    justify-content: flex-end;
  }

  transition: transform 0.8s ease-in-out;
  ${ItemWrapper}.highlight & {
    transform: scale(1.1) translateX(-5px);
    z-index: 3;
  }
  ${ItemWrapper}.reverse.highlight & {
    transform: scale(1.1) translateX(5px);
    z-index: 3;
  }
`;

const ContentWrapper = styled.div``;

export default Timeline;
