import React, { useEffect, useMemo } from "react";
import UserFrame, { Stripe } from "../../UserFrame";
import Background from "../../frame/Background";
import Header from "../../frame/Header";
import { usePureEvent } from "../../../actions/eventActions";
import { useHistory, useParams } from "react-router";
import bgs from "../../../images/fitnessBackgrounds";
import EventContent from "./EventContent";
import { getImageUrl, getIndex } from "../../../lib/methods";
import { useLastClub } from "../../../actions/clubActions";
import { PageTitle } from "../../../tools";
import { useTranslation } from "react-i18next";

const EventView = ({ hideHeader }) => {
  const { event_id, bg: bgId } = useParams();
  const [club] = useLastClub();
  const { t } = useTranslation();
  const bgIndex = useMemo(() => {
    return parseInt(bgId) || Math.floor(Math.random() * bgs.length);
  }, [bgId]);
  const bg = club?.event_image ? getImageUrl(club?.event_image) : bgs[bgIndex];
  const { replace } = useHistory();
  const [event, loading, , { load, setEvent }] = usePureEvent(
    Number(event_id),
    true
  );
  useEffect(() => {
    load();
  }, []);
  const title = t(
    "components.pages.event.EventView.page-title",
    "Kurs - {{name}}",
    { name: event?.course?.name ?? "..." }
  );
  return (
    <>
      <PageTitle title={title} />
      <Background
        background={bg}
        filter={"sepia(0.5) grayscale(0.5) brightness(0.55) blur(1px)"}
      />
      <UserFrame>
        <Stripe />
        {!hideHeader && <Header />}
        <EventContent
          event={event}
          loading={loading}
          updateEvent={setEvent}
          onChangeBackground={() =>
            replace(`/event/${event_id}/${getIndex(bgIndex, 1)}`)
          }
        />
      </UserFrame>
    </>
  );
};

export default EventView;
