import React from "react";
import styled from "styled-components/macro";
import { Slide } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { COLOR } from "../../../../constants";
import cn from "clsx";

const MediaPagination = ({ className, data, ...props }) => {
  return (
    <>
      <PaginationWrapper className={"pagination-wrapper"}>
        <Slide direction={"up"} in>
          <PaginationInner
            onClick={(e) => e.stopPropagation()}
            className={"pagination-wrapper-inner"}
          >
            <Pagination
              className={cn(className, "media-pagination")}
              shape={"rounded"}
              count={data?.pages ?? 1}
              showFirstButton
              showLastButton
              page={data?.page ?? 1}
              {...props}
            />
          </PaginationInner>
        </Slide>
      </PaginationWrapper>
    </>
  );
};

const PaginationWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 2em;
  background: linear-gradient(#0000, #0008);
  button,
  div {
    color: white !important;
  }
  button:hover {
    background: #fff2;
  }
  .Mui-selected {
    background: ${COLOR.PRIMARY}99 !important;
    color: black !important;
  }
`;
const PaginationInner = styled.div`
  background: #222;
  padding: 0.5em;
  border-radius: 0.5em;

  box-shadow: 0 0 1px black, inset 0 0 2px gray;
`;
export const MediaPaginationSpacer = styled.div`
  height: 4rem;
`;
export default MediaPagination;
