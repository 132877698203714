import React from "react";
import { CircularProgress, Fade } from "@material-ui/core";
import styled from "styled-components/macro";
import CenteredPortal from "../misc/CenteredPortal";
import * as PropTypes from "prop-types";
import cn from "clsx";

const ProgressSpinner = styled(({ shadow, className, color, ...props }) => (
  <CircularProgress className={cn({ shadow }, className)} {...props} />
))`
  justify-self: center;
  ${(p) =>
    `width: ${p.size || 24}px !important ;height: ${
      p.size || 24
    }px !important;`}
  svg {
    border-radius: 50% !important;
    color: ${(props) => props.color || "#c5d60c"} !important;
    circle {
      stroke-linecap: round;
    }
    ${(p) =>
      `width: ${p.size || 24}px !important; height: ${
        p.size || 24
      }px !important;`}
  }
  &.shadow circle {
    filter: drop-shadow(0 0 3px currentColor);
  }
`;
ProgressSpinner.propTypes = {
  color: PropTypes.string,
  shadow: PropTypes.bool,
};

export default ProgressSpinner;

export const PortalProgressSpinner = ({ portalProps, bg, ...props }) => {
  return (
    <CenteredPortal bg={bg}>
      <Fade in>
        <ProgressSpinner size={80} {...props} />
      </Fade>
    </CenteredPortal>
  );
};
PortalProgressSpinner.propTypes = {
  color: PropTypes.string,
  shadow: PropTypes.bool,
  bg: PropTypes.oneOf(["dark", "light"]),
};
