import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { useTokenEvent } from "../actions/tokenActions";
import { Link, Typography } from "@material-ui/core";
import { Flex } from "../lib";
import { SSE, SSE_GlobalStateChange } from "../actions";
import GlobalStyle from "../lib/style/GlobalStyle";
import UserFrame, { Stripe } from "../components/UserFrame";
import EventContent from "../components/pages/event/EventContent";
import AppIcon from "../components/frame/AppIcon";
import styled from "styled-components/macro";
import bgs from "../images/fitnessBackgrounds";
import Background from "../components/frame/Background";
import { getIndex } from "../lib/methods";
import { COLOR } from "../constants";
import { useUser } from "../actions/userActions";
import { useLastClub } from "../actions/clubActions";
import Header from "../components/frame/Header";
import { Trans, useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

const PoweredByBar = () => {
  const { t } = useTranslation();
  return (
    <Flex
      center
      style={{ position: "fixed", bottom: 0, left: 0, right: 0, padding: 10 }}
    >
      <Typography variant={"body2"} component={"div"}>
        <PoweredWrapper>
          <Trans
            t={t}
            i18nKey={"view.index.powered-by"}
            defaults={"<label>Powered by</label> <link>liveDo</link>"}
            components={{
              label: <PoweredLabel />,
              link: (
                <PoweredLink
                  target={"itholics"}
                  href={"https://www.itholics.de"}
                />
              ),
            }}
          />
        </PoweredWrapper>
      </Typography>
    </Flex>
  );
};
const PoweredWrapper = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: auto auto auto;
  justify-content: center;
  justify-items: center;
`;
const PoweredLabel = styled.span`
  color: rgba(255, 255, 255, 0.8);
`;
const PoweredLink = styled(Link)`
  color: ${COLOR.PRIMARY} !important;
`;

export const TokenViewRoutes = () => {
  const { token_id, bg: bgId } = useParams();
  const { event, loading, setEvent, error } = useTokenEvent(token_id, true);
  const bgIndex = useMemo(
    () => parseInt(bgId) || Math.floor(Math.random() * bgs.length),
    [bgId]
  );
  const bg = bgs[bgIndex];
  const { replace } = useHistory();
  const [user] = useUser();
  const [lastClub] = useLastClub();
  const showInternal = user && user.confirmed && lastClub;
  const { enqueueSnackbar } = useSnackbar();
  return (
    <>
      <GlobalStyle />
      <Background
        background={bg}
        filter={"sepia(0.5) grayscale(0.5) brightness(0.55) blur(1px)"}
      />
      <Stripe />
      {showInternal ? (
        <>
          <SSE
            onStateChange={SSE_GlobalStateChange}
            notistack={enqueueSnackbar}
          />
          <UserFrame>
            <Stripe />
            <Header />
            <EventContent
              token={token_id}
              event={event}
              loading={loading}
              updateEvent={setEvent}
              onChangeBackground={() =>
                replace(`/view/${token_id}/${getIndex(bgIndex, 1)}`)
              }
            />
          </UserFrame>
        </>
      ) : (
        <>
          <SSE
            onStateChange={SSE_GlobalStateChange}
            debug={console.debug}
            section={"Token"}
            url={`sse/stream/token/${token_id}`}
            notistack={enqueueSnackbar}
          />
          <MiniHeader>
            <AppIcon />
          </MiniHeader>
          <EventContent
            token={token_id}
            event={event}
            updateEvent={setEvent}
            loading={loading}
            onChangeBackground={() =>
              replace(`/view/${token_id}/${getIndex(bgIndex, 1)}`)
            }
          />
        </>
      )}
      <PoweredByBar />
    </>
  );
};
const Pre = styled.pre`
  background: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  border-radius: 1rem;
  margin: 1rem 1rem 4rem;
`;
const MiniHeader = styled.header`
  display: flex;
  justify-content: center;
  margin: 0.5rem;
`;
