import cn from "clsx";
import PropTypes from "prop-types";
import Flex from "../lib/Flex";
import React, { useEffect } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
  Slide,
  Zoom,
} from "@material-ui/core";
import { getOpacity } from "../lib/methods";
import { useHistory, useRouteMatch } from "react-router";
import { useInterval } from "../actions";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

export { default as DateTimePicker } from "./datepicker/datepicker";
export { default as HlsPlayer } from "./player/hls-player";
export { default as JsPlayer } from "./player/videojs-player";

export { default as DashPlayer } from "./player/dash-player";

export const Styled = (Component = "div", ...state) =>
  React.forwardRef(({ classes, className, ...props }, ref) => {
    classes = classes || {};
    // const source = { ...props };
    for (const s of state) {
      if (s in props) {
        classes[s] = props[s];
        delete props[s];
      }
      if (
        typeof Component === "object" &&
        "defaultProps" in Component &&
        s in Component.defaultProps
      ) {
        classes[s] = Component.defaultProps[s];
      }
    }
    return (
      <Component ref={ref} className={cn(classes, className)} {...props} />
    );
  });

const LoaderButtonWrapper = styled(Styled(Button, "fakeload"))`
  position: relative;
  &.fakeload {
    pointer-events: none;
  }
`;
const LoaderButtonContent = styled(Styled("span", "hidden", "fluid"))`
  &.hidden {
    opacity: 0;
  }
  &.fluid {
    display: none;
  }
`;
const LoaderButtonSpinner = styled(Styled("div", "fluid", "visible"))`
  display: none;
  &:not(.fluid) {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  &.visible {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const LoaderButton = ({
  loading,
  disabled,
  children,
  fluid,
  spinnerProps: sprops,
  ...props
}) => {
  const spinnerProps = {
    size: 24,
    ...sprops,
  };
  return (
    <LoaderButtonWrapper {...props} fakeload={loading} disabled={disabled}>
      <LoaderButtonContent fluid={fluid} hidden={loading}>
        {children}
      </LoaderButtonContent>
      <LoaderButtonSpinner fluid={fluid} visible={loading}>
        <Spinner {...spinnerProps} />
      </LoaderButtonSpinner>
    </LoaderButtonWrapper>
  );
};
LoaderButton.defaultProps = {
  fluid: false,
};

export const DialogTransition = React.forwardRef((props, ref) => (
  <Slide direction={"up"} ref={ref} {...props} />
));

export const LoaderIcon = ({
  className,
  color,
  size,
  style,
  heavy,
  opacity,
  ...props
}) => (
  <i
    className={cn("pi pi-spin pi-spinner", className)}
    style={{
      color,
      fontSize: size,
      fontWeight: heavy && "bold",
      opacity: getOpacity(opacity),
      ...style,
    }}
    {...props}
  />
);
LoaderIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  heavy: PropTypes.bool,
  opacity: PropTypes.number,
};

export const Spinner = ({
  id,
  style,
  className,
  color,
  thickness,
  size,
  ...props
}) => (
  <CircularProgress
    id={id}
    thickness={thickness}
    style={style}
    size={size}
    className={className}
    color={color}
    {...props}
  />
);
Spinner.propTypes = {
  id: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  thickness: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  spinner: PropTypes.node,
  color: PropTypes.oneOf(["inherit", "primary", "secondary"]),
  duration: PropTypes.string,
};
Spinner.defaultProps = {
  // size: 60
};

export const OverlayLoader = ({
  children,
  id,
  spinnerStyle,
  spinnerClassName,
  thickness,
  color,
  size,
  active,
  spinner,
  zIndex,
  style,
  textColor,
  textStyle,
  opacity,
  light: inverse,
}) => {
  const extra = {};
  if (!active) {
    extra.display = "none";
  }
  if (!textColor) {
    textColor = inverse ? "#444" : "#888";
  }
  const bg = inverse ? 255 : 0;
  return (
    <Flex
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: `rgba(${bg}, ${bg}, ${bg}, ${getOpacity(opacity)})`,
        zIndex,
        ...style,
        ...extra,
      }}
      center
      middle
    >
      <Flex column>
        <Flex center middle>
          {spinner || (
            <Spinner
              id={id}
              style={spinnerStyle}
              className={spinnerClassName}
              thickness={thickness}
              size={size}
              color={color}
            />
          )}
        </Flex>
        <Flex
          center
          middle
          style={{
            color: textColor,
            marginTop: 15,
            fontSize: "1.1em",
            ...textStyle,
          }}
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
};

OverlayLoader.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  spinnerStyle: PropTypes.object,
  spinnerClassName: PropTypes.string,
  thickness: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  spinner: PropTypes.node,
  color: PropTypes.oneOf(["inherit", "primary", "secondary"]),
  duration: PropTypes.string,
  active: PropTypes.bool,
  textColor: PropTypes.string,
  textStyle: PropTypes.object,
  opacity: PropTypes.number,
  light: PropTypes.bool,
};

OverlayLoader.defaultProps = {
  size: 60,
  opacity: 90,
  light: false,
};

export const LinkListItem = ({ to, exact, ignore, onClick, ...props }) => {
  const { push } = useHistory();
  const active =
    useRouteMatch({
      path: to,
      exact,
    }) && !ignore;

  const clickEvent = (e) => {
    onClick && onClick(e);
    if (to && !e.isDefaultPrevented()) {
      push(to);
    }
  };
  return <ListItem selected={active} onClick={clickEvent} {...props} />;
};

const Transition = React.forwardRef((props, ref) => (
  <Zoom in ref={ref} {...props} />
));
export const ConfirmDialog = ({
  title,
  children,
  onCancel,
  onConfirm,
  closeOnConfirm,
  loading,
  cancelText,
  confirmText,
  disableOnLoading,
  ...props
}) => {
  const { t: t2 } = useTranslation();
  // t('tools.index.confirm-default-ok', 'Ja')
  // t('tools.index.confirm-default-cancel', 'Abbrechen')
  return (
    <Dialog {...props} TransitionComponent={Transition}>
      {title &&
        (typeof title === "string" ? (
          <DialogTitle>{title}</DialogTitle>
        ) : (
          title
        ))}
      <DialogContent>
        <div style={{ padding: 20, margin: ".5em" }}>{children}</div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          disabled={Boolean(loading && disableOnLoading)}
        >
          {t2([cancelText, "tools.index.confirm-default-cancel"]).capital()}
        </Button>
        <LoaderButton
          loading={loading}
          color={"primary"}
          onClick={(e) => {
            onConfirm(e);
            closeOnConfirm && onCancel(e);
          }}
        >
          {t2([confirmText, "tools.index.confirm-default-ok"]).capital()}
        </LoaderButton>
      </DialogActions>
    </Dialog>
  );
};
ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  closeOnConfirm: PropTypes.bool,
  loading: PropTypes.bool,
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  disableOnLoading: PropTypes.bool,
};
ConfirmDialog.defaultProps = {
  open: false,
  closeOnConfirm: false,
  loading: false,
  cancelText: "tools.index.confirm-default-cancel",
  confirmText: "tools.index.confirm-default-ok",
  disableOnLoading: true,
};

const _pageTitles = [];
export const PageTitle = ({ base, title }) => {
  useEffect(() => {
    document.title = title ? `${base} | ${title}` : base;
    _pageTitles.push([base, title]);
    return () => {
      _pageTitles.pop();
      const [_base, title] = _pageTitles[_pageTitles.length - 1] ?? [base, ""];
      document.title = title ? `${_base} | ${title}` : _base;
    };
  }, []);
  useEffect(() => {
    document.title = title ? `${base} | ${title}` : base;
    _pageTitles[_pageTitles.length - 1] = [base, title];
  }, [title]);
  return null;
};
PageTitle.defaultProps = {
  base: "liveDo",
};
export const AdminTitle = ({ title, ...props }) => (
  <PageTitle title={title} {...props} />
);
AdminTitle.defaultProps = {
  base: "liveDo.admin",
};

export const IntervalContainer = ({ interval, children }) => {
  useInterval(interval);
  return children;
};
IntervalContainer.defaultProps = {
  interval: 10000,
};

export const Spacer = styled.div`
  flex-grow: ${(p) => p.$weight || 100};
`;
