import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { PageTitle } from "../../../tools";
import Background from "../../frame/Background";
import { Image_Adobe_1 as bg } from "../../../images";
import { useTranslation } from "react-i18next";
import GlobalStyle from "../../../lib/style/GlobalStyle";
import { Button, Grow, Slide, Tooltip, Zoom } from "@material-ui/core";
import { useHistory } from "react-router";
import { useLastClub } from "../../../actions/clubActions";
import { Pagination } from "@material-ui/lab";
import { COLOR } from "../../../constants";
import { Close, SubdirectoryArrowLeft } from "@material-ui/icons";
import { ProgressBar } from "../../../elements/progress";
import { mediaUri, usePublicMedia } from "../../../actions/mediaActions";
import MediaHeader from "./layout/MediaHeader";
import MediaFrame from "./layout/MediaFrame";
import MediaList from "./components/MediaList";
import MediaListItem from "./components/MediaListItem";
import MediaPagination, {
  MediaPaginationSpacer,
} from "./components/MediaPagination";
import MediaPlayback, {
  MediaPlaybackOverlay,
} from "./components/MediaPlayback";

const MediaPage = ({}) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(null);
  const { t } = useTranslation();
  const { push } = useHistory();
  const [club] = useLastClub();
  const { loading: fetching, data, load } = usePublicMedia();
  useEffect(() => {
    load();
  }, []);
  return (
    <>
      <PageTitle
        title={t(
          "components.pages.bookmarked.MediaPage.page-title",
          "Mediathek"
        )}
      />
      <MediaFrame>
        <MediaHeader loading={loading} club={club} />
        <Wrapper>
          <MediaList>
            {(data?.videos ?? []).map((v) => (
              <MediaListItem
                key={v.stream_id}
                video={v}
                onView={() => push(mediaUri.view(v.stream_id))}
              />
            ))}
          </MediaList>
        </Wrapper>
        <MediaPaginationSpacer />
        {data?.pages > 1 && (
          <MediaPagination
            data={data}
            onChange={(_, page) => load(page).then(() => window.scrollTo(0, 0))}
          />
        )}
      </MediaFrame>
      <MediaPlaybackOverlay open={null !== show}>
        <MediaPlayback
          video={show}
          backIcon={Close}
          onClose={setShow.bind(null, null)}
        />
      </MediaPlaybackOverlay>
    </>
  );
};
const Wrapper = styled.main`
  color: white;
  padding: 2em;

  @media (max-width: 600px) {
    padding: 0.5em;
    font-size: 0.8órem;
  }
`;
export default MediaPage;
