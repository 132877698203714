import { uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET, POST, PUT } from "../lib";
import { useError } from "./errorActions";

export const ROOM_ACTIONS = {
  RESET: "ROOMS_RESET",
  SET_LIST: "ROOMS_SET_LIST",
};

export const roomState__setList = (rooms = []) => ({
  type: ROOM_ACTIONS.SET_LIST,
  rooms,
});
export const roomState__add = (...room) =>
  roomState__setList((rooms) => uniqBy([...room, ...rooms], "room_id"));
export const roomstate__remove = (...room) =>
  roomState__setList((rooms) => {
    // rooms.filter(r => !rooms.find(r.room_id === room.room_id || room))
    const remove = new Set(room.map((r) => r.room_id || room));
    return rooms.filter((r) => !remove.has(r.room_id));
  });

export const useRooms = (clubId = null) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const rooms = useSelector((state) => state.rooms.rooms);
  const dispatch = useDispatch();

  const load = async (club_id, throws = null) => {
    try {
      setLoading(true);
      const _rooms = await GET("/room/all");
      dispatch(roomState__setList(_rooms));
      return _rooms;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    clubId && load(clubId);
    // eslint-disable-next-line
  }, [clubId]);

  return [rooms, loading, error, { load, clearError }];
};

export const useRoom = (room_id = null, init = false) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  // eslint-disable-next-line
  const room = useSelector((state) =>
    room_id ? state.rooms.rooms.find((r) => r.room_id == room_id) : null
  );
  const dispatch = useDispatch();

  const load = async (room_id, throws = null) => {
    try {
      if (room) {
        return room;
      }
      setLoading(true);
      const res = await GET(`/room/id/${room_id}`);
      dispatch(roomState__add(res));
      return res;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init && room_id && load(room_id);
    // eslint-disable-next-line
  }, [init, room_id]);

  return [room, loading, error, { load, clearError }];
};

export const useRoomCreation = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const rooms = useSelector((state) => state.rooms.rooms);
  const dispatch = useDispatch();

  const create = async (params, throws = null) => {
    try {
      setLoading(true);
      const newRoom = await PUT("/room", params);
      dispatch(roomState__add(newRoom));
      return newRoom;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return [rooms, loading, error, { create, clearError }];
};

export const useRoomUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const update = async (room, throws = null) => {
    try {
      setLoading(true);
      const res = await POST("/room", room);
      dispatch(roomState__add(res));
      return res;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  const updateParameters = async (room_id, parameters, throws = null) => {
    try {
      setLoading(true);
      const res = await POST("/room/parameters", { room_id, parameters });
      dispatch(roomState__add(res));
      return res;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  const updateHlsOptions = async (room_id, hls_options, throws = null) => {
    try {
      setLoading(true);
      const res = await POST("/room/hls-options", { room_id, hls_options });
      dispatch(roomState__add(res));
      return res;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  const updateAdaptiveStream = async (
    room_id,
    adaptive_stream,
    throws = null
  ) => {
    try {
      setLoading(true);
      const res = await POST("/room/adaptive-stream", {
        room_id,
        adaptive_stream,
      });
      dispatch(roomState__add(res));
      return res;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  return [
    loading,
    error,
    {
      update,
      clearError,
      updateParameters,
      updateHlsOptions,
      updateAdaptiveStream,
    },
  ];
};

export const useRoomParametersUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const update = async (room_id, parameters = "", throws = null) => {
    try {
      setLoading(true);
      const res = await POST("/room/paramters", { room_id, parameters });
      dispatch(roomState__add(res));
      return res;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return [loading, error, { update, clearError }];
};

export const useRoomConfigurationLoader = (init = null) => {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [resetting, setResetting] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [config, setConfig] = useState({});

  const load = async (roomId, throws = null) => {
    try {
      setLoading(true);
      const conf = await GET("/room/configuration/" + roomId);
      setConfig(conf);
      setInitialized(true);
      return conf;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  const save = async (roomId, config, throws = null) => {
    try {
      setSaving(true);
      const conf = await POST("/room/configuration/" + roomId, config);
      setConfig(conf);
      return conf;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setSaving(false);
    }
  };

  const reset = async (roomId, throws = null) => {
    try {
      setResetting(true);
      const conf = await POST(`/room/configuration/${roomId}/reset`);
      setConfig(conf);
      return conf;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setResetting(false);
    }
  };

  useEffect(() => {
    if (init) {
      load(init);
    }
  }, [init]);

  return {
    loading,
    saving,
    resetting,
    initialized,
    error,
    clearError,
    config,
    load,
    setConfig,
    save,
    reset,
  };
};

export const useRoomConfigurationMeta = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [meta, setMeta] = useState(null);

  const load = async (roomId, throws = null) => {
    try {
      if (loading) {
        return;
      }
      if (meta) {
        return meta;
      }
      setLoading(true);
      const res = await GET(`/room/configuration/${roomId}/meta`);
      setMeta(Object.entries(res));
      return res;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, clearError, meta: meta ?? [], load };
};
