import React, { useState } from "react";
import { Flex } from "../lib";
import { v4 } from "uuid";
import { LoaderButton } from "../tools";
import { useUserCreate } from "../actions/userActions";
import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Switch,
  TextField,
} from "@material-ui/core";
import bg from "../images/bg.png";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export const CreateUserView = ({ club, ...props }) => {
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [auth, setAuth] = useState("");
  const [isAdmin, setAdmin] = useState(false);
  const [, creating, , { createUser }] = useUserCreate();
  const { enqueueSnackbar } = useSnackbar();
  const mailId = v4();
  const passwordId = v4();
  const toggleId = v4();
  const authId = v4();
  const { t } = useTranslation();
  if (!club) {
    return null;
  }

  return (
    <>
      <style>
        {`body {
					background-image: url(${bg});
					background-repeat: repeat;
					background-attachment: fixed;
					padding: 0;
					margin: 0;
				}`}
      </style>
      <Flex center middle {...props}>
        <Card>
          <CardHeader
            title={t(
              "components.CreateUser.create-user-title",
              "Benutzer anlegen"
            )}
            subheader={club.name}
          />
          <CardContent>
            <Flex
              as={"form"}
              column
              onSubmit={(e) => {
                e.preventDefault();
                createUser(auth, club.club_id, mail, password, isAdmin, () =>
                  enqueueSnackbar(
                    t(
                      "components.CreateUser.user-created",
                      "Benutzer wurde angelegt"
                    ),
                    { variant: "success" }
                  )
                );
              }}
            >
              <div>
                <TextField
                  variant={"outlined"}
                  label={t("labels.email", "E-Mail").capital()}
                  type={"email"}
                  required
                  autoComplete={"new-email"}
                  id={mailId}
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                />
              </div>
              <div style={{ marginTop: 15, marginBottom: 15 }}>
                <TextField
                  variant={"outlined"}
                  label={t("labels.password", "Passwort").capital()}
                  type={"password"}
                  required
                  autoComplete={"new-password"}
                  id={passwordId}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div style={{ marginBottom: 15 }}>
                <TextField
                  variant={"outlined"}
                  label={t(
                    "components.CreateUser.auth-label",
                    "Auth"
                  ).capital()}
                  type={"password"}
                  required
                  autoComplete={"new-auth"}
                  id={authId}
                  value={auth}
                  onChange={(e) => setAuth(e.target.value)}
                />
              </div>
              <Flex center>
                <FormControlLabel
                  control={
                    <Switch
                      id={toggleId}
                      checked={isAdmin}
                      onChange={(e) => setAdmin(e.target.checked)}
                    />
                  }
                  label={t("labels.admin", "Admin").capital()}
                />
              </Flex>
              <Flex center style={{ marginTop: 20 }}>
                <LoaderButton loading={creating} type="submit">
                  {t("labels.create", "Erstellen")}
                </LoaderButton>
              </Flex>
            </Flex>
          </CardContent>
        </Card>
      </Flex>
    </>
  );
};
