import React, { useEffect, useState } from "react";
import { Flex } from "../lib";
// import bg from "../images/bg-workout.jpeg";
// import bg2 from "../images/bg-club.jpeg";
import { Image_Adobe_3 as bg, Image_Adobe_8 as bg2 } from "../images";
import { v4 } from "uuid";
import { LoaderButton } from "../tools";
import { useUserLogin } from "../actions/userActions";
import { useHistory, useParams, useRouteMatch } from "react-router";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  TextField,
} from "@material-ui/core";
import { CallMade, FitnessCenter } from "@material-ui/icons";
import styled, { createGlobalStyle } from "styled-components/macro";
import { RegisterLink } from "../pages/RegisterPage";
import PasswordForgottenDialog from "./PasswordForgottenDialog";
import GlobalStyle from "../lib/style/GlobalStyle";
import { useTranslation } from "react-i18next";
import PoweredBy from "./frame/PoweredBy";
import cn from "clsx";
import Background from "./frame/Background";

export const LoginView = ({ club }) => {
  const [, loggingIn, , { loginUser, setInvite }] = useUserLogin();
  const { invite } = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { push } = useHistory();
  const { path } = useRouteMatch();
  const emailId = v4();
  const passwordId = v4();
  const isClub = Boolean(club);
  const [reclaimPassword, _setReclaimPassword] = useState(false);
  const { t } = useTranslation();
  const setReclaimPassword = (value) => (e) => {
    e?.stopPropagation?.();
    _setReclaimPassword(value);
  };
  useEffect(() => {
    console.debug("invite is", invite);
    if (invite) {
      const inviteParts = invite.split(";");
      if (inviteParts.length === 2) {
        setInvite?.(invite.split(";"));
      }
    } else {
      setInvite?.(null);
    }
  }, [setInvite, invite]);

  useEffect(() => {
    const ev = window.document.createEvent("Events");
    ev.initEvent("click", true, false);
    window.document.body.dispatchEvent(ev);
  }, []);
  return (
    <>
      <GlobalStyle />
      <Background
        background={club ? bg2 : bg}
        backgroundSize={"auto, cover"}
        backgroundPosition={"top, center"}
      />
      {/*<Background2 club={club} />*/}
      <Layer className={cn({ club: isClub })} />
      <Wrapper>
        <LoginCard className={cn({ club: isClub })}>
          <LoginHeader
            title={
              <LoginTitleWrapper spaceBetween middle>
                <LoginTitle>Login</LoginTitle>
                {club && (
                  <GeneralLoginButton
                    size={"medium"}
                    onClick={() =>
                      path === "/user"
                        ? push(`/club/${club.slug}/login`)
                        : push("/user")
                    }
                  >
                    <CallMade />
                  </GeneralLoginButton>
                )}
              </LoginTitleWrapper>
            }
            subheader={
              club && (
                <Subheader>
                  <FitnessCenter /> {club.name}
                </Subheader>
              )
            }
          />
          <CardContent>
            <FormWrapper
              onSubmit={async (e) => {
                e.preventDefault();
                try {
                  await loginUser(
                    email,
                    password,
                    club ? club.club_id : undefined,
                    true
                  );

                  push("/");
                } catch (e) {
                  console.error(e);
                }
              }}
            >
              <FormField>
                <TextField
                  type={"email"}
                  autoCapitalize={"current-email"}
                  variant={"outlined"}
                  label={t("labels.email", "E-Mail").capital()}
                  id={emailId}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormField>
              <FormField>
                <TextField
                  type={"password"}
                  autoComplete={"current-password"}
                  variant={"outlined"}
                  label={t("labels.password", "Passwort").capital()}
                  id={passwordId}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormField>
              <ButtonField>
                <LoaderButton loading={loggingIn} type="submit">
                  {t("components.Login.login-label", "Einloggen").capital()}
                </LoaderButton>
              </ButtonField>

              {/*<pre>{JSON.stringify({club, email, user, loggingIn}, null, 2)}</pre>*/}
            </FormWrapper>
            <PasswordForgotten onClick={setReclaimPassword(true)}>
              {t(
                "components.Login.password-forgotten-label",
                "Passwort vergessen?"
              )}
            </PasswordForgotten>
          </CardContent>
        </LoginCard>
        <RegisterLink />
        <PoweredBy concise={false} dark={true} />
      </Wrapper>
      <PasswordForgottenDialog
        open={reclaimPassword}
        onClose={setReclaimPassword(false)}
      />
    </>
  );
};
const Wrapper = styled.div`
  display: grid;
  gap: 40px;
  justify-items: center;
`;
const PasswordForgotten = styled.a`
  display: block;
  text-align: center;
  margin: 2em 0 0 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.8em;
  position: relative;
`;
const Layer = styled.div`
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  &.club {
    backdrop-filter: grayscale(0.8) sepia(0.2);
  }
`;

const LoginCard = styled(Card)`
  display: block;
  background: transparent
    linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.9) 100%
    ) !important;
  box-shadow: inset 0 0 1.5px rgba(255, 255, 255, 0.8), 0 10px 40px black !important;
  @supports (backdrop-filter: blur(15px)) {
    background: transparent
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.7) 0%,
        rgba(255, 255, 255, 0.1) 100%
      ) !important;
    backdrop-filter: blur(15px);
  }
`;

const Background2 = createGlobalStyle`
	body {
		background-image: url(${(props) => (props.club ? bg2 : bg)});
		background-repeat: no-repeat;
		background-attachment: fixed;
		padding: 0;
		margin: 0;
	  background-position: top center;
	  background-size: cover;
      ${(props) =>
        props.club
          ? `
        background-position: center;
      `
          : `
          background-position: right;
      `}
	  
	}
`;
const LoginHeader = styled(CardHeader)``;
const LoginTitleWrapper = styled(Flex)``;
const LoginTitle = styled.div``;
const GeneralLoginButton = styled(IconButton)``;
const Subheader = styled.div`
  display: grid;
  align-items: center;
  gap: 10px;
  justify-content: center;
  grid-template-columns: auto auto;
`;

const FormWrapper = styled.form`
  display: grid;
  gap: 15px;
`;

const FormField = styled.div``;
const ButtonField = styled.div`
  display: flex;
  justify-content: center;
`;

export const LoginPage = ({ error }) => {
  return (
    <LoginPageWrapper className={"login"}>
      <LoginView />
    </LoginPageWrapper>
  );
};

const LoginPageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  justify-content: center;
  align-content: start;
  padding-top: 10vh;
`;
