import React from "react";
import styled from "styled-components/macro";
import { Plate } from "../../../lib/style/Templates";
import { useUser } from "../../../actions/userActions";
import { useTranslation } from "react-i18next";

const ProfileData = () => {
  const [user] = useUser();
  const { t } = useTranslation();
  return (
    <>
      <Wrapper>
        <Header>
          {t(
            "components.pages.password.profile.ProfileData.header-title",
            "Profildaten"
          )}
        </Header>
        <Table>
          <TableBody>
            <TableRow>
              <TableDefinitionCell>
                {t("labels.first-name", "Vorname").capital()}
              </TableDefinitionCell>
              <TableCell>
                {user?.first_name ||
                  t(
                    "components.pages.password.profile.ProfileData.not-specified",
                    "keine Angabe"
                  )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableDefinitionCell>
                {t("labels.last-name", "Nachname").capital()}
              </TableDefinitionCell>
              <TableCell>
                {user?.last_name ||
                  t(
                    "components.pages.password.profile.ProfileData.not-specified",
                    "keine Angabe"
                  )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableDefinitionCell>
                {t("labels.email", "E-Mail").capital()}
              </TableDefinitionCell>
              <TableCell>{user?.email ?? t("labels.dash", "-")}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  ${Plate};
  padding: 2em;
`;
const Header = styled.h2`
  font-size: 1.3em;
  font-weight: bold;
`;
const Table = styled.table`
  margin: 1em 0;
`;
const TableBody = styled.tbody``;
const TableRow = styled.tr``;
const TableCell = styled.td`
  padding: 0.5em 1em;
`;
const TableDefinitionCell = styled(TableCell)`
  font-weight: bold;
`;
export default ProfileData;
