import React from "react";
import styled from "styled-components/macro";
import AppLogo from "../../elements/svg/AppLogo";

const Fallback = () => {
  return (
    <>
      <Wrapper>
        <AppLogo dual />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  min-height: 100vh;
  > * {
    max-width: 300px;
  }
`;

export default Fallback;
