import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Image_Adobe_4 as Signup } from "../images";
import Background from "../components/frame/Background";
import { useHistory, useParams } from "react-router";
import { Plate } from "../lib/style/Templates";
import { useUser } from "../actions/userActions";
import AppLogo from "../elements/svg/AppLogo";
import { Button } from "@material-ui/core";
import GlobalStyle from "../lib/style/GlobalStyle";
import ProgressSpinner from "../elements/progress/ProgressSpinner";
import { useClubBySlug, useClubJoining } from "../actions/clubActions";
import { useTranslation } from "react-i18next";
import { CheckCircle } from "@material-ui/icons";
import { APP_NAME } from "../constants";

const View = ({ children, ...props }) => {
  const { push } = useHistory();
  return (
    <>
      <Wrapper {...props}>
        <AppLogoWrapper>
          <AppLogo dual onClick={() => push("/")} />
        </AppLogoWrapper>
        <InnerWrapper>{children}</InnerWrapper>
      </Wrapper>
    </>
  );
};

const ClubHandler = ({ club, loading, error, children }) => {
  if (loading) {
    return (
      <View>
        <CenterWrapper>
          <ProgressSpinner color={"#000"} />
        </CenterWrapper>
      </View>
    );
  }
  if (error || !club) {
    return (
      <>
        <View>
          <CenterWrapper>
            <ErrorMessage className={"centered"}>
              Die Einladung zum gewünschten Club konnte nicht durchgeführt
              werden.
              <ErrorMessage className="bold new-line padded centered">
                Der Club ist nicht verfügbar!
              </ErrorMessage>
            </ErrorMessage>
          </CenterWrapper>
        </View>
      </>
    );
  }

  return children;
};

const CenterWrapper = styled.div`
  height: 100%;
  min-height: 300px;
  display: grid;
  align-items: center;
  justify-content: center;
  align-content: center;
`;
const ErrorMessage = styled.div`
  color: var(--color-error);
  &.bold {
    font-weight: bold;
  }
  &.new-line {
    display: block;
  }
  &.padded {
    padding: 1em;
  }
  &.centered {
    text-align: center;
  }
`;

const UserHandler = ({ user, club, children }) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { token } = useParams();
  if (user) {
    return children;
  }
  return (
    <>
      <View>
        <CenterWrapper>
          <Gap />
          <InfoMessage className={"centered title"}>
            Einladung zum Beitritt von <strong>{club.name}</strong>.
          </InfoMessage>
          <Gap value={"3em"} />
          <InfoMessage className={"centered narrow"}>
            Um die Einladung wahrnehmen zu können, müssen Sie registriert sein.
          </InfoMessage>
          <Gap value={"2em"} />
          <InfoMessage className={"centered"}>
            <Button
              variant={"contained"}
              onClick={() => push(`/register/invite/${club.slug};${token}`)}
            >
              {t("labels.register", "Registrieren")}
            </Button>
          </InfoMessage>
          <Gap value={"2em"} />
          <InfoMessage className={"centered narrow"}>
            Oder wenn Sie bereits ein Konto bei {APP_NAME} haben, können Sie
            sich hier anmelden.
          </InfoMessage>
          <Gap value={"2em"} />
          <InfoMessage className={"centered"}>
            <Button
              color={"primary"}
              variant={"outlined"}
              onClick={() => push(`/user/invite/${club.slug};${token}`)}
            >
              {t("labels.login", "Anmelden")}
            </Button>
          </InfoMessage>
          <Gap />
        </CenterWrapper>
      </View>
    </>
  );
};

const Gap = styled.div`
  height: ${(p) =>
    typeof p.value === "string" ? p.value : (p.value ?? 12) + "px"};
`;
const InfoMessage = styled.p`
  &.centered {
    text-align: center;
  }
  &.title {
    font-size: 1.1em;
  }
  &.narrow {
    margin: 0 2em;
  }
`;

const Validate = ({ club, user, token }) => {
  const [loading, error, { joinAdvanced, clearError }] = useClubJoining();
  const [init, setInit] = useState(false);
  const { replace } = useHistory();
  const handle = async () => {
    try {
      await joinAdvanced(club.club_id, token);
    } finally {
      setInit(true);
    }
  };
  useEffect(() => {
    handle();
  }, []);
  if (loading || !init) {
    return (
      <View>
        <CenterWrapper>
          <InfoMessage className={"centered"}>
            <p>
              Sie versuchen <strong>{club.name}</strong> über einen
              Beitrittslink beizutreten.
            </p>
            <Gap value={"2em"} />
            <ProgressSpinner color={"black"} />
            <Gap gap={"1em"} />
            <p>Zugang wird geprüft</p>
          </InfoMessage>
        </CenterWrapper>
      </View>
    );
  }
  if (error) {
    return (
      <View>
        <CenterWrapper>
          <ErrorMessage className={"centered"}>
            <p>
              <strong>Der Zugang kann nicht validiert werden.</strong>{" "}
            </p>
            <Gap value={"2em"} />
            <p>
              Dafür kann es mehere Gründe geben (Gültigkeit, Systemfehler,
              etc.).
            </p>
            <Gap />
            <p>
              Kontaktieren Sie bitte <strong>{club.name}</strong> für weitere
              Unterstützung.
            </p>
          </ErrorMessage>
        </CenterWrapper>
      </View>
    );
  }
  return (
    <>
      <View>
        <CenterWrapper>
          <InfoMessage className={"centered"}>
            <p>
              Der Zugang zu <strong>{club.name}</strong> wurde gewährt.
            </p>
            <Gap value={"2em"} />
            <CheckCircle fontSize={"large"} />
            <Gap value={"1em"} />
            <Button variant={"contained"} onClick={() => replace("/")}>
              Weiter zu {club.name}
            </Button>
          </InfoMessage>
        </CenterWrapper>
      </View>
    </>
  );
};

const JoinClubPage = ({}) => {
  const [user] = useUser();
  const { slug, token } = useParams();
  const [processed, setProcessed] = useState(false);
  const { club, loading: loadingClub, error, load } = useClubBySlug();
  const handle = async () => {
    if (slug) {
      await load(slug);
      setProcessed(true);
    }
  };
  useEffect(() => {
    handle();
  }, [slug]);

  return (
    <>
      <GlobalStyle />
      <Background background={Signup} />
      <ClubHandler
        loading={loadingClub || !processed}
        club={club}
        error={error}
      >
        <UserHandler user={user} club={club}>
          <Validate club={club} user={user} token={token} />
        </UserHandler>
      </ClubHandler>
    </>
  );
};

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 2em;
`;

const AppLogoWrapper = styled.div`
  text-align: center;
  > * {
    max-width: 250px;
    cursor: pointer;
  }
`;
const InnerWrapper = styled.div`
  ${Plate};
  min-width: 200px;
  max-width: 400px;
  margin: 0 1em;
  min-height: 300px;
  padding: 1em;
  @media all and (max-width: 400px) {
    max-width: 100%;
  }
`;

export default JoinClubPage;
