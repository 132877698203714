import { EVENT_ACTIONS } from "../actions/eventActions";
import { omit, isArray, isPlainObject, isInteger } from "lodash";
import { array2object } from "../lib/methods";
const defaultState = {
  events: {},
  eventMap: {},
  groups: {},
  list: [],
  registered: {},
  notifications: {},
  bookmarks: [],
};

export const eventReducer = (
  state = defaultState,
  { type, events, course_id, groups, eventId, registerState, notification }
) => {
  switch (type) {
    case "RESET":
      return defaultState;
    case EVENT_ACTIONS.SET_LIST: {
      const nextEvents =
        typeof events === "function"
          ? events(state.events[course_id] || [], state.events)
          : events;
      const eventMap = array2object(nextEvents, (e) => e.event_id ?? 0);
      return {
        ...state,
        events: {
          ...state.events,
          [course_id]: [...nextEvents],
        },
        eventMap: {
          ...state.eventMap,
          ...eventMap,
        },
      };
    }
    case EVENT_ACTIONS.SET_PURE: {
      if (typeof events === "function") {
        events = events(state.list);
      }
      const eventMap = array2object(events, (event) => event.event_id ?? 0);
      return {
        ...state,
        list: events,
        eventMap: {
          ...state.eventMap,
          ...eventMap,
        },
      };
    }
    case EVENT_ACTIONS.SET_GROUPS:
      if (typeof groups === "function")
        groups = groups(state.groups[eventId] || []);
      return {
        ...state,
        groups: {
          ...state.groups,
          [eventId]: groups,
        },
      };
    case EVENT_ACTIONS.SET_REGISTERED:
      return {
        ...state,
        registered: {
          ...state.registered,
          [eventId]: registerState,
        },
      };
    case EVENT_ACTIONS.UPDATE_NOTIFICATION: {
      if (isInteger(notification)) {
        // remove notification
        return {
          ...state,
          notifications: omit(state.notifications, [notification]),
        };
      }
      if (isArray(notification)) {
        notification = array2object(notification, (n) => n.event_id);
        return {
          ...state,
          notifications: {
            ...state.notifications,
            ...notification,
          },
        };
      }
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [notification.event_id]: notification,
        },
      };
    }
    case EVENT_ACTIONS.SET_BOOKMAKRS:
      return {
        ...state,
        bookmarks: events,
      };
    default:
      return state;
  }
};
