import React from "react";
import styled from "styled-components/macro";

const EventStateNoAccess = ({ message }) => {
  return (
    <>
      <NoAccess>
        <Icon
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11H7v-2h10v2z" />
        </Icon>
        <Message>{message}</Message>
      </NoAccess>
    </>
  );
};
EventStateNoAccess.defaultProps = {
  message: "components.pages.event.EventStateNoAccess.default",
};

const NoAccess = styled.div`
  display: grid;
  justify-content: center;
  gap: 15px;
  justify-items: center;
  align-items: center;
`;
const Message = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.25em;
`;
const Icon = styled.svg`
  width: 4em !important;
  height: 4em !important;
  fill: darkred !important;
`;

export default EventStateNoAccess;
