import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { messageSort } from "../../../../lib/Sorting";
import { Person } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import { formatDistanceToNow } from "../../../../lib/date";
import { useTranslation } from "react-i18next";

const EventOverlayChat = (props) => {
  const messages = useSelector((s) => s.view.messages);
  const elem = useRef();
  useEffect(() => {
    elem?.current?.scrollTo(0, 99999999);
  }, [!elem?.current, messages.length]);
  const { t } = useTranslation();
  return (
    <ScrollWrapper {...props} ref={elem}>
      <Wrapper>
        {messages.sort(messageSort.byIdAsc).map((message) => {
          return (
            <MessageWrapper key={message.message_id}>
              <Name>
                {message.is_instructor && (
                  <Tooltip
                    title={t(
                      "components.pages.event.chat.EventOverlayChat.instructor",
                      "Instruktor"
                    )}
                  >
                    <PersonIcon />
                  </Tooltip>
                )}
                {message.name}
                <Dot>•</Dot>
                <DateTime>
                  {formatDistanceToNow(new Date(message?.created_at))}
                </DateTime>
              </Name>
              <Message>{message.content}</Message>
            </MessageWrapper>
          );
        })}
      </Wrapper>
    </ScrollWrapper>
  );
};
const ScrollWrapper = styled.div`
  max-height: 55vh;
  width: 100%;
  max-width: 250px;
  overflow-y: hidden;
  overflow-x: visible;
`;
const Wrapper = styled.ul`
  display: grid;
  gap: 1em;
  height: 100%;
  padding-left: 20px;
`;
const MessageWrapper = styled.li``;
const DateTime = styled.time`
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.7);
`;
const PersonIcon = styled(Person)`
  height: 0.5em !important;
  position: absolute;
  left: -18px;
  color: rgba(0, 0, 0, 0.7) !important;
`;
const Dot = styled.span`
  font-size: 1em;
  display: inline-block;
  transform: scale(1.4);
  margin: 0 0.4em;
  color: rgba(0, 0, 0, 0.7);
`;
const Name = styled.div`
  position: relative;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
`;
const Message = styled.div`
  line-height: 1.5em;
`;

export default EventOverlayChat;
