import React, { useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { v4 } from "uuid";
import StyledDialog from "../../../elements/misc/StyledDialog";
import { useUserPasswordChange } from "../../../actions/userActions";
import { useSnackbar } from "notistack";
import { LoaderButton } from "../../../tools";
import { useTranslation } from "react-i18next";

const ProfileChangePasswordDialog = ({
  id,
  onClose: givenOnClose,
  ...props
}) => {
  const { t } = useTranslation();
  const formId = id ?? v4();
  const oldId = v4();
  const newId = v4();
  const confirmId = v4();
  const [old, setOld] = useState("");
  const [next, setNext] = useState("");
  const [confirm, setConfirm] = useState("");
  const { loading, change: _change } = useUserPasswordChange();
  const { enqueueSnackbar } = useSnackbar();
  const reset = () => {
    setOld("");
    setNext("");
    setConfirm("");
  };
  const onClose = () => {
    reset();
    givenOnClose?.();
  };
  const change = async (e) => {
    e?.preventDefault?.();
    if (!old) {
      enqueueSnackbar(
        t(
          "components.pages.password.profile.ProfileChangePasswordDialog.provide-current-password",
          "Bitte das aktuelle Passwort bereitstellen"
        ),
        {
          variant: "warning",
        }
      );
      return;
    }
    if (!next) {
      enqueueSnackbar(
        t(
          "components.pages.password.profile.ProfileChangePasswordDialog.provide-new-password",
          "Bitte das neue Passwort bereitstellen"
        ),
        {
          variant: "warning",
        }
      );
      return;
    }
    if (next !== confirm) {
      enqueueSnackbar(
        t(
          "components.pages.password.profile.ProfileChangePasswordDialog.password-mismatch",
          "Die Passwörter stimmen nicht überein"
        ),
        {
          variant: "warning",
        }
      );
      return;
    }
    await _change(old, next, true);
    enqueueSnackbar(
      t(
        "components.pages.password.profile.ProfileChangePasswordDialog.password-updated",
        "Password wurde aktualisiert"
      ),
      { variant: "success" }
    );
    reset();
    onClose();
  };
  return (
    <StyledDialog {...props}>
      <DialogContent>
        <Form id={formId} onSubmit={change}>
          <TextField
            variant={"outlined"}
            label={t(
              "components.pages.password.profile.ProfileChangePasswordDialog.current-password-label",
              "Aktuelles Passwort"
            )}
            id={oldId}
            type={"password"}
            autoComplete={"current-password"}
            value={old}
            required
            onChange={(e) => setOld(e.target.value)}
          />
          <TextField
            variant={"outlined"}
            label={t(
              "components.pages.password.profile.ProfileChangePasswordDialog.new-password-label",
              "Neues Passwort"
            )}
            id={newId}
            type={"password"}
            autoComplete={"new-password"}
            value={next}
            required
            onChange={(e) => setNext(e.target.value)}
          />
          <TextField
            variant={"outlined"}
            label={t(
              "components.pages.password.profile.ProfileChangePasswordDialog.confirm-password-label",
              "Passwort bestätigen"
            )}
            id={confirmId}
            type={"password"}
            autoComplete={"new-password"}
            value={confirm}
            required
            onChange={(e) => setConfirm(e.target.value)}
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t("labels.cancel", "Abbrechen").capital()}
        </Button>
        <LoaderButton
          loading={loading}
          color={"primary"}
          type={"submit"}
          form={formId}
        >
          {t("labels.change", "Ändern").capital()}
        </LoaderButton>
      </DialogActions>
    </StyledDialog>
  );
};

const Form = styled.form`
  display: grid;
  gap: 1em;
  margin: 2em 1em;
`;

export default ProfileChangePasswordDialog;
