import { Flex } from "../lib";
import { Paper, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import { useTranslation } from "react-i18next";

export default () => {
  const { t } = useTranslation();
  return (
    <Flex middle center>
      <Paper variant={"outlined"} style={{ padding: 0 }}>
        <Alert severity={"warning"} style={{ padding: 30 }}>
          <AlertTitle>
            {t("pages.NotFoundPage.title", "Nicht gefunden")}
          </AlertTitle>
          <Typography variant={"body1"}>
            {t(
              "pages.NotFoundPage.content",
              "Eintrag oder Seite nicht gefunden"
            )}
          </Typography>
        </Alert>
      </Paper>
    </Flex>
  );
};
