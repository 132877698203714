import { useEffect, useState } from "react";
import { get, isNumber, isString, uniqBy } from "lodash";
import { DELETE, GET, POST, PUT } from "../lib";
import { useDispatch, useSelector } from "react-redux";
import { useUser } from "./userActions";
import { setAuthToken } from "../lib/myfetch";
import { useReset } from "./index";
import { useError } from "./errorActions";

export const CLUB_ACTIONS = {
  SET_LIST: "CLUB_SET_LIST",
  SET_LAST: "CLUB_SET_LAST",
};

export const clubState__setList = (clubList = []) => ({
  type: CLUB_ACTIONS.SET_LIST,
  clubList,
});
export const clubState__add = (...club) =>
  clubState__setList((list) => {
    return uniqBy([...club, ...list], "club_id");
  });
export const clubState__remove = (...club) =>
  clubState__setList((list) => {
    const remove = new Set(club.map((c) => c.club_id || c));
    return list.filter((c) => !remove.has(c.club_id));
  });
export const clubState__setLast = (club = null) => ({
  type: CLUB_ACTIONS.SET_LAST,
  club,
});

export const useLastClub = (pure = false) => {
  const _club = useSelector((s) => s.clubs.last);
  const club = useSelector((s) =>
    pure ? _club : s.clubs.list.find((c) => c.club === _club?.club_id) ?? _club
  );
  const dispatch = useDispatch();
  const setClub = (club = null) => dispatch(clubState__setLast(club));

  return [club, setClub];
};

export const useClubsOfUser = (user = null) => {
  const [loading, setLoading] = useState(false);
  const [initiated, setInitiated] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [clubs, setClubs] = useState([]);

  const query = async (throws = null) => {
    try {
      setLoading(true);
      if (user && !user.admin) {
        setClubs(user?.clubs ?? []);
        setInitiated(true);
        return user?.clubs ?? [];
      }
      const list = await GET("/club/user");
      setClubs(list);
      setInitiated(true);
      return list;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    query();
  }, [user?.admin]);

  return { loading, initiated, error, clubs, loadClubs: query };
};

export const useClubs = (init = false) => {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, handle, clearError] = useError(true);
  const clubs = useSelector((state) => state.clubs.list);
  const dispatch = useDispatch();
  const setClubs = (data) => {
    return dispatch(clubState__setList(data));
  };
  const add = (...club) => {
    return dispatch(clubState__add(...club));
  };
  const remove = (...club) => {
    return dispatch(clubState__remove(...club));
  };

  const req = async (id_tag = null, throws = null) => {
    try {
      setLoading(true);
      if (isString(id_tag)) {
        const club = await GET(`/club/tag/${id_tag}`);
        setClubs((list) => uniqBy([club, ...list], "club_id"));
        return club;
      }
      if (isNumber(id_tag)) {
        const club = await GET(`/club/id/${id_tag}`);
        setClubs((list) => uniqBy([club, ...list], "club_id"));
        return club;
      }
      const list = await GET("/club/all");
      setClubs(list);
      setDone(true);
      return list;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init && req();
    // eslint-disable-next-line
  }, [init]);

  return [
    clubs,
    loading,
    error,
    {
      loadClubs: req,
      addClubs: add,
      removeClubs: remove,
      setClubs,
      clearError,
      done,
    },
  ];
};

export const useClubBySlug = (init = null, dispatchResult = false) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [club, setClub] = useState(null);

  const dispatch = useDispatch();

  const load = async (slug, throws = null) => {
    try {
      slug = slug ?? init;
      if (!slug) return;
      setLoading(true);
      const _club = await GET(`/club/tag/${slug}`);
      setClub(_club);
      dispatchResult &&
        dispatch(
          clubState__setList((list) => uniqBy([club, ...list], "club_id"))
        );
      return _club;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    load(init, false);
  }, [init]);
  return { club, loading, error, clearError, load };
};

export const useAdminClub = (init = false) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [club, setClub] = useState(null);
  const dispatch = useDispatch();
  const retClub = useSelector((s) =>
    club ? s.clubs.list.find((c) => c.club_id === club.club_id) : null
  );

  const load = async (throws = null) => {
    try {
      setLoading(true);
      const c = await GET("/club");
      setClub(c);
      dispatch(clubState__add(c));
      return c;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    init && load();
  }, [init]);
  return [retClub, loading, error, { load, clearError }];
};

export const useAdminClubInvitation = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [club, setClub] = useState(null);
  const retClub = useSelector((s) =>
    club ? s.clubs.list.find((c) => c.club_id === club.club_id) : null
  );
  const dispatch = useDispatch();
  const create = async (throws = null) => {
    try {
      setLoading(true);
      const c = await PUT("/club/invitation-link");
      dispatch(clubState__add(c));
      setClub(c);
      return c;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  const remove = async (throws = null) => {
    try {
      setLoading(true);
      const c = await DELETE("/club/invitation-link");
      dispatch(clubState__add(c));
      setClub(c);
      return c;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { club: retClub, loading, error, clearError, create, remove };
};

export const useClubJoining = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [, setUser] = useUser();
  const [, setLastClub] = useLastClub();
  const reset = useReset();

  const joinAdvanced = async (clubId, inviteToken = null, throws = null) => {
    try {
      setLoading(true);
      const extra = inviteToken ? `/${inviteToken}` : "";
      const { user, relation, token, club: nextClub } = await POST(
        "/club/join/" + clubId + extra
      );
      if (user.admin && nextClub) {
        nextClub.admin = true;
        reset();
        setAuthToken(token);
        setUser(user);
        setLastClub(nextClub);
        return { user, relation };
      }
      const club = user.clubs.find((c) => c.club_id === clubId);
      if (!club) {
        throw new Error("Method `join()`: Club ID missing in list");
      }
      reset();
      setAuthToken(token);
      club.admin = get(relation, "is_admin", false);
      setUser(user);
      setLastClub(club);
      return { user, relation };
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  const join = async (club_id, throws = null) => {
    return await joinAdvanced(club_id, null, throws);
  };

  return [loading, error, { join, joinAdvanced, clearError }];
};

export const useClubUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const update = async (data, throws = null) => {
    try {
      setLoading(true);
      const club = await POST("/club", data || {});
      dispatch(clubState__add(club));
      return club;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return [loading, error, { update, clearError }];
};
