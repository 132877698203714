import React from "react";
import styled from "styled-components/macro";
import { DateRange, HowToReg } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import { format } from "../../lib/date";
import { useTranslation } from "react-i18next";
import { DATETIME_FORMAT } from "../../constants";

const ThresholdText = ({ given, threshold }) => {
  const { t } = useTranslation();
  if (given >= threshold) {
    return t(
      "elements.card.EventCardRegistration.course-ok",
      "Kurs findet voraussichtlich statt"
    );
  }
  return t(
    "elements.card.EventCardRegistration.count-required",
    "{{count}} Teilnehmer noch benötigt",
    { count: threshold - given }
  );
};

const RequirementSection = ({ event }) => {
  const { t } = useTranslation();
  if (event?.registration_threshold ?? 0) {
    return (
      <Section>
        <HowToReg />
        <SectionText>
          <ThresholdText
            threshold={event?.registration_threshold ?? 0}
            given={event?.registration_numbers}
          />
        </SectionText>
      </Section>
    );
  }
  return (
    <Section>
      <HowToReg />
      <SectionText>
        {t("labels.application-desired", "Anmeldung erwünscht").capital()}
      </SectionText>
    </Section>
  );
};

const DeadlineSection = ({ event }) => {
  const { t } = useTranslation();
  if (!event?.registration_deadline) {
    return null;
  }
  const date = new Date(event.registration_deadline);

  return (
    <Section>
      <Tooltip
        title={t(
          "elements.card.EventCardRegistration.application-deadline",
          "Anmeldeschluss"
        )}
      >
        <DateRange />
      </Tooltip>
      <SectionText>
        {date < new Date()
          ? t(
              "elements.card.EventCardRegistration.application-deadline",
              "Anmeldeschluss"
            )
          : format(date, t("date.datetime-format", DATETIME_FORMAT))}
      </SectionText>
    </Section>
  );
};

const EventCardRegistration = ({ event }) => {
  if (!event?.registration_required) {
    return null;
  }
  return (
    <>
      <RequirementSection event={event} />
      <DeadlineSection event={event} />
    </>
  );
};

const Section = styled.div`
  display: grid;
  gap: 10px;
  align-items: center;
  grid-template-columns: auto auto;
  justify-content: start;
  margin: 0 20px;
`;
const SectionText = styled.div``;

export default EventCardRegistration;
