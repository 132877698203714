import React from "react";
import styled from "styled-components/macro";
import { Styled } from "../../tools";
import { useSelector } from "react-redux";
import { COLOR } from "../../constants";

const AppLogo = ({ online: givenOnline, ...props }) => {
  const online = useSelector((s) => givenOnline ?? s.misc.sseOnline ?? false);
  return (
    <>
      <Logo
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 568.08 169.48"
        {...props}
        online={online}
      >
        <title>liveDo</title>
        <g id="Ebene_2" data-name="Ebene 2">
          <g id="Ebene_1-2" data-name="Ebene 1">
            <Path
              className="live letter-e"
              d="M323.46,111.48H266.58V94l16.32-3c-2-6.08-6.37-5.89-10.49-6.16a13.82,13.82,0,0,0-13.47,6.86c-8,12.74-3.08,28.16,11.34,32.12,14.72,4,25.38,13.66,37.07,22.5-36.07,25.76-77.08,12.48-88.73-21.65-7-20.4-3.7-39.78,12.17-55.34,16.72-16.4,36.88-20.48,59-13.25C315,64.31,327.88,85.53,323.46,111.48Z"
            />
            <Path
              className="live letter-l"
              d="M.05,13.48H43.42c.18,3.68.46,6.91.47,10.14,0,22.62.13,45.24,0,67.86-.08,10.79,1.93,20.48,11.17,27.43,4.67,3.5,5.61,9,6.21,14.23.85,7.55,1.14,15.16,1.66,22.64-24,9.92-59-2.53-61.31-37C.09,96.2.45,73.54.11,50.94-.1,38.67.05,26.4.05,13.48Z"
            />
            <Path
              className="live letter-v"
              d="M166.4,163.34C150.26,127,135.06,92.75,119.85,58.49c24.12-9.56,34.26-5.11,46.38,20.35,2.67-3.86,5.19-7.19,7.37-10.72,6.3-10.21,15.41-14.2,27.15-12.71,3.95.5,7.9.92,13.28,1.53C198.33,92,182.91,126.48,166.4,163.34Z"
            />
            <Path className="live letter-i" d="M110.58,158.48h-37v-105h37Z" />
            <Path
              className="live letter-i"
              d="M92.28,0C95,1.8,97.73,3,99.59,5a74.45,74.45,0,0,1,7.79,10.32c5.88,9.09,4.66,14.9-4.39,22.21-8.45,6.83-14,6.65-21.89-.71-8.76-8.22-9.33-13.66-2.28-23.35a99.85,99.85,0,0,1,7.5-9.29C87.77,2.59,89.87,1.64,92.28,0Z"
            />
            <Path
              className="live underline"
              d="M333.24,169.48c8.74-2.67,221.92-2.9,231,0Z"
            />
            <Path
              className="do letter-d"
              d="M370.58,122.89V69.75l3-.07c.21,3.13.59,6.25.59,9.38,0,10-.33,20-.16,30,.15,8.7,1.51,10.14,10,9.52,10.94-.8,21.87-2,32.72-3.59,10.35-1.49,15.73-8.54,16.87-18.35a98.21,98.21,0,0,0,.07-21.88c-1.39-12.55-9.54-20.43-22-22.26a102.77,102.77,0,0,0-13-.92c-16.65-.36-33.31-.73-50-.81-8,0-9.23,1.27-9.25,9.39q-.09,42,.18,84c.07,8.93,1.71,10.53,10.69,10.35,24-.49,47.94-1,71.89-2,13-.55,23.57-6.53,31.9-16.49,16.1-19.26,24.94-61.79,6.36-91.34-14.52-23.1-35.81-31.61-61.84-31.41-5.33,0-10.65.15-16,.18-7.52.06-8.38.92-8.79,8.46a7.78,7.78,0,0,1-2.2,5.29V8.42c18.88.49,37.32-1,54.88,4.46C451.78,20.81,467,39,473.12,64.44c5,20.88,2,41.18-7.71,60.2-11.83,23.14-31.45,34.87-57.15,35.6-21,.61-42,.09-63-.07-9.13-.07-11.12-1.94-11.17-11.25q-.21-46,0-92c0-8.26,1.93-10.15,10-10.18,20.33-.08,40.67-.3,61,.27,21.68.61,34.47,15.29,34.36,38.3-.12,22-14.19,37-35.52,37.52C393.47,123.09,382.87,122.89,370.58,122.89Z"
            />
            <Path
              className="do letter-o"
              d="M511.17,139c-2.63-1.62-5.21-3.09-7.67-4.75-14.15-9.52-19.77-23.23-17.81-39.68,1.83-15.44,11-26,25-32.43,2.37-1.08,5.15-1.24,7.63-2.14,3-1.07,5.79-2.47,8.25-3.55,2.59,1.12,5.38,2.88,8.4,3.52,16.63,3.56,29.2,15.69,32.25,31.54,3.38,17.57-3.4,33.7-18.2,43.24-2.21,1.42-4.48,2.75-6.83,4.19,5,3.82,9.79,7.18,14.13,11.07,1.48,1.32,2.81,4.35,2.27,5.94s-3.55,3-5.5,3q-26.46.34-53,0c-2,0-5-1.53-5.52-3s.82-4.59,2.3-5.9C501.32,146.18,506.18,142.83,511.17,139Zm52.74-39.74c.1-22.24-15.62-36-42.69-35.66-18.36.25-32.17,19.35-32,36.1.15,20.87,16.52,37.85,37.11,37.84C547.78,137.55,563.82,121.23,563.91,99.28Zm-10.25,55.2c-10.9-18.17-44.93-17-53.55,0Z"
            />
            <Path
              className="do letter-o"
              d="M544.92,100.42a18.12,18.12,0,1,1-36.24-.25h0a18.12,18.12,0,0,1,36.24.25Zm-3.77.06a14.3,14.3,0,0,0-13.57-14.43c-8.36-.43-15,5.53-15.31,13.64a14.46,14.46,0,0,0,28.9.79Z"
            />
            <Path
              className="do letter-o dot"
              d="M526.52,90.25a9.88,9.88,0,0,1,10.25,9.51c0,.13,0,.26,0,.39a10.27,10.27,0,0,1-10.34,10,9.94,9.94,0,0,1-9.61-9.56,9.61,9.61,0,0,1,8.85-10.31Q526.1,90.25,526.52,90.25Zm0,18.57c2.88-4.09,5.49-6.3,6-8.9.58-3-1.68-5.48-5.28-5.76s-7.25,2.3-6.54,5.55C521.28,102.37,523.73,104.63,526.52,108.82Z"
            />
          </g>
        </g>
      </Logo>
    </>
  );
};

const Logo = styled(
  Styled("svg", "dual", "online", "primary", "inverted", "maxWidth")
)`
  ${(p) => (p.maxWidth ? `width: ${p.maxWidth};` : "")}
`;
const Path = styled.path`
  ${Logo}.dual & {
    &.live {
      fill: #a5b592;
    }

    &.do {
      fill: #d97b11;
    }
  }
  ${Logo}.inverted & {
    &.do {
      fill: rgba(255, 255, 255, 0.8);
    }
  }

  ${Logo}.online & {
    &.do.dot {
      fill: #a5b592;
    }
  }

  ${Logo}.primary & {
    &.live {
      fill: ${COLOR.PRIMARY};
    }
    ${Logo}.online &.do.dot {
      fill: ${COLOR.PRIMARY};
    }
  }
`;

export default AppLogo;
