import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { Send, VisibilityOff } from "@material-ui/icons";
import EventChatMessages from "./EventChatMessages";
import { useMessenger } from "../../../../actions/viewActions";
import { useSelector } from "react-redux";
import { TextField, Tooltip, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const EventChat = ({ event, showViewers, onHide, ...props }) => {
  const { t } = useTranslation();
  const { loading: sending, send } = useMessenger();
  const [content, setContent] = useState("");
  const messages = useSelector((s) => s.view.messages);
  const isMobile = useMediaQuery("(max-width: 1020px)");
  const onlineCount = useSelector((s) => Object.keys(s.view.online).length);
  const list = useRef();
  const atBottom =
    (list?.current?.clientHeight ?? 0) + (list?.current?.scrollTop ?? 0) >=
    (list?.current?.scrollHeight ?? 0);
  useEffect(() => {
    // dispatch(viewState__setOrder(isMobile ? "desc" : "asc"));
    list?.current?.scrollTo?.(0, isMobile ? 0 : list?.current?.scrollHeight);
  }, [isMobile]);
  useEffect(() => {
    const target = list?.current;
    const states = [
      atBottom,
      target?.scrollTop,
      target?.clientHeight,
      target?.scrollHeight,
      (target?.clientHeight ?? 0) + (target?.scrollTop ?? 0) >=
        (target?.scrollHeight ?? 0),
    ];
    isMobile &&
      atBottom &&
      list?.current?.scrollTo?.(0, list?.current?.scrollHeight || 9999999);
  }, [messages.length]);

  return (
    <>
      <Wrapper {...props}>
        <Header>
          <ChatTitle>
            {t("components.pages.event.chat.EventChat.chat-title", "Chat")}
          </ChatTitle>
          {showViewers && (
            <OnlineNotice>
              {t(
                "components.pages.event.chat.EventChat.viewer-count",
                "Zuschauer: {{count}}",
                { count: onlineCount }
              )}
            </OnlineNotice>
          )}
          {onHide && (
            <Tooltip
              title={t(
                "components.pages.event.chat.EventChat.hide-chat",
                "Chat ausblenden"
              )}
            >
              <HideChatButton onClick={onHide} />
            </Tooltip>
          )}
        </Header>
        <Body ref={list}>
          <EventChatMessages
            reverse={isMobile}
            event={event}
            messages={messages}
          />
        </Body>
        <InputWrapper
          onSubmit={async (e) => {
            e.preventDefault();
            if (sending) return false;
            await send(event?.event_id, content, null, true);
            setContent("");
          }}
        >
          {/*<TextField variant={"outlined"} fullWidth label={"Deine Nachricht"} />*/}
          <Input
            required
            type={"text"}
            placeholder={t(
              "components.pages.event.chat.EventChat.your-message-label",
              "Deine Nachricht"
            )}
            onChange={(e) => setContent(e.target.value)}
            value={content}
            autoComplete={"new-message"}
          />
          <Submit>
            <Send />
          </Submit>
        </InputWrapper>
        <Footer />
      </Wrapper>
    </>
  );
};

const HideChatButton = styled(VisibilityOff)`
  transform: scale(0.8);
  cursor: pointer;
  color: #ddd;
`;

const Wrapper = styled.div`
  border-radius: 0.5em;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  @media all and (max-width: 1020px) {
    max-width: 100%;
    border-radius: 0;
  }
`;

const Header = styled.div`
  background: #999;
  padding: 0.5em 1em;
  display: flex;
  align-items: centeró;
  justify-content: space-between;
  @supports (backdrop-filter: blur(10px)) {
    background: rgba(150, 150, 150, 0.6);
    backdrop-filter: blur(10px);
  }
  @media all and (max-width: 1020px) {
    display: none;
  }
`;

const ChatTitle = styled.span``;
const OnlineNotice = styled.span``;

const Body = styled.div`
  min-height: 400px;
  background: #ccc;
  overflow-y: auto;
  max-height: 50vh;
  flex-grow: 1;
  padding: 1em 0;
  @media all and (max-width: 1020px) {
    min-height: auto;
    max-height: inherit;
    overflow-y: initial;
    order: 2;
  }
`;

const InputWrapper = styled.form`
  background: #ccc;
  display: flex;
  flex-shrink: 0;
`;
const Footer = styled.div`
  height: 10px;
  background: #999;
  @supports (backdrop-filter: blur(10px)) {
    background: rgba(150, 150, 150, 0.6);
    backdrop-filter: blur(10px);
  }
  @media all and (max-width: 1020px) {
    order: 1;
    display: none;
  }
`;

const Input = styled(TextField).attrs({ multiline: true, rowsMax: 4 })`
  display: block;
  width: 100%;
  padding: 1em !important;
  background: #ddd;
  border: none;
  font-size: 1rem;
  flex-grow: 1;
  outline: none;
  border-radius: 0;
`;

const Submit = styled.button`
  display: block;
  background: #ddd;
  border: none;
  cursor: pointer;
  min-width: 60px;
  border-radius: 0; ;
`;

export default EventChat;
