import React, { useMemo } from "react";
import styled from "styled-components/macro";
import { PageTitle } from "../../../tools";
import { useTranslation } from "react-i18next";
import Background from "../../frame/Background";
import { Image_Adobe_9 as bg } from "../../../images";
import UserFrame from "../../UserFrame";
import Header from "../../frame/Header";
import { useEventWeekView } from "../../../actions/eventActions";
import { Plate } from "../../../lib/style/Templates";
import {
  add,
  endOfWeek,
  getDay,
  getWeek,
  startOfDay,
  startOfWeek,
} from "date-fns";
import { format } from "../../../lib/date";
import { DATE_FORMAT } from "../../../constants";
import { useHistory, useParams } from "react-router";
import { IconButton } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { de } from "date-fns/locale";

const groupData = (events) => {
  const group = [[], [], [], [], [], [], []];
  for (const event of events) {
    const weekday = (getDay(new Date(event.starts)) + 6) % 7;
    group[weekday].push(event);
  }
  return group;
};

const _getWeek = (base = null) => {
  const day = base ?? new Date();
  const start = startOfWeek(day, { locale: de, weekStartsOn: 1 });
  const end = endOfWeek(day, { locale: de, weekStartsOn: 1 });
  const week = getWeek(day);
  return [start, end, week];
};

const OverviewWeekPage = ({}) => {
  const { t } = useTranslation();
  const { base: urlBase } = useParams();
  const { loading, load, events } = useEventWeekView();
  const groups = groupData(events);
  // const [base, setBase] = useState(startOfDay(urlBase ? new Date(urlBase) : new Date()));
  const base = useMemo(() => {
    return startOfDay(urlBase ? new Date(urlBase) : new Date());
  }, [urlBase]);
  const weekData = useMemo(() => {
    load(base);
    return _getWeek(base);
  }, [base.valueOf()]);
  const { push, replace } = useHistory();
  return (
    <>
      <PageTitle
        title={t(
          "components.pages.overview.OverviewWeekPage.page-title",
          "Wochenübersicht"
        )}
      />
      <Background
        background={bg}
        filter={"sepia(0.5) grayscale(0.5) brightness(0.55) blur(1px)"}
      />
      <UserFrame>
        {/*<Stripe />*/}
        <Header />
      </UserFrame>
      <TableWrapper>
        <HeaderOptions>
          <WeekSelector
            base={base}
            startDay={weekData[0]}
            endDay={weekData[1]}
            weekOfYear={weekData[2]}
            onChange={(nextBase, e) => {
              e?.stopPropagation?.();
              replace(`/overview/week/${format(nextBase, "yyyy-MM-dd")}`);
            }}
          />
        </HeaderOptions>
        <ScrollWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell>
                  {t("labels.monday", "Montag").capital()}
                </TableHeaderCell>
                <TableHeaderCell>
                  {t("labels.tuesday", "Dienstag").capital()}
                </TableHeaderCell>
                <TableHeaderCell>
                  {t("labels.wednesday", "Mittwoch").capital()}
                </TableHeaderCell>
                <TableHeaderCell>
                  {t("labels.thursday", "Donnerstag").capital()}
                </TableHeaderCell>
                <TableHeaderCell>
                  {t("labels.friday", "Freitag").capital()}
                </TableHeaderCell>
                <TableHeaderCell>
                  {t("labels.saturday", "Samstag").capital()}
                </TableHeaderCell>
                <TableHeaderCell>
                  {t("labels.sunday", "Sonntag").capital()}
                </TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {groups.map((events, weekday) => {
                  return (
                    <TableCell key={weekday}>
                      {events.map((event) => {
                        return (
                          <EventWrapper key={event.event_id}>
                            <EventCell
                              event={event}
                              onClick={(e) => {
                                e.stopPropagation();
                                push(`/event/${event.event_id}`);
                              }}
                            />
                          </EventWrapper>
                        );
                      })}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </ScrollWrapper>
      </TableWrapper>
    </>
  );
};

const TableWrapper = styled.section`
  ${Plate};
  padding: 2em;
  margin: 0 1em 1em;
  min-height: 40vh;
  //z-index: 10;
  position: relative;
  @media all and (max-width: 700px) {
    padding: 2em 0.5em 0.5em;
    margin: 0 0.25em 0 0.25em;
  }
`;
const ScrollWrapper = styled.div`
  overflow-x: auto;
`;
const HeaderOptions = styled.div`
  margin-bottom: 1em;
  margin-top: -1em;
`;
const Table = styled.table`
  width: 100%;
  min-width: 1400px;
  margin-bottom: 5em;
`;
const TableHead = styled.thead``;
const TableBody = styled.tbody``;
const TableRow = styled.tr``;
const TableHeaderCell = styled.th`
  padding: 0 0.5em 1em;
  border-bottom: 2px solid gray;
  border-right: 2px solid gray;
  font-weight: bold;

  &:last-child {
    border-right: none;
  }
`;
const TableCell = styled.td``;

const EventWrapper = styled.div`
  padding: 0.2em;
  margin: 0.8em 0;
`;

const WeekSelector = ({
  base,
  startDay,
  endDay,
  weekOfYear,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <WeekWrapper {...props}>
      <IconButton
        onClick={(e) => {
          onChange?.(add(base, { weeks: -1 }), e);
        }}
      >
        <ChevronLeft />
      </IconButton>
      <WeekView>
        {format(startDay, t("date.date-format", DATE_FORMAT))} —{" "}
        {format(endDay, t("date.date-format", DATE_FORMAT))}
      </WeekView>
      <IconButton
        onClick={(e) => {
          onChange?.(add(base, { weeks: 1 }), e);
        }}
      >
        <ChevronRight />
      </IconButton>
    </WeekWrapper>
  );
};

const WeekWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WeekView = styled.div`
  margin-right: 1em;
  margin-left: 1em;
  background: rgba(255, 255, 255, 0.25);
  padding: 0.8em;
  border-radius: 0.25em;
`;

const EventCell = ({ event, ...props }) => {
  const { t } = useTranslation();
  // added here extra because it won't work inline
  // t("components.pages.overview.OverviewWeekPage.EventCell.start_date_format", "HH:mm 'Uhr'")
  // t("components.pages.overview.OverviewWeekPage.EventCell.end_date_format", "HH:mm 'Uhr'")
  return (
    <EventContainer {...props}>
      <EventName>{event.course?.name ?? event.event_id}</EventName>
      <EventHr />
      <StartTime>
        {t(
          "components.pages.overview.OverviewWeekPage.EventCell.date-range",
          "{{start_date}} - {{end_date}}",
          {
            start_date: format(
              new Date(event.starts),
              t(
                "components.pages.overview.OverviewWeekPage.EventCell.start_date_format",
                "HH:mm 'Uhr'"
              )
            ),
            end_date: format(
              new Date(event.ends),
              t(
                "components.pages.overview.OverviewWeekPage.EventCell.end_date_format",
                "HH:mm 'Uhr'"
              )
            ),
          }
        )}
      </StartTime>
    </EventContainer>
  );
};

const EventContainer = styled.div`
  background: rgba(255, 255, 255, 0.25);
  padding: 0.5em;
  border-radius: 0.25em;
  cursor: pointer;
  transition: background 400ms ease-in-out;

  &:hover {
    background: rgba(255, 255, 255, 0.55);
    transition: background 200ms ease-in-out;
  }
`;

const EventName = styled.strong`
  font-weight: 500;
`;
const EventHr = styled.div`
  margin: 0.35em 0;
  height: 1px;
  background: lightgray;
`;

const StartTime = styled.div`
  font-weight: 300;
`;

export default OverviewWeekPage;
