import React from "react";
import styled, { css } from "styled-components/macro";
import cn from "clsx";
import {
  FavoriteBorder,
  PlayArrow,
  ThumbUp,
  ThumbUpOutlined,
  Visibility,
} from "@material-ui/icons";
import DateToggle from "../../../../admin/media/components/DateToggle";
import { mediaUri, useMediaLikes } from "../../../../actions/mediaActions";
import { useSelector } from "react-redux";
import Duration from "../../../../elements/time/Duration";
import ClampLines from "react-clamp-lines";

const MediaListItem = ({ video, className, onView, ...props }) => {
  const { loading: liking, like } = useMediaLikes();
  const liked = useSelector((s) => Boolean(s.media.likes?.[video?.stream_id]));
  const handleLike = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!video?.stream_id) {
      return;
    }
    like(video.stream_id);
  };
  return (
    <>
      <Wrapper
        className={cn(className, "media-list-item", {
          "media-missing-source": video?.meta?.missing_source,
          "with-banner": Boolean(video?.banner_id),
        })}
        banner={
          video?.banner_id
            ? mediaUri.banner(video.stream_id, video.banner_id)
            : null
        }
        {...props}
      >
        <Header>
          <Title
            title={
              video?.meta?.title ?? video?.course?.name ?? "Freie Aufnahme"
            }
          >
            <ClampLines
              buttons={false}
              lines={2}
              text={
                video?.meta?.title ?? video?.course?.name ?? "Freie Aufnahme"
              }
              id={`media-title-${video?.stream_id ?? "0"}`}
            />
          </Title>
          <StyledDateToggle absolute={false} source={video?.created_at} />
        </Header>
        <Playback onClick={onView}>
          <SvgWrapper>
            <PlayArrow />
          </SvgWrapper>
        </Playback>
        <Footer>
          <DurationWrapper>
            <Duration value={video?.meta?.duration} />
          </DurationWrapper>
          <FavoriteWrapper>
            <FavoriteBorder />
          </FavoriteWrapper>
          {/*<Spacer />*/}
          <Views>
            <ViewNumber>{video?.meta?.views ?? 0}</ViewNumber>
            <Visibility />
          </Views>
          <Likes onClick={handleLike}>
            <LikeNumber> {video?.meta?.likes ?? 0}</LikeNumber>
            {liked ? <LikeIconActive /> : <LikeIcon />}
          </Likes>
        </Footer>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.li`
  --bg: ${(p) =>
    p.banner ? `url("${p.banner}")` : "linear-gradient(165deg, #222e, #444)"};
  aspect-ratio: 16/9;
  background-image: var(--bg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  grid-template-rows: max-content auto max-content;
  box-shadow: 0 0 1px black, inset 0 0 2px white;
  overflow: hidden;

  body.untouchable & {
    &.with-banner:hover {
      background-image: var(--bg);
    }

    &:not(.with-banner):hover {
      background-image: linear-gradient(165deg, #111, #333);
    }
  }
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  ${Wrapper}.with-banner & {
    background: #0004;
  }
`;
const Title = styled.h2`
  padding: 1em;

  .media-missing-source & {
    color: var(--color-warning);
  }
`;
const StyledDateToggle = styled(DateToggle)`
  padding: 1em;
  color: #fff8;
  //font-size: 0.8em;
`;
const SvgWrapper = styled.div`
  display: inline-grid;
  place-items: center;
  border-radius: 50%;
  overflow: hidden;
  transition: box-shadow 800ms, opacity 400ms;
  transform: translate3d(0, 0, 0);

  opacity: 0.3;

  body.untouchable .media-list-item:hover & {
    transition: box-shadow 400ms, opacity 1000ms;
    box-shadow: 0 0 4px 3px var(--color-primary), 0 0 3px 5px white;
    opacity: 0.6;
  }
`;
const Playback = styled.div`
  flex-grow: 100;
  display: grid;
  place-items: center;
  margin: 0 0;
  cursor: pointer;

  svg {
    width: 60px;
    height: 60px;
    color: var(--color-primary);
    border-radius: 50%;
  }

  transition: background 400ms;

  ${Wrapper}.with-banner & {
    background: #0004;
  }
  body.untouchable &:hover {
    transition: background 200ms;
    background: #000d;
    margin: 0 1px;
  }

  body.touchable & {
    //background: #111;
  }
`;
const IconStyle = css`
  //svg {
  width: 24px !important;
  height: 24px !important;
  //}
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fffa;
  ${Wrapper}.with-banner & {
    background: #0004;
  }
  //display: none;
`;

const DurationWrapper = styled.div`
  padding: 0.5em 1em;
`;

const Likes = styled.div`
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
  //margin-bottom: 0.2em;
`;
const LikeNumber = styled.span`
  line-height: 1;
  padding-top: calc(1em / 2.3);
  //height: 24px;
  //line-height: 18px;
`;

const LikeIcon = styled(ThumbUpOutlined)`
  ${IconStyle};
`;
const LikeIconActive = styled(ThumbUp)`
  ${IconStyle};
  color: var(--color-primary);
  opacity: 0.8;
`;
const FavoriteWrapper = styled.div`
  padding: 0.5em 1em;
  display: none;
`;
const Views = styled(Likes)`
  padding: 0.5em 1em;
`;
const ViewNumber = styled(LikeNumber)`
  //line-height: 22px;
  padding-top: calc(1em / 6);
`;
export default MediaListItem;
