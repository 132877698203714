import { VIEW_ACTIONS } from "../actions/viewActions";
import { omit, zipObject } from "lodash";
import { messageSort } from "../lib/Sorting";

const defaultState = {
  online: {},
  number: 0,
  messages: [],
  order: "asc",
  active: false,
};

export const viewReducer = (
  state = defaultState,
  { type, userId, users, messages, order, active }
) => {
  switch (type) {
    case "RESET":
    case VIEW_ACTIONS.RESET:
      return defaultState;
    case VIEW_ACTIONS.ONLINE: {
      const online = {
        ...state.online,
        [userId]: userId,
      };
      return {
        ...state,
        online,
        number: Object.keys(online).length,
      };
    }
    case VIEW_ACTIONS.OFFLINE: {
      const online = omit(state.online, [userId]);
      return {
        ...state,
        online,
        number: Object.keys(online).length,
      };
    }
    case VIEW_ACTIONS.ONLINE_ALL:
      if (Array.isArray(users)) {
        users = zipObject(users, users);
      }
      return {
        ...state,
        online: users,
        numner: Object.keys(users).length,
      };
    case VIEW_ACTIONS.SET_MESSAGES:
      // sort messages
      if (typeof messages === "function") {
        messages = messages(state.messages);
      }
      if (state.order === "asc") {
        messages = messages.sort(messageSort.byIdAsc);
      } else {
        messages = messages.sort(messageSort.byIdDesc);
      }
      return { ...state, messages };
    case VIEW_ACTIONS.SET_MESSAGE_ORDER:
      // changing order will result in setting the messages a new (they will be sorted by setting
      order = order || "asc";
      if (order !== state.order) {
        return viewReducer(
          { ...state, order },
          { type: VIEW_ACTIONS.SET_MESSAGES, messages: state.messages }
        );
      }
      return state;
    case VIEW_ACTIONS.SET_ACTIVE:
      if (typeof active === "function") {
        active = active(state.active);
      }
      return {
        ...state,
        active,
      };
    default:
      return state;
  }
};
