import React, { useEffect } from "react";

const meta = document.getElementById("meta-theme");
const update = (color) => {
  if (!meta) {
    console.error("meta-theme not found!");
    return;
  }
  meta.setAttribute("content", color ?? meta.getAttribute("data-default"));
};

const ThemeColor = ({ color }) => {
  useEffect(() => {
    update(color);
    return () => {
      update();
    };
  }, [color]);
  return null;
};

export default ThemeColor;
