String.prototype.capital = function (upper = true) {
  if (this.length) {
    const char = this.charAt(0);
    return (upper ? char.toUpperCase() : char.toLowerCase()) + this.substr(1);
  }
  return this;
};

// Object.assign(String.prototype, function captial(upper = true) {
//   if (this.length) {
//     const char = this.charAt(0);
//     return (upper ? char.toUpperCase() : char.toLowerCase()) + this.substr(1);
//   }
//   return this;
// })