import { useDispatch } from "react-redux";
import { v4 } from "uuid";
import { unset } from "lodash";
import { useEffect, useState } from "react";
import { API_URL, IS_DEV } from "../constants";
import { useLocation } from "react-router";
import { intervalToDuration } from "date-fns";
import { getAuthToken } from "../lib/myfetch";
import SSEHandler from "./sseHandler";
import React from "react";
import { miscState__setSseOnline } from "./miscActions";
import * as PropTypes from "prop-types";
import { StateDecorator } from "../lib/methods";

export const resetState = () => ({ type: "RESET" });

export const useReset = () => {
  const dispatch = useDispatch();
  return () => dispatch(resetState());
};

export const useAdminAwareLocation = () => {
  const locs = useLocation();
  locs.is_admin = locs.pathname.match(/^\/admin/);
  return locs;
};

const channels = {};
const unsubscribe = (channel, token) => {
  if (typeof channel === "function") {
    return channel();
  } else if (channels[channel] && channels[channel][token]) {
    return unset(channels, [channel, token]);
  }
};
const subscribe = (channel, method) => {
  const token = v4();
  channels[channel] = {
    ...channels[channel],
    [token]: method,
  };
  return () => unsubscribe(channel, token);
};
export const publish = (channel, ...arg) => {
  if (channels[channel]) {
    Object.values(channels[channel]).forEach((method) => {
      method(...arg);
    });
  }
};
if (IS_DEV) {
  window.publish = publish;
}
export const useSubscription = (channel, method, deps = []) => {
  // eslint-disable-next-line
  useEffect(() => {
    return subscribe(channel, method);
  }, deps);
};

export const usePubSub = () => [publish, subscribe, unsubscribe];

export const SUBSCRIPTIONS = {
  SESSION_TIMOUT: "session-timeout",
  TOKEN_CHANGE: "token-change",
  STREAM_REMOVE: "stream-remove",
  EVENT_UPDATE: "event_update",
  SET_ADMIN_HEADER_TITLE: "admin-header-title",
  EVENT_USER_ONLINE: "event-user-online",
};

export const useAdminHeaderTitle = (title = "") => {
  useEffect(() => {
    publish(SUBSCRIPTIONS.SET_ADMIN_HEADER_TITLE, title);
    return () => {
      publish(SUBSCRIPTIONS.SET_ADMIN_HEADER_TITLE, "");
    };
  }, []);
};

/**
 *
 * @param {Date|string|number|null} relative
 * @param {object} options
 * @return {Duration|null}
 */
export const useDurationProvider = (relative, options = {}) => {
  const [duration, setDuration] = useState(null);
  relative = relative instanceof Date ? relative : new Date(relative || null);

  options = {
    interval: 999,
    immediate: true,
    now: new Date(),
    active: true,
    reverse: false,
    ...options,
  };

  const run = () => {
    if (options.active) {
      setDuration(
        intervalToDuration(
          options.reverse
            ? { start: options.now, end: relative }
            : {
                start: relative,
                end: options.now,
              }
        )
      );
    } else {
      setDuration(null);
    }
  };

  useEffect(() => {
    if (options.active) {
      options.immediate && run();
      const timer = window.setInterval(run, options.interval);
      return () => {
        window.clearInterval(timer);
      };
    }
    // eslint-disable-next-line
  }, [relative.valueOf(), options.active, options.reverse, options.interval]);

  return duration;
};

// eslint-disable-next-line
// noinspection JSUnusedLocalSymbols
export const useSessionState = (name, defaults = null) => {
  const [value, _setValue] = useState(defaults);
  const setValue = (val) => {
    if (typeof val === "function") {
      val = val(value);
    }
    sessionStorage.setItem(name, JSON.stringify(val));
    _setValue(val);
  };
  useEffect(() => {
    const val = sessionStorage.getItem(name) || null;
    if (null === val) setValue(defaults);
    else setValue(JSON.parse(val) || null);
    // eslint-disable-next-line
  }, []);

  return [value, setValue];
};

// eslint-disable-next-line
// noinspection JSUnusedLocalSymbols
export const useLocaleState = (name, defaults = null) => {
  const [value, _setValue] = useState(defaults);
  const setValue = (val) => {
    if (typeof val === "function") {
      val = val(value);
    }
    localStorage.setItem(name, JSON.stringify(val));
    _setValue(val);
  };
  useEffect(() => {
    const val = localStorage.getItem(name) || null;
    if (null === val) setValue(defaults);
    else setValue(JSON.parse(val) || null);
    // eslint-disable-next-line
  }, []);

  return [value, setValue];
};

export const useStateDecorator = (
  init,
  action = { stop: false, prevent: false }
) => {
  const [next, method] = useState(init);
  return [next, StateDecorator(method, action)];
};

export const useInterval = (value = 10000) => {
  const [, updateInterval] = useState(Date.now());
  useEffect(() => {
    const timer = window.setInterval(() => {
      updateInterval(Date.now());
      return () => {
        window.clearInterval(timer);
      };
    }, value);
  }, []);
};

/**
 *
 * @param {Object} options
 * @param {function} [options.debug]
 * @param {string} [options.url]
 * @param {function} [options.handler]
 * @param {function} [options.onLeave]
 * @param {function} [options.onEnter]
 * @param {function} [options.onStateChange]
 */
export const useSSE = (options = {}) => {
  const [sse, setSse] = useState(null);
  const dispatch = useDispatch();
  options = {
    debug: null,
    url: "sse/stream",
    handler: SSEHandler,
    section: "global",
    ...options,
  };
  options.debug = options?.debug ? console.debug : null;

  const connect = () => {
    if (!("EventSource" in window)) {
      console.error(
        `SSE (${options?.section ?? "default"}): EventSource is not available!`
      );
      return;
    }
    const _url = options?.url ?? "/sse/stream";
    const _sse = new EventSource(`${API_URL}/${_url}?jwt=${getAuthToken()}`);
    setSse((prev) => {
      options?.debug?.("setting SSE", prev, _sse);
      return _sse;
    });
  };
  const setup = () => {
    if (!sse) return;
    sse.onopen = () => {
      options?.debug?.(
        `SSE (${options?.section ?? "default"}): Connection establied`
      );
      options?.onStateChange?.(true, sse, dispatch);
    };
    sse.onerror = (e) => {
      console.error(`SSE (${options?.section ?? "default"}): Error ~`, e);
      options?.onStateChange?.(false, sse, dispatch, e);
    };

    sse.onmessage = (e) => {
      options?.debug?.(
        `SSE (${options?.section ?? "default"}): State is:`,
        sse.readyState
      );
      let data = JSON.parse(e.data || "null");
      options?.debug?.(
        `SSE (${options?.section ?? "default"}): Message ~`,
        data
      );
      const handler = options?.handler ?? SSEHandler;
      handler(data, dispatch, sse, options.debug, options.notistack);
    };
  };

  useEffect(() => {
    !sse && connect();
    sse && options?.onEnter?.(dispatch, sse);
    sse && setup();
    return () => {
      if (sse) {
        sse.close();
        options?.onStateChange?.(false, sse, dispatch);
        options?.debug?.(
          `SSE (${options?.section ?? "default"}): Connection closed`
        );
      }
      options?.onLeave?.(dispatch, sse);
    };
    // eslint-disable-next-line
  }, [!sse]);
  return [sse, connect];
};

export const SSE_GlobalStateChange = (state, sse, dispatch) => {
  dispatch(miscState__setSseOnline(state));
};

export const SSE = ({
  debug,
  url,
  handler,
  section,
  onStateChange,
  notistack,
}) => {
  useSSE({
    debug,
    onStateChange,
    url,
    handler,
    section,
    notistack,
  });
  return null;
};

SSE.defaultProps = {
  debug: null,
  url: "/sse/stream",
  handler: SSEHandler,
  section: "global",
};
SSE.propTypes = {
  debug: PropTypes.func,
  url: PropTypes.string,
  handler: PropTypes.func,
  section: PropTypes.string,
  onStateChange: PropTypes.func,
};
