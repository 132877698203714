import { useState } from "react";
import { useError } from "./errorActions";
import { DELETE, GET, POST } from "../lib";
import { API_URL } from "../constants";
import { getAuthToken } from "../lib/myfetch";
import { useDispatch, useSelector } from "react-redux";
import md5 from "md5";

export const mediaUri = {
  dash: () => `/admin/media`,
  pending: (page) => `/admin/media/pending${page ? `/${page}` : ""}`,
  list: (page) => `/admin/media/list${page ? `/${page}` : ""}`,
  detail: (id) => `/admin/media/video/${id}`,
  playback: (clubId, streamId, playlistName, appendJWT = false) => {
    let url = `${API_URL}/video/${clubId}/${streamId}/${playlistName}`;
    if (appendJWT) {
      url += `?jwt=${getAuthToken()}`;
    }
    return url;
  },
  library: (page) => `/media/${page ? `/${page}` : ""}`,
  view: (id) => `/media/view/${id}`,
  banner: (streamId, bannerId = null) => {
    let uri = `${API_URL}/media/banner/${streamId}?jwt=${getAuthToken()}`;
    if (bannerId) {
      uri += `&${md5(bannerId)}`;
    }
    return uri;
  },
  poster: (streamId, posterId = null) => {
    let uri = `${API_URL}/media/poster/${streamId}?jwt=${getAuthToken()}`;
    if (posterId) {
      uri += `&${md5(posterId)}`;
    }
    return uri;
  },
};

export const mediaState = {
  INPERSISTENT: 1,
  PENDING: 1,
  KEEP: 2,
  REMOVED: 255,
};

export const MEDIA_ACTIONS = {
  PAGE_CHANGE: "MEDIA_PAGE_CHANGE",
  UPDATE_VIDEO: "MEDIA_UPDATE_VIDEO",
  UPDATE_LIKE: "MEDIA_UPDATE_LIKE",
};

const STATE = {
  pageChange: (data) => ({ type: MEDIA_ACTIONS.PAGE_CHANGE, data }),
  updateVideo: (video) => ({ type: MEDIA_ACTIONS.UPDATE_VIDEO, video }),
  updateLike: (like, added) => ({
    type: MEDIA_ACTIONS.UPDATE_LIKE,
    like,
    likeAdded: added,
  }),
};

export const usePublicMedia = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const data = useSelector((s) => s.media);
  const dispatch = useDispatch();

  const load = async (page = null, limit = null, throws = null) => {
    try {
      setLoading(true);
      let base = "/media/videos";
      if (limit !== null) {
        base += `/limit/${limit}`;
      }
      if (page !== null) {
        base += `/page/${page}`;
      }
      const response = await GET(base);
      dispatch(STATE.pageChange(response));
      return response;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    data,
    error,
    clearError,
    load,
  };
};

export const useMediaTest = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [data, setData] = useState({ ok: null });

  const load = async (throws = null) => {
    try {
      setLoading(true);
      const res = await GET("/media");
      setData(res);
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return {
    data,
    loading,
    error,
    clearError,
    load,
  };
};

export const useMediaExists = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [data, setData] = useState({ exists: null, stream: null });

  const request = async (streamId, throws = null) => {
    try {
      setLoading(true);
      const state = await GET(`/media/exists/${streamId}`);
      setData(state);
      return state;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    state: data,
    error,
    clearError,
    request,
  };
};

export const useMediaTitleUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);

  const request = async (streamId, title, throws = null) => {
    try {
      setLoading(true);
      console.debug("updateing title", streamId, title);
      return await POST("/media/title", { stream_id: streamId, title });
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    error,
    clearError,
    request,
  };
};

export const usePendingMedia = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [data, setData] = useState({});
  const load = async (page = null, limit = null, throws = null) => {
    try {
      setLoading(true);
      let base = "/media/pending";
      if (limit !== null) {
        base += `/limit/${limit}`;
      }
      if (page !== null) {
        base += `/page/${page}`;
      }
      const response = await GET(base);
      setData(response);
      return response;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    data,
    error,
    clearError,
    load,
  };
};

export const useAvailableMedia = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [data, setData] = useState({});
  const load = async (page = null, limit = null, throws = null) => {
    try {
      setLoading(true);
      let base = "/media/items";
      if (limit !== null) {
        base += `/limit/${limit}`;
      }
      if (page !== null) {
        base += `/page/${page}`;
      }
      const response = await GET(base);
      setData(response);
      return response;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    data,
    error,
    clearError,
    load,
  };
};

export const usePendingPersistence = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const request = async (streamId, throws = null) => {
    try {
      setLoading(true);
      const response = await POST(`/media/admin/persistent/${streamId}`);
      return response;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  const persistList = async (ids, throws = null) => {
    try {
      setLoading(true)
      const streams = await POST('/media/admin/stream/pending/persist', { streams: ids })
      return streams
    } catch (e) {
      return handle(e, throws)
    } finally {
      setLoading(false)
    }
  }
  return {
    loading,
    error,
    clearError,
    request,
    persistList
  };
};

export const useMediaSourceRemoval = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const request = async (streamId, throws = null) => {
    try {
      setLoading(true);
      const response = await DELETE(`/media/admin/stream/${streamId}`);
      return response;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  const removeList = async (id_list, throws = null) => {
    try {
      setLoading(true)
      const streams = await POST('/media/admin/stream/pending/remove', { streams: id_list })
      return streams
    } catch (e) {
      return handle(e, throws)
    } finally {
      setLoading(false)
    }
  }
  return {
    loading,
    error,
    clearError,
    request,
    removeList
  };
};

export const useMediaDetail = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [video, setVideo] = useState({});
  const dispatch = useDispatch();

  const load = async (id, throws = null) => {
    try {
      setLoading(true);
      const { stream } = await GET("/media/video/" + id);
      setVideo(stream);
      dispatch(STATE.updateVideo(stream));
      return stream;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  return {
    video,
    loading,
    error,
    clearError,
    load,
    setVideo: (video) => {
      setVideo(video);
      dispatch(STATE.updateVideo(video));
    },
  };
};

export const useMediaGroups = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [groups, setGroups] = useState(null);

  const load = async (streamId, throws = null) => {
    try {
      setLoading(true);
      const { groups: _groups } = await GET(`/media/video/${streamId}/groups`);
      setGroups(_groups);
      return _groups;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, clearError, groups, load, setGroups };
};

export const useMediaGroupAssigner = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);

  const request = async (streamId, groupId, assign, throws = null) => {
    try {
      setLoading(true);
      const method = assign ? POST : DELETE;
      const { groups, unset } = await method(
        `/media/groups/assign/${streamId}/${groupId}`
      );
      return { groups, unset };
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    error,
    clearError,
    request,
  };
};

export const useMediaLikes = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const like = async (streamId, throws = null) => {
    try {
      setLoading(true);
      const res = await GET(`/media/like/${streamId}`);
      dispatch(STATE.updateVideo(res.stream));
      dispatch(STATE.updateLike(res.like, res.added));
      return res;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, clearError, like };
};

export const useMediaImageRemoval = (isBanner) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const remove = async (streamId, throws = null) => {
    try {
      setLoading(true);
      const section = isBanner ? "banner" : "poster";
      const res = await DELETE(`/media/${section}/${streamId}`);
      dispatch(STATE.updateVideo(res.stream));
      return res;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    error,
    clearError,
    remove,
  };
};

export const useGlobalMediaUpdate = () => {
  const dispatch = useDispatch();
  return (video) => dispatch(STATE.updateVideo(video));
};
