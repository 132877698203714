import React, { useState } from "react";
import cn from "clsx";
import { format, formatDistanceToNow } from "../../lib/date";
import PropTypes from "prop-types";
import { DATETIME_FORMAT } from "../../constants";
import styled from "styled-components/macro";

const DateToggle = ({
  source,
  absolute: isAbsolute,
  absoluteFormat,
  disabled,
  className,
  children,
  ...props
}) => {
  const [absolute, setAbsolute] = useState(isAbsolute);
  if (!source) {
    return children;
  }
  return (
    <Span
      className={cn(className, { disabled })}
      {...props}
      onClick={disabled ? null : setAbsolute.bind(null, !absolute)}
    >
      {absolute
        ? format(source, absoluteFormat)
        : formatDistanceToNow(
            source instanceof Date ? source : new Date(source),
            { addSuffix: true }
          )}
    </Span>
  );
};
DateToggle.propTypes = {
  absolute: PropTypes.bool,
  absoluteFormat: PropTypes.string,
  disabled: PropTypes.bool,
};
DateToggle.defaultProps = {
  absolute: true,
  absoluteFormat: DATETIME_FORMAT,
  disabled: false,
};
const Span = styled.span`
  cursor: pointer;

  &.disabled {
    cursor: default;
    pointer-events: none;
  }
`;
export default DateToggle;
