import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { keyframes } from "styled-components/macro";
import { useUser, useUserLogout } from "../../actions/userActions";
import { useHistory } from "react-router";
import { Styled } from "../../tools";
import { useLastClub } from "../../actions/clubActions";
import { useSnackbar } from "notistack";
import AppLogoIcon from "../../elements/svg/AppLogo";
import { getImageUrl } from "../../lib/methods";
import ClubLogo from "./ClubLogo";
import ClubChangeDialog from "../club/ClubChangeDialog";
import { useTranslation } from "react-i18next";

const Header = ({}) => {
  const [top, setTop] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [, , , { logoutUser, updatePage }] = useUserLogout();
  const { enqueueSnackbar } = useSnackbar();
  const [showClubChange, _setShowClubChange] = useState(false);
  const { t } = useTranslation();
  const setShowClubChange = useCallback(
    (value, menuState = null) => (e) => {
      e?.stopPropagation?.();
      null !== menuState && setMenuOpen(menuState);
      _setShowClubChange(value);
    },
    [setMenuOpen, _setShowClubChange]
  );
  const [club] = useLastClub();
  const [user] = useUser();
  const { push } = useHistory();
  const menuRef = useRef();
  const logo = useMemo(
    () => (club?.icon_image ? getImageUrl(club.icon_image) : null),
    [club?.icon_image?.image_id ?? 0]
  );
  const logout = useCallback(async () => {
    await logoutUser();
    updatePage();
    enqueueSnackbar(
      t("components.frame.Header.logged-out", "Sie wurden abgemeldet")
    );
  }, [logoutUser, updatePage, enqueueSnackbar, t]);
  const _updateScrollPosition = useCallback(() => {
    setTop(window.scrollY);
  }, [setTop]);
  const _clickOutsideProfile = useCallback(
    (e) => {
      if (menuRef && menuRef.current && !menuRef.current.contains(e.target)) {
        setMenuOpen(false);
      }
    },
    [setMenuOpen, menuRef?.current]
  );
  useEffect(() => {
    _updateScrollPosition();
    window.addEventListener("scroll", _updateScrollPosition, { passive: true });
    document.addEventListener("click", _clickOutsideProfile, {
      passive: true,
    });
    return () => {
      window.removeEventListener("scroll", _updateScrollPosition);
      document.removeEventListener("click", _clickOutsideProfile);
    };
  }, []);
  return (
    <>
      <Wrapper>
        <FixedWrapper
          style={{
            background: `linear-gradient(
          180deg,
        rgba(0, 0, 0, ${max(top)}) 0%,
        rgba(0, 0, 0, ${max(top) * 0.5}) 66%,
        rgba(0, 0, 0, 0) 100%
        )`,
          }}
        >
          <Link
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              push("/profile");
            }}
          >
            {t("components.frame.Header.profile", "Profil")}
          </Link>

          <ClubLogo
            active={Boolean(logo)}
            src={logo}
            alt={
              club?.name ??
              t("components.frame.Header.your-club-fallback", "dein Club")
            }
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              push("/");
            }}
          >
            <AppLogo
              dual
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                push("/");
              }}
            />
          </ClubLogo>
          <MenuLinkWrapper>
            <Link
              isActive={menuOpen}
              isMenu={menuOpen}
              ref={menuRef}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setMenuOpen(!menuOpen);
              }}
            >
              {t("components.frame.Header.menu", "Menü")}
            </Link>
            <MenuInvisibleWrapper ref={menuRef} open={menuOpen}>
              <Menu>
                {club.admin && (
                  <ProfileOption
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      push("/admin");
                    }}
                  >
                    {t("components.frame.Header.admin-section", "Adminbereich")}
                  </ProfileOption>
                )}
                <ProfileOption
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    push("/overview/week");
                  }}
                >
                  {t(
                    "components.frame.Header.week-overview",
                    "Wochenübersicht"
                  )}
                </ProfileOption>
                <ProfileOption
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    push("/bookmarked");
                  }}
                >
                  {t(
                    "components.frame.Header.bookmarks",
                    "Vorgemerkte Termine"
                  )}
                </ProfileOption>
                {club.video_persistent && (
                  <ProfileOption
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      push("/media");
                    }}
                  >
                    {t("components.frame.Header.media", "Mediathek")}
                  </ProfileOption>
                )}
                {(user?.clubs?.length ?? 0) > 1 && (
                  <ProfileOption onClick={setShowClubChange(true, false)}>
                    {t(
                      "components.frame.Header.change-club",
                      "Gruppe wechseln"
                    )}
                  </ProfileOption>
                )}
                <ProfileOption
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    logout();
                  }}
                >
                  {t("components.frame.Header.logout", "Abmelden")}
                </ProfileOption>
              </Menu>
            </MenuInvisibleWrapper>
          </MenuLinkWrapper>
        </FixedWrapper>
      </Wrapper>
      <ClubChangeDialog
        open={showClubChange}
        onClose={setShowClubChange(false)}
      />
    </>
  );
};

const Wrapper = styled.div`
  height: 100px;
  z-index: 10;
`;

const max = (value) => {
  return Math.min(value, 300) / 300;
};

const FixedWrapper = styled.header`
  position: fixed;
  z-index: 10;
  width: 100%;
  display: grid;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
  justify-content: center;
  grid-template-columns: auto auto auto;
  align-items: end;
  gap: 50px;
  background: rgba(0, 0, 0, 0);
`;
const AppLogo = styled(AppLogoIcon)`
  height: 40px;
  cursor: pointer;
`;

const Link = styled(Styled("a", "isMenu", "isActive"))`
  padding: 8px 12px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transform: translateZ(0);
  transition: all 0.2s ease, transform 0.1s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  &.isMenu {
    position: relative;
  }

  &.isActive,
  &:hover {
    box-shadow: inset 0 0 1.5px rgba(255, 255, 255, 0.8), 0 5px 10px black;
    //backdrop-filter: blur(15px);
    transform: translateZ(0);
  }
  &:active {
    will-change: transform;
    transform: scale(0.978) translateZ(0);
    -webkit-backface-visibility: hidden;
    backdrop-filter: blur(15px);
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
  }
`;
const MenuLinkWrapper = styled.div`
  display: grid;
  place-items: center;
  position: relative;
`;
const MenuInvisibleWrapper = styled(Styled("div", "open"))`
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
  &.open {
    display: inherit;
  }
`;
const MenuAnim = keyframes`
    from {transform: scaleY(0)}
    to {transform: scaleY(1)}
`;
const Menu = styled.div`
  position: relative;
  margin-top: 1em;
  color: white;
  display: grid;
  gap: 0.25em;
  //background: rgba(255, 255, 255, 0.3);
  padding: 0.25em;
  border-radius: 0.75em;
  //box-shadow: inset 0 0 1.5px 0.5px rgba(255, 255, 255, 0.1);
  &:before {
    position: absolute;
    right: 10px;
    top: 10px;
    content: "";
    display: block;
    width: 3px;
    height: calc(100% - 5px);
    background: rgba(213, 234, 187, 0.8);
    animation: ${MenuAnim};
    animation-duration: 400ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    //transform-origin: top;
  }
`;

const ProfileOption = styled.div`
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.1s ease-in-out;
  position: relative;
  color: rgba(0, 0, 0, 1);
  //background: #c5d60c66;
  background: rgba(213, 234, 187, 0.7);
  text-align: center;
  text-shadow: 0 0 1.5px white;
  box-shadow: inset 0 0 1.5px white, 0 0 1.5px black;
  @supports (backdrop-filter: blur(15px)) {
    backdrop-filter: blur(15px);
  }
  &:active {
    box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.8);
  }
`;

export default Header;
