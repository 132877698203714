import React from "react";
import { useSSE } from "../../../actions";
import { SSEEventHandler } from "../../../actions/sseHandler";
import {
  viewState__active,
  viewState__resetView,
} from "../../../actions/viewActions";

const EventPresentationSSE = ({ event, token }) => {
  const url =
    `/sse/event/${event?.event_id}` + (token ? `/token/${token}` : "");
  useSSE({
    debug: true,
    section: `event-${event?.event_id ?? "na"}`,
    url,
    handler: SSEEventHandler,
    onLeave: (dispatch, sse) => {
      console.debug(
        "EventPresentationSSE: Closing sse connection to event",
        event?.event_id
      );
      dispatch(viewState__resetView());
      sse?.close?.();
    },
    onStateChange: (state, sse, dispatch) => {
      console.debug(
        "EventPresentationSSE: changing state to",
        state,
        sse?.readyState
      );
      dispatch(viewState__active(state));
    },
  });
  return null;
};

export default EventPresentationSSE;
