import React from "react";
import styled, { css } from "styled-components/macro";
import { Styled } from "../../tools";
import {
  Event,
  FitnessCenter,
  Person,
  Star,
  StarOutline,
  Timelapse,
} from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import ProgressSpinner from "../progress/ProgressSpinner";
import EventCardRegistration from "./EventCardRegistration";
import Color from "color";
import { useEventNotification } from "../../actions/eventActions";
import PropTypes from "prop-types";
import EventAttributeView from "../../components/pages/event/EventAttributeView";
import { useTranslation } from "react-i18next";

const EventCard = ({
  title,
  trainer,
  date,
  clubName,
  duration,
  children,
  showFavorite,
  onFavorite,
  favorite,
  upadingFavorite,
  favoriteTooltip,
  header,
  register,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Card {...props}>
      {header ? (
        typeof header === "function" ? (
          header(
            { title, favorite, upadingFavorite, favoriteTooltip, onFavorite },
            { TitleWrapper, Title }
          )
        ) : (
          header
        )
      ) : (
        <TitleWrapper
          favorite={favorite}
          visible={showFavorite}
          action={Boolean(onFavorite)}
        >
          <Title>{title}</Title>
          <Tooltip
            title={
              favoriteTooltip ??
              t("elements.card.EventCard.favorite", "Favorit")
            }
          >
            <FavoriteWrapper
              onClick={(e) => {
                onFavorite && onFavorite(e, favorite);
                e.stopPropagation();
              }}
            >
              {!upadingFavorite && (
                <>
                  <FavoriteIcon />
                  <FavoriteIconInactive />
                </>
              )}
              {upadingFavorite && (
                <ProgressSpinner shadow color={"black"} size={24} />
              )}
            </FavoriteWrapper>
          </Tooltip>
          {/*<Button>Hello</Button>*/}
        </TitleWrapper>
      )}
      <Club exists={Boolean(clubName)}>
        <FitnessCenter /> {clubName}
      </Club>

      <Trainer exists={Boolean(trainer)}>
        <Tooltip title={t("labels.trainer", "Trainer").capital()}>
          <Person />
        </Tooltip>{" "}
        {trainer}
      </Trainer>
      <Date exists={Boolean(date)}>
        <Tooltip
          title={t("elements.card.EventCard.course-start", "Kursbeginn")}
        >
          <Event />
        </Tooltip>{" "}
        {date}
      </Date>
      <EventCardRegistration event={register} />
      <Duration>
        <Timelapse /> {duration}
      </Duration>
      {children}
    </Card>
  );
};

const Card = styled(Styled("div", "disabled"))`
  display: grid;
  gap: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%),
    0px 1px 8px 0px rgb(0 0 0 / 12%);
  background: linear-gradient(
    145deg,
    rgba(226, 221, 221, 0.6) 0%,
    rgba(255, 255, 255, 0.8) 100%
  );
  color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  cursor: pointer;
  padding-bottom: 20px;

  &.disabled {
    position: relative;
    color: #555;
    background: linear-gradient(
      145deg,
      rgba(138, 136, 136, 0.6) 0%,
      rgba(169, 169, 169, 0.8) 100%
    );

    &:after {
      content: "";
      display: block;
      width: 200%;
      height: 3px;
      left: 50%;
      top: 50%;
      //background: rgba(255, 0, 0, 0.5);
      background: ${Color("red").darken(0.2).fade(0.5)};
      position: absolute;
      border-radius: 1.5px;
      transform: rotate(45deg) translate(-50%, -50%);
      transform-origin: top left;
    }
  }
`;

const SEPARATE_BORDER = "1px solid rgba(255, 255, 255, 0.8)";

const TitleStyle = css`
  margin: 20px 0 0;
  padding: 0 20px 10px 20px;
  border-bottom: ${SEPARATE_BORDER};
`;

export const TitleWrapper = styled(
  Styled("div", "favorite", "visible", "action")
)`
  display: grid;
  align-content: center;
  align-items: center;
  ${TitleStyle};
  gap: 10px;

  &.visible {
    grid-template-columns: auto min-content;
  }
`;
const FavoriteWrapper = styled.div`
  display: none;
  position: relative;
  overflow: hidden;
  width: 30px;
  height: 30px;

  ${TitleWrapper}.visible & {
    display: grid;
  }

  ${TitleWrapper}.action & {
    cursor: pointer;

    &:hover {
      transform: scale(1.4);
    }

    &:active {
      transform: scale(1.1);
    }
  }

  transition: 0.1s ease-in-out;
`;
const FavoriteIcon = styled(Star)`
  opacity: 0;
  width: 30px !important;
  height: 30px !important;
  position: absolute;
  left: 0;
  top: 0;
  color: #555;
  //filter: drop-shadow(-1px -0px black) drop-shadow(0px 1px black);
  transition: opacity 0.4s;

  ${TitleWrapper}.favorite & {
    opacity: 1;
  }
`;
const FavoriteIconInactive = styled(StarOutline)`
  opacity: 0;
  color: gray;
  width: 30px !important;
  height: 30px !important;
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 0.4s;

  ${TitleWrapper}:not(.favorite) & {
    opacity: 1;
  }
`;
export const Title = styled.h3`
  white-space: nowrap;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
`;
const Trainer = styled(Styled("div", "exists"))`
  display: none;
  margin: 0 20px;
  white-space: nowrap;
  font-size: 16px;

  &.exists {
    display: grid;
  }

  align-items: center;
  gap: 10px;
  grid-template-columns: auto auto;
  justify-content: start;
`;
const Club = styled(Trainer)``;
const Date = styled(Styled("div", "exists"))`
  display: none;
  margin: 0 20px;
  white-space: nowrap;

  &.exists {
    display: grid;
  }

  align-items: center;
  gap: 10px;
  grid-template-columns: auto auto;
  justify-content: start;
`;
const Duration = styled.div`
  margin: 0 20px;
  white-space: nowrap;
  display: grid;
  align-items: center;
  gap: 10px;
  grid-template-columns: auto auto;
  justify-content: start;
`;
export default EventCard;

export const ConnectedEventCard = ({
  event,
  title,
  trainer,
  date,
  clubName,
  duration,
  children,
  showFavorite,
  onFavorite,
  favorite,
  upadingFavorite,
  favoriteTooltip,
  header,
  register,
  showAttributes,
  ...props
}) => {
  const { notification, loading, update } = useEventNotification(
    event?.event_id ?? null
  );
  const updateFavorite = async () => {
    if (!event) {
      return;
    }
    await update(event.event_id, !notification);
  };
  const attributes = event?.course?.attributes ?? [];
  const { t } = useTranslation();
  return (
    <EventCard
      title={
        title ?? event?.course?.name ?? t("labels.course", "Kurs").capital()
      }
      trainer={trainer ?? event?.trainer ?? null}
      date={date}
      clubName={clubName}
      duration={duration}
      showFavorite={true}
      onFavorite={updateFavorite}
      favorite={Boolean(notification)}
      upadingFavorite={upadingFavorite ?? loading}
      favoriteTooltip={t("elements.card.EventCard.message", "Benachrichtigung")}
      header={header}
      register={register}
      {...props}
    >
      {showAttributes && attributes.length > 0 && (
        <AttributeWrapper>
          <EventAttributeView event={event} attributes={attributes} />
        </AttributeWrapper>
      )}
    </EventCard>
  );
};

const AttributeWrapper = styled.div`
  border-top: ${SEPARATE_BORDER};
  padding: 0.5em 0.5em;
`;

ConnectedEventCard.propTypes = {
  event: PropTypes.object.isRequired,
};
