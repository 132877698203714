import React, { useState } from "react";
import { PlayArrow } from "@material-ui/icons";
import { HlsPlayer } from "../../../../../tools";
import styled from "styled-components/macro";
import WrappedHlsMeta from "./WrappedHlsMeta";

const WrappedHls = ({ video, banner, ...props }) => {
  const [started, setStarted] = useState(false);
  if (banner && !started) {
    return (
      <WrappedHlsMeta video={video}>
        <Banner className={"hls-banner"} onClick={() => setStarted(true)}>
          <PlayArrow />
        </Banner>
      </WrappedHlsMeta>
    );
  }
  return <HlsPlayer autoplay={true} {...props} />;
};

const Banner = styled.div`
  background: var(--color-primary);
  display: grid;
  place-items: center;
  padding: 5em;
  border-radius: 20%;
  cursor: pointer;

  svg {
    color: #111;
  }

  &:active {
    box-shadow: inset 0 0.5em 1em black;

    svg {
      filter: drop-shadow(-1px -1px 5px #ddd);
    }
  }
`;

export default WrappedHls;
