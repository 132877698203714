import { clubState__add, clubState__remove } from "./clubActions";
import { roomState__add, roomstate__remove } from "./roomActions";
import { courseState__add, courseState__remove } from "./courseActions";
import { eventState__add, eventState__remove } from "./eventActions";
import { streamState__add, streamState__remove } from "./streamActions";
import { publish, SUBSCRIPTIONS } from "./index";
import {
  viewState__addMessages,
  viewState__offine,
  viewState__online,
  viewState__onlineAll,
  viewState__removeMessages,
  viewState__setMessages,
} from "./viewActions";
import { isArray } from "lodash";

const handleSSE = (
  { action, payload },
  dispatch,
  sse = null,
  debug = false,
  notistack = null
) => {
  debug?.(`SSE-Handler: action=${action} payload=%o`, payload);
  switch (action) {
    case "error":
      // dispatch()
      console.error("got error", payload, notistack);
      notistack &&
        notistack(payload?.error ?? "Some error", { variant: "error" });
      break;
    case "club":
      dispatch(clubState__add(payload));
      break;
    case "club-remove":
      dispatch(clubState__remove(payload));
      break;
    case "room":
      dispatch(roomState__add(payload));
      break;
    case "room-remove":
      dispatch(roomstate__remove(payload));
      break;
    case "course":
      dispatch(courseState__add(payload));
      break;
    case "course-remove":
      dispatch(courseState__remove(payload));
      break;
    case "event":
      dispatch(eventState__add(payload.course_id, payload));
      publish(SUBSCRIPTIONS.EVENT_UPDATE, payload, payload.event_id);
      break;
    case "event-remove": {
      const data = isArray(payload) ? payload : [payload];
      if (data?.length) {
        dispatch(eventState__remove(data[0].course_id, ...data));
      }
      break;
    }
    case "stream":
      dispatch(streamState__add(payload));
      break;
    case "stream-remove":
      dispatch(streamState__remove(payload));
      publish(SUBSCRIPTIONS.STREAM_REMOVE, payload);
      break;
    case "token":
      publish(SUBSCRIPTIONS.TOKEN_CHANGE, true, payload);
      break;
    case "token-remove":
      publish(SUBSCRIPTIONS.TOKEN_CHANGE, false, payload);
      break;
    case "ping":
      debug?.(`SSE-Handler: got PING`);
      break;
    default:
      console.warn(`Action [${action}] has no handler!`);
  }
};

export default handleSSE;

export const SSEEventHandler = (
  { action, payload },
  dispatch,
  sse = null,
  debug = false
) => {
  debug &&
    console.debug(`SSE-Event-Handler: action=${action} payload=%o`, payload);
  switch (action) {
    case "event-online":
      dispatch(viewState__online(payload));
      publish(SUBSCRIPTIONS.EVENT_USER_ONLINE, true, payload);
      break;
    case "event-online-all":
      dispatch(viewState__onlineAll(payload));
      break;
    case "event-offline":
      dispatch(viewState__offine(payload));
      publish(SUBSCRIPTIONS.EVENT_USER_ONLINE, false, payload);
      break;
    case "message":
      dispatch(viewState__addMessages(payload));
      break;
    case "message-init":
      dispatch(viewState__setMessages(payload));
      break;
    case "message-remove":
      dispatch(viewState__removeMessages(payload));
      break;
    case "ping":
      debug?.(`SSE-Event-Handler: got PING`);
      break;
    default:
      console.warn(`SSE-Event-Handler: Action [${action}] has no handler`);
  }
};
