import { GROUP_ACTIONS } from "../actions/groupActions";
import { get, omit } from "lodash";

const defaultState = {
  list: [],
  detail: {},
};

export const groupReducer = (
  state = defaultState,
  { type, groups, details }
) => {
  switch (type) {
    case "RESET":
      return defaultState;
    case GROUP_ACTIONS.SET:
      if (typeof groups === "function") {
        groups = groups(state.list);
      }
      return {
        ...state,
        list: [...groups],
      };
    case GROUP_ACTIONS.SET_DETAIL:
      if (typeof details === "function") {
        details = details(state.details);
      }
      if (typeof details === "number") {
        return {
          ...state,
          detail: omit(state.detail, [details]),
        };
      } else {
        const id = get(details, "group.group_id");
        if (id) {
          return {
            ...state,
            detail: {
              ...state.detail,
              [id]: details,
            },
          };
        }
      }
      return state;
    default:
      return state;
  }
};
