import React, { useCallback, useState } from "react";
import styled from "styled-components/macro";
import { Styled } from "../../tools";

const ClubLogo = ({ active, onLoad, onError, children, ...props }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const _onLoad = useCallback(
    (e) => {
      setLoaded(true);
      onLoad?.(e);
    },
    [onLoad, setLoaded]
  );
  const _onError = (e) => {
    setError(true);
    onError?.(e);
  };
  if (!active || error) {
    return children;
  }
  return (
    <Image loaded={isLoaded} onLoad={_onLoad} onError={_onError} {...props} />
  );
};

const Image = styled(Styled("img", "loaded"))`
  height: 40px;
  min-width: 50px;
  cursor: pointer;
  &.loaded {
    min-width: inherit;
  }
`;

export default ClubLogo;
