import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components/macro";
import ProgressSpinner from "../../../elements/progress/ProgressSpinner";
import { get } from "lodash";
import { intervalToDuration } from "date-fns";
import {
  ConnectedEventCard,
  Title as EventCardTitle,
  TitleWrapper as EventCardTitleWrapper,
} from "../../../elements/card/EventCard";
import EventStateFinished from "./EventStateFinished";
import EventStateNoAccess from "./EventStateNoAccess";
import EventStateStart from "./EventStateStart";
import EventStateComingUp from "./EventStateComingUp";
import { useEventRegistration } from "../../../actions/eventActions";
import { useSnackbar } from "notistack";
import EventPresentation from "./EventPresentation";
import EventConfirmVoucher from "./EventConfirmVoucher";
import { useSelector } from "react-redux";
import { LoaderButton } from "../../../tools";
import EventStateNotHappending from "./EventStateNotHappening";
import { format, formatDistance, formatDuration } from "../../../lib/date";
import { useInterval } from "../../../actions";
import { useTranslation } from "react-i18next";
import { DATETIME_FORMAT } from "../../../constants";

const _renderOption = (event, t) => {
  if (!event) return [React.Fragment, false, {}];
  const now = new Date();
  const starts = new Date(event.starts);
  const ends = new Date(event.ends);
  const timeLimit = starts.valueOf() - now.valueOf() < 300000;
  const finished = now > ends;
  const inRange = now >= starts && now <= ends;
  const stream = get(event, "room.stream", null);
  if (false === (event?.happens ?? null)) {
    return [EventStateNotHappending, false, {}];
  }
  if (finished) {
    return [
      EventStateFinished,
      false,
      {
        message: t(
          "components.pages.event.EventContent.state-course-finished",
          "Kurs wurde bereits beendet"
        ),
      },
    ];
  }
  if (!event.has_access && !event.voucher) {
    return [
      EventStateNoAccess,
      false,
      {
        message: t(
          "components.pages.event.EventContent.state-access-denied",
          "Sie haben zu diesem Kurs keinen Zugang"
        ),
      },
    ];
  }
  if (event.active && stream) {
    return [
      EventStateStart,
      true,
      {
        message: t(
          "components.pages.event.EventContent.state-course-started",
          "Kurs hat begonnen"
        ),
      },
    ];
  }
  const distance = formatDistance(starts, now, { addSuffix: true });
  const message = inRange
    ? t(
        "components.pages.event.EventContent.state-course-down",
        "Der Stream ist gleich wieder zurück"
      )
    : timeLimit
    ? t(
        "components.pages.event.EventContent.state-course-coming-up",
        "Der Kurs beginnt in Kürze"
      )
    : t(
        "components.pages.event.EventContent.state-course-starts-at",
        "Der Kurs beginnt {{ range }}",
        { range: distance }
      );

  return [
    EventStateComingUp,
    false,
    {
      message: message,
    },
  ];
};

const ActionView = ({ event, setConfirmVoucher, showPlayer }) => {
  useInterval();
  const { t } = useTranslation();
  const [StateComponent, playerAllowed, stateProps] = _renderOption(event, t);
  return (
    <StateComponent
      {...stateProps}
      onClick={() => {
        if (playerAllowed) {
          if (!event.has_access && event.voucher) {
            setConfirmVoucher(true);
          } else {
            showPlayer(true);
          }
        }
      }}
    />
  );
};

const CustomHeader = ({ event, title }) => {
  const isRegistered = useSelector(
    (s) => s.events.registered[event?.event_id ?? 0] ?? false
  );
  const { t } = useTranslation();
  const {
    signIn,
    signOut,
    signState,
    loading: updatingRegistration,
  } = useEventRegistration();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (event?.event_id) {
      // noinspection JSIgnoredPromiseFromCall
      signState(event.event_id);
    }
  }, [event?.event_id ?? 0]);
  const handleSignment = (state) => async (e) => {
    if (state) {
      await signOut(event?.event_id ?? 0, true);
    } else {
      await signIn(event?.event_id ?? 0, true);
    }
    enqueueSnackbar(
      state
        ? t(
            "components.pages.event.EventContent.action-logged-out",
            "Sie haben sich abgemeldet"
          )
        : t(
            "components.pages.event.EventContent.action-logged-in",
            "Sie haben sich angemeldet"
          )
    );
  };
  return (
    <Heading>
      <HeadingTitle>{title}</HeadingTitle>
      <LoaderButton
        disabled={new Date(event.starts) < new Date()}
        loading={updatingRegistration}
        variant={"outlined"}
        color={isRegistered ? "secondary" : "primary"}
        size={"small"}
        onClick={handleSignment(isRegistered)}
      >
        {isRegistered
          ? t("components.pages.event.EventContent.logout-label", "Abmelden")
          : t("components.pages.event.EventContent.login-label", "Anmelden")}
      </LoaderButton>
    </Heading>
  );
};

const customHeader = (event) => {
  if (!event?.registration_required) {
    return null;
  }
  return ({ title }) => {
    return <CustomHeader title={title} event={event} />;
  };
};

const Heading = styled(EventCardTitleWrapper)`
  grid-template-columns: auto min-content;
`;
const HeadingTitle = styled(EventCardTitle)``;

const EventContent = ({
  onChangeBackground,
  event,
  loading,
  token,
  updateEvent,
  updateInterval,
  ...props
}) => {
  const { t } = useTranslation();
  const [player, showPlayer] = useState(false);
  const [confirmVoucher, setConfirmVoucher] = useState(false);
  const [activeState, setActiveState] = useState(event?.active ?? false);

  const { enqueueSnackbar } = useSnackbar();
  const closePlayer = () => showPlayer(false);
  const duration = (() => {
    return intervalToDuration({
      start: new Date(event?.starts ?? null),
      end: new Date(event?.ends ?? null),
    });
  })();
  useEffect(() => {
    if (activeState && false === event?.active) {
      enqueueSnackbar(
        t(
          "components.pages.event.EventContent.stream-stopped",
          "Stream wurde gestoppt"
        )
      );
      showPlayer(false);
    }
    setActiveState(event?.active ?? false);
  }, [event?.active ?? false]);

  return (
    <>
      <Wrapper {...props}>
        <InnerWrapper>
          {!event && loading && <ProgressSpinner thickness={5} size={80} />}
          {event && (
            <>
              <CourseInfo>
                <EventDescription>
                  {event.course?.description && (
                    <>
                      <CourseDescription>
                        {event.course?.description}
                      </CourseDescription>
                    </>
                  )}{" "}
                  {event.description}
                </EventDescription>
                {/*<CourseCard>*/}
                {/*  <CourseTitle>Dein Kurs</CourseTitle>*/}
                {/*</CourseCard>*/}
                <Event
                  showAttributes
                  event={event}
                  onClick={onChangeBackground}
                  title={get(
                    event,
                    "course.name",
                    t(
                      "components.pages.event.EventContent.your-course-label",
                      "Dein Kurs"
                    )
                  )}
                  trainer={get(event, "trainer", null)}
                  duration={formatDuration(duration)}
                  clubName={get(event, "club.name", null)}
                  showFavorite={false}
                  date={format(
                    new Date(event.starts),
                    t("date.datetime-format", DATETIME_FORMAT)
                  )}
                  register={event}
                  header={customHeader(event)}
                />
              </CourseInfo>
              <ActionView
                event={event}
                setConfirmVoucher={setConfirmVoucher}
                showPlayer={showPlayer}
              />
            </>
          )}
        </InnerWrapper>
      </Wrapper>
      {player && (
        <EventPresentation token={token} event={event} onClose={closePlayer} />
      )}
      {confirmVoucher && (
        <EventConfirmVoucher
          eventId={event?.event_id ?? 0}
          onClose={setConfirmVoucher.bind(null, false)}
          updateEvent={updateEvent}
        />
      )}
    </>
  );
};

EventContent.defaultProps = {
  updateInterval: 10000,
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const InnerWrapper = styled.div`
  max-width: 960px;
  width: 100%;
  padding-top: 100px;
  display: grid;
  gap: 100px;
  margin-bottom: 100px;
  padding-left: 20px;
  padding-right: 20px;
  @media all and (max-width: 960px) {
    padding-top: 30px;
  }
`;

const CourseInfo = styled.div`
  overflow: hidden;
  border: 1px solid transparent;
  box-shadow: inset 0 0 0.5px rgba(255, 255, 255, 0.8), 0 20px 40px black;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(50px);
  display: grid;
  gap: 40px;
  grid-template-columns: auto auto;
  @media all and (max-width: 960px) {
    grid-template-columns: auto;
    grid-row: revert;
    gap: 10px;
  }
`;

const EventDescription = styled.p`
  padding: 30px 0 30px 30px;
  flex-grow: 1;
  font-size: 16px;
  line-height: 30px;
  @media all and (max-width: 960px) {
    padding: 0 30px 30px 30px;
  }
`;

const CourseDescription = styled.span`
  display: block;

  &:not(:empty) {
    margin-bottom: 10px;
  }
`;

const EventAnimation = keyframes`
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0)
	}
`;

const EventAnimationTop = keyframes`
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateY(0)
	}
`;

const Event = styled(ConnectedEventCard)`
  flex-shrink: 0;
  cursor: default;
  align-self: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
  animation: ${EventAnimation};
  animation-duration: 0.9s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  @media all and (max-width: 960px) {
    border-radius: 0;
    margin-top: 0;
    grid-row: 1;
    animation: ${EventAnimationTop};
    animation-duration: 0.9s;
  }
`;

export default EventContent;
