import React, { useEffect, useRef, useState } from "react";
import { SUBSCRIPTIONS, useSubscription } from "../../../../actions";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

const _name = (user) => {
  return (
    `${user.first_name} ${user.last_name}`.trim() || `Anonymous_${user.user_id}`
  );
};

const EventOnline = ({ ttl, ...props }) => {
  const ref = useRef();
  const [list, setList] = useState([]);
  useSubscription(SUBSCRIPTIONS.EVENT_USER_ONLINE, (online, user) => {
    setList((old) => [...old, { online, user, time: Date.now() }]);
  });
  useEffect(() => {
    const interval = window.setInterval(() => {
      const now = Date.now();
      setList((old) => old.filter(({ time }) => time + ttl >= now));
    }, ttl ?? 5000);
    return () => {
      return window.clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    ref?.current?.scrollTo(0, ref?.current?.scrollHeight ?? 999999);
  }, [list.length]);
  const { t } = useTranslation();
  return (
    <Wrapper {...props} ref={ref}>
      <List>
        {/*<Notice>Fufuu is beigetreten</Notice>*/}
        {/*<Notice>ASDs hat den Stream verlassen</Notice>*/}
        {list.map((item) => {
          return (
            <Notice key={item.time}>
              {item.online
                ? t(
                    "components.pages.event.chat.EventOnline.user-entered",
                    "{{name}} ist beigetreten",
                    { name: _name(item.user) }
                  )
                : t(
                    "components.pages.event.chat.EventOnline.user-left",
                    "{{name}} hat den Stream verlassen",
                    { name: _name(item.user) }
                  )}
            </Notice>
          );
        })}
      </List>
    </Wrapper>
  );
};

EventOnline.defaultProps = {
  ttl: 5000,
};

const Wrapper = styled.div`
  max-height: 50%;
  overflow-y: hidden;
`;
const List = styled.ul``;
const Notice = styled.li``;

export default EventOnline;
