import React from "react";
import styled from "styled-components/macro";
import { Info } from "@material-ui/icons";
import * as PropTypes from "prop-types";

const EventStateFinished = ({ message, children, ...props }) => {
  return (
    <CourseFinished {...props}>
      <InfoIcon />
      <FinishedText>{message}</FinishedText>
    </CourseFinished>
  );
};
EventStateFinished.defaultProps = {
  message: "components.pages.event.EventStateFinsihed.default",
};
EventStateFinished.propTypes = {
  message: PropTypes.string,
};

export default EventStateFinished;

const CourseFinished = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 15px;
  justify-items: center;
`;
const InfoIcon = styled(Info)`
  width: 4em !important;
  height: 4em !important;
  fill: #eca412 !important;
`;
const FinishedText = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.25em;
`;
