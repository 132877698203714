import { useState } from "react";
import { useError } from "./errorActions";
import { DELETE, GET, POST, PUT } from "../lib";
import { useDispatch, useSelector } from "react-redux";
import { uniqBy } from "lodash";

export const GROUP_ACTIONS = {
  SET: "SET_GROUP",
  SET_DETAIL: "SET_GROUP_DETAIL",
};

export const groupState__set = (groups) => ({
  type: GROUP_ACTIONS.SET,
  groups,
});
export const groupState__add = (...group) =>
  groupState__set((list) => uniqBy([...group, ...list], "group_id"));
export const groupState__remove = (...group) =>
  groupState__set((list) => {
    const r = new Set(group.map((g) => g.group_id || g));
    return list.filter((g) => !r.has(g.group_id));
  });

export const groupState__setDetail = (details) => ({
  type: GROUP_ACTIONS.SET_DETAIL,
  details,
});

export const useGroups = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const load = async (throws = null) => {
    try {
      setLoading(true);
      const list = await GET("/rights/groups");
      dispatch(groupState__set(list));
      return list;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return [loading, error, { load, clearError }];
};

export const useGroupDetail = (groupId) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();
  const details = useSelector((s) => s.groups.detail[groupId] || null);

  const clear = (groupID = null) => {
    dispatch(groupState__setDetail(groupID || groupId));
  };

  const load = async (groupID = null, throws = null) => {
    groupID = groupID || groupId;
    try {
      setLoading(true);
      const res = await GET("/rights/group/" + groupID);
      dispatch(groupState__setDetail(res));
      return res;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  const assign = async (userIDs, groupID = null, throws = null) => {
    groupID = groupID || groupId;
    try {
      setLoading(true);
      const res = await POST(
        `/rights/group/${groupID}/assign-members`,
        userIDs
      );
      dispatch(groupState__setDetail(res));
      return res;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  return [details, loading, error, { clear, load, assign, clearError }];
};

export const useGroupCreation = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const create = async (name, all, throws = null) => {
    try {
      setLoading(true);
      const next = await PUT("/rights/group", { name, all });
      dispatch(groupState__add(next));
      return next;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  return [loading, error, { create, clearError }];
};

export const useGroupRemoval = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const remove = async (groupId, throws = null) => {
    try {
      setLoading(true);
      const response = await DELETE(`/rights/group/${groupId}`);
      dispatch(groupState__remove(response.group));
      return response;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, clearError, remove };
};
