import {ROOM_ACTIONS} from "../actions/roomActions";

const defaultState = {
	rooms: []
}

export const roomReducer = (state = defaultState, {type, rooms}) => {
	switch(type) {
		case 'RESET':
		case ROOM_ACTIONS.RESET:
			return defaultState
		case ROOM_ACTIONS.SET_LIST:
			rooms = typeof rooms === 'function' ? rooms(state.rooms) : rooms
			return {
				...state,
				rooms
			}
		default: return state
	}
}