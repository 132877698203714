import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { userReducer } from "./userReducer";
import { clubReducer } from "./clubReducer";
import { roomReducer } from "./roomReducer";
import { courseReducer } from "./courseReducer";
import { eventReducer } from "./eventReducer";
import { streamReducer } from "./streamReducer";
import { groupReducer } from "./groupReducer";
import { voucherReducer } from "./voucherReducer";
import { viewReducer } from "./viewReducer";
import { miscReducer } from "./miscReducer";
import { imageReducer } from "./imageReducer";
import { mediaReducer } from "./mediaReducer";

export const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    users: userReducer,
    clubs: clubReducer,
    rooms: roomReducer,
    courses: courseReducer,
    events: eventReducer,
    streams: streamReducer,
    groups: groupReducer,
    vouchers: voucherReducer,
    view: viewReducer,
    misc: miscReducer,
    images: imageReducer,
    media: mediaReducer,
  });
