import React from "react";
import GlobalStyle from "../../../../lib/style/GlobalStyle";
import Background from "../../../frame/Background";
import { Image_Adobe_1 as bg } from "../../../../images";

const MediaFrame = ({ children, background, ...props }) => {
  return (
    <>
      <GlobalStyle />
      <Background backgroundColor={"#444"} />
      {/*<Background*/}
      {/*  background={background === undefined ? bg : background}*/}
      {/*  filter={" grayscale(0.25) brightness(0.75) blur(1px)"}*/}
      {/*/>*/}
      {children}
    </>
  );
};

export default MediaFrame;
