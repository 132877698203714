import { MEDIA_ACTIONS } from "../actions/mediaActions";
import { omit } from "lodash";
import { array2object } from "../lib/methods";

const defaultState = {
  count: 0,
  limit: 24,
  page: 1,
  videos: [],
  likes: {},
  views: {},
  globalVideos: {},
  globalLikes: {},
  globalViews: {},
};

export const mediaReducer = (
  state = defaultState,
  { type, data, video, like, likeAdded }
) => {
  switch (type) {
    case MEDIA_ACTIONS.PAGE_CHANGE:
      return {
        ...state,
        ...data,
        globalVideos: {
          ...state.globalVideos,
          ...array2object(data.videos, (v) => v.stream_id),
        },
        globalLikes: {
          ...state.globalLikes,
          ...data.likes,
        },
        globalViews: {
          ...state.globalViews,
          ...data.views,
        },
      };
    case MEDIA_ACTIONS.UPDATE_VIDEO:
      return {
        ...state,
        videos: state.videos.map((v) => {
          if (v.stream_id === video.stream_id) {
            return video;
          }
          return v;
        }),
        globalVideos: {
          ...state.globalVideos,
          [video.stream_id]: video,
        },
      };
    case MEDIA_ACTIONS.UPDATE_LIKE:
      if (likeAdded) {
        return {
          ...state,
          likes: {
            ...state.likes,
            [like.stream_id]: like,
          },
          globalLikes: {
            ...state.globalLikes,
            [like.stream_id]: like,
          },
        };
      }
      return {
        ...state,
        likes: omit(state.likes, like.stream_id),
        globalLikes: omit(state.globalLikes, like.stream_id),
      };
    default:
      return state;
  }
};
