import { useSnackbar } from "notistack";
import { useState } from "react";
import { isPlainObject, isArray } from "lodash";
import { useTranslation } from "react-i18next";

const _alert = (a, b, e, trans) => {
  if ((a === null && b === null) || false === b) return null;
  let message = e.error || e.message;
  let data = {
    variant: "error",
    autoHideDuration: 3000,
    ...a,
  };
  if (isPlainObject(b)) {
    message = b.message || message;
    delete b.message;
    data = { ...data, ...b };
  } else if (isArray(b)) {
    message = (b.length > 1 && b[0]) || message;
    if (b.length > 1) data.variant = b[1];
    else if (b.length === 1) data.variant = b[0];
  } else if (typeof b === "string") {
    message = b;
  }
  return [trans(message, message), data];
};

let defaultSnackOptions = null;

export const setDefaultSnackOptions = (options) =>
  (defaultSnackOptions = options);

export const useError = (snackOptions = defaultSnackOptions) => {
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const handle = (e, onState = null, defaultValue = null, options = null) => {
    console.error(e);
    if ("pre" === onState) {
      throw e;
    }
    const messageData = _alert(snackOptions, options, e, t);
    if (messageData) {
      enqueueSnackbar(...messageData);
    }
    if (false === onState) {
      throw e;
    }
    setError(e);
    if (true === onState) {
      throw e;
    }
    return defaultValue;
  };
  return [error, handle, setError.bind(null, null)];
};
