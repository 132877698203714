const _direction = (d) => {
  if (typeof d === "string") {
    return d.toLowerCase() !== "asc";
  }
  return Boolean(d);
};

const _numeric = (v, defaults = null) => {
  if (v === undefined || v === "" || v === null) {
    return defaults;
  }
  v = Number(v);
  return isNaN(v) ? defaults : v;
};
const _alphanum = (v, defaults = null) => {
  if (v === undefined || v === "" || v === null) {
    return defaults;
  }
  v = String(v).trim();
  return v || defaults;
};
export const generalSort = {
  numeric: (key, direction = false, missing = null) => {
    direction = _direction(direction) ? -1 : 1;
    return (left, right) => {
      const l = _numeric(left?.[key], missing);
      const r = _numeric(right?.[key], missing);
      if (l === null) {
        return 1;
      }
      if (r === null) {
        return -1;
      }
      return (l - r) * direction;
    };
  },
  alpanum: (key, direction = false, missing = null) => {
    direction = _direction(direction) ? -1 : 1;
    return (left, right) => {
      const l = _alphanum(left?.[key], missing);
      const r = _alphanum(right?.[key], missing);
      if (l === null) {
        return 1;
      }
      if (r === null) {
        return -1;
      }
      return (
        String(l).toLowerCase().localeCompare(String(r).toLowerCase()) *
        direction
      );
    };
  },
  natural: (key, direction = false, missing = null) => {
    direction = _direction(direction) ? -1 : 1;
    return (left, right) => {
      const l = _alphanum(left?.[key], missing);
      const r = _alphanum(right?.[key], missing);
      if (l === null) {
        return 1;
      }
      if (r === null) {
        return -1;
      }
      const nl = _numeric(l);
      const nr = _numeric(r);
      if (null !== nl && null !== nr) {
        return (nl - nr) * direction;
      }
      return (
        String(l).toLowerCase().localeCompare(String(r).toLowerCase()) *
        direction
      );
    };
  },
};

export const groupSort = {
  byNameAsc: (left, right) =>
    left.name.toLowerCase().localeCompare(right.name.toLowerCase()),
};

export const userSort = {
  byIdAsc: (left, right) => (left?.user_id ?? 0) - (right?.user_id ?? 0),
  byIdDesc: (left, right) => userSort.byIdAsc(right, left),
  byEmailAsc: (left, right) =>
    (left?.email ?? "")
      .toLowerCase()
      .localeCompare((right?.email ?? "").toLowerCase()),
  byEmailDesc: (left, right) => userSort.byEmailAsc(left, right) * -1,
  byFirstNameAsc: (left, right) =>
    (left?.first_name ?? "")
      .toLowerCase()
      .localeCompare((right?.first_name ?? "").toLowerCase()),
  byFirstNameDesc: (left, right) => userSort.byFirstNameAsc(left, right) * -1,
  byLastNameAsc: (left, right) =>
    (left?.last_name ?? "")
      .toLowerCase()
      .localeCompare((right?.last_name ?? "").toLowerCase()),
  byLastNameDesc: (left, right) => userSort.byLastNameAsc(left, right) * -1,
};

export const clubSort = {
  byNameAsc: (left, right) =>
    (left?.name?.toLowerCase?.() ?? "").localeCompare(
      right?.name?.toLowerCase?.() ?? ""
    ),
};

export const messageSort = {
  byIdAsc: (left, right) => (left?.message_id ?? 0) - (right?.message_id ?? 0),
  byIdDesc: (left, right) => (right?.message_id ?? 0) - (left?.message_id ?? 0),
};

export const imageSort = {
  byIdAsc: (left, right) => (left?.image_id ?? 0) - (right?.image_id ?? 0),
};

export const eventSort = {
  byStartAsc: (left, right) =>
    new Date(left?.starts ?? 0).valueOf() -
    new Date(right?.starts ?? 0).valueOf(),
  byEndDesc: (left, right) =>
    new Date(right?.ends ?? 0).valueOf() - new Date(left?.ends ?? 0).valueOf(),
};
