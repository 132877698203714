import { useError } from "./errorActions";
import { useEffect, useState } from "react";
import { DELETE, GET, POST, PUT } from "../lib";
import { uniqBy, get } from "lodash";
import { SUBSCRIPTIONS, useSubscription } from "./index";
import { usePureEvent } from "./eventActions";

export const useTokenEvent = (tokenId = null, init = false) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [token, setToken] = useState(null);
  const [event, setEvent] = useState(null);

  const load = async (loadTokens = true, tokenID = null, throws = null) => {
    tokenID = tokenID ?? tokenId;
    try {
      setLoading(true);
      let retToken = token;
      if (loadTokens) {
        const retToken = await GET(`/token/${tokenID}/simple`);
        setToken(retToken);
      }
      const retEvent = await GET(`/event/token/${tokenID}`);
      setEvent(retEvent);
      return [retEvent, retToken ?? token];
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  useSubscription(
    SUBSCRIPTIONS.EVENT_UPDATE,
    (nextEvent, eId) => {
      console.debug("got event change", nextEvent, eId);
      if (event && eId === event.event_id) {
        load(false, null);
      }
    },
    [load, !event]
  );

  useEffect(() => {
    if (init) {
      // noinspection JSIgnoredPromiseFromCall
      load();
    }
  }, [init]);

  return {
    event,
    token,
    loading,
    error,
    clearError,
    setEvent,
  };
};

export const useToken = (tokenId = null, init = false) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [data, setData] = useState({
    token: null,
    club: null,
    event: null,
    course: null,
    user: null,
    room: null,
  });

  const load = async (token_id = tokenId, throws = null) => {
    try {
      setLoading(true);
      const response = await GET(`/token/${token_id}`);
      setData(response);
      return response;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    init && tokenId && load(tokenId);
    // eslint-disable-next-line
  }, [init, tokenId]);
  return [data, loading, error, { load, clearError }];
};

export const useTokens = (init = false) => {
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  useSubscription(SUBSCRIPTIONS.TOKEN_CHANGE, (_add, token) => {
    if (!get(init, "event_id")) {
      return;
    }
    // noinspection EqualityComparisonWithCoercionJS
    if (init.event_id != token.event_id) {
      return;
    }
    if (_add) {
      add(token);
    } else {
      remove(token);
    }
  });

  const add = (...token) =>
    setTokens((list) => uniqBy([...token, ...list], "token_id"));
  const remove = (...token) =>
    setTokens((list) => {
      const rm = new Set(token.map((t) => t.token_id || t));
      return list.filter((t) => !rm.has(t.token_id));
    });

  const load = async (filters = {}, throws = null) => {
    try {
      setLoading(true);
      const list = await POST("/token/items", filters);
      setTokens(list);
      return list;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    init && load(init);
    // eslint-disable-next-line
  }, [!init]);
  return [tokens, loading, error, { load, add, remove, clearError, setTokens }];
};

export const useTokenCreation = (addToken) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);

  const create = async (event_id, throws = null) => {
    try {
      setLoading(true);
      const token = await PUT("/token", { event_id });
      addToken && addToken(token);
      return token;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return [loading, error, { create, clearError }];
};

export const useTokenRemoval = (removeToken) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);

  const remove = async (token_id, throws = null) => {
    try {
      setLoading(true);
      const token = await DELETE("/token/" + token_id);
      removeToken && removeToken(token);
      return token;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return [loading, error, { remove, clearError }];
};
