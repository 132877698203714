import React, { useEffect } from "react";
import GlobalStyle from "../../../lib/style/GlobalStyle";
import MediaPlayback from "./components/MediaPlayback";
import { useHistory, useParams } from "react-router";
import { mediaUri, useMediaDetail } from "../../../actions/mediaActions";
import ThemeColor from "../../frame/ThemeColor";

const MediaDetailPage = () => {
  const { goBack, length: historyItems, push } = useHistory();
  const { id } = useParams();
  const { loading, load, video } = useMediaDetail();
  useEffect(() => {
    load(id);
  }, [id]);
  const handleBackwards = () => {
    goBack() || push(mediaUri.library());
  };
  return (
    <>
      <GlobalStyle />
      <ThemeColor color={"#000"} />
      <MediaPlayback
        onClose={handleBackwards}
        video={video}
        loading={loading}
      />
    </>
  );
};

export default MediaDetailPage;
