import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import * as PropTypes from "prop-types";
import HlsPlayerScreen from "./HlsPlayerScreen";
import { useSelector } from "react-redux";
import { Styled } from "../../tools";
import { API_URL, COLOR } from "../../constants";
import { Tooltip } from "@material-ui/core";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Hls from "hls.js";
import NativePlayer from "../../tools/player/native-player";
import { getAuthToken, getStreamToken } from "../../lib/myfetch";
import { useTranslation } from "react-i18next";

const _player = (player, stream, props) => {
  switch (player) {
    case "hls":
      if (!Hls.isSupported()) {
        return _player(null, stream, props);
      }
      return <HlsPlayerScreen stream={stream} {...props} />;
    default:
      if (!stream) return null;
      const params = { stream_id: stream.stream_id };
      const auth = getAuthToken();
      const streamToken = getStreamToken();
      if (auth) params["jwt"] = auth;
      if (streamToken) params["streamToken"] = streamToken;
      return (
        <NativePlayer
          source={`${API_URL}/streams/${stream.club_id}/${stream.stream_id}/playlist.m3u8`}
          params={params}
          {...props}
          controls
        />
      );
  }
};

const PlayerScreen = React.forwardRef(
  (
    {
      title,
      max,
      min,
      children,
      stream,
      player,
      streamProps,
      onPlayerError,
      onClose,
      onFullscreenChange,
      overlay,
      showViewers,
      onViewersClick,
      viewersProps,
      chatDisabled,
      setupOptions,
      token,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const fsHandle = useFullScreenHandle();
    const online = useSelector((s) => s.view.active);
    const screenRef = useRef();
    const viewers = useSelector((s) => Object.values(s.view.online).length);

    return (
      <Wrapper {...props} ref={ref}>
        <HeaderLine className={"player-sceen-header"} viewers={showViewers}>
          <Title>{title}</Title>
          {showViewers && (
            <Viewers {...viewersProps} onClick={onViewersClick}>
              {chatDisabled
                ? t(
                    "elements.misc.PlayerScreen.viewers-summary-disabled",
                    "{{count}} Zuschauer | Chat deaktiviert",
                    { count: viewers }
                  )
                : t(
                    "elements.misc.PlayerScreen.viewers-summary",
                    "{{count}} Zuschauer",
                    { count: viewers }
                  )}
            </Viewers>
          )}
          <Tooltip title={t("labels.close", "Schließen").capital()}>
            <Closer
              onClick={(e) => {
                onClose && onClose(e);
              }}
              online={online}
            />
          </Tooltip>
        </HeaderLine>
        <Screen
          min={min}
          max={max}
          className={"player-screen-body"}
          ref={screenRef}
        >
          <FullScreenWrapper
            handle={fsHandle}
            onChange={(state) => {
              onFullscreenChange?.(state);
            }}
          >
            {children ||
              _player(player, stream, {
                ...streamProps,
                onError: onPlayerError,
                setupOptions,
              })}
            {typeof overlay === "function" ? overlay(fsHandle) : overlay}
          </FullScreenWrapper>
        </Screen>
        <Footer className={"player-screen-footer"} />
      </Wrapper>
    );
  }
);
PlayerScreen.propTypes = {
  player: PropTypes.oneOf(["hls", "videojs", "dash"]),
};
PlayerScreen.defaultProps = {
  player: "hls",
};
const Wrapper = styled.div`
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 2px 12px rgba(0, 0, 0, 0.4);
  max-width: 960px;
  @media all and (max-width: 965px) {
    margin: 10px;
    box-shadow: 0 0 2px 3px #000;
  }
`;
const HeaderLine = styled(Styled("div", "viewers"))`
  height: 30px;
  background: #999;
  gap: 1em;
  display: grid;
  align-items: center;
  padding: 3px 10px;
  grid-template-columns: auto 1rem;

  &.viewers {
    grid-template-columns: auto min-content 1rem;
  }

  @supports (backdrop-filter: blur(10px)) {
    background: rgba(150, 150, 150, 0.6);
    backdrop-filter: blur(10px);
  }
`;
const Title = styled.div``;
const Viewers = styled.div`
  white-space: nowrap;
`;
const Closer = styled(Styled("button", "online"))`
  border: 0;
  border-radius: 50%;
  height: 1rem !important;
  width: 1rem !important;
  background: #b80c0c;
  outline: none;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.6);

  &.online {
    background: ${COLOR.PRIMARY};
  }

  &:hover {
    transform: scale(1.2);
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.6);
  }

  &:active {
    transform: scale(1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.6);
  }
`;
const Screen = styled.div`
  position: relative;
  background: black;
  max-width: ${(props) => props.max || "100%"};
  min-width: ${(props) => props.min || "600px"};
  width: 100%;
  padding: 0;
  margin: 0;
  @media all and (min-width: 960px) {
    width: 960px;
  }

  video {
    max-width: 100%;
    @media all and (min-width: 960px) {
      width: 960px;
    }
  }
`;
const FullScreenWrapper = styled(FullScreen)`
  position: relative;
`;
const Footer = styled.div`
  background: #999;
  height: 10px;
  @supports (backdrop-filter: blur(10px)) {
    background: rgba(150, 150, 150, 0.6);
    backdrop-filter: blur(10px);
  }
`;

export default PlayerScreen;
