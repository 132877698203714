import React, { useMemo, useState } from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import { IconButton } from "@material-ui/core";
import { Info, PlayArrow, SubdirectoryArrowLeft } from "@material-ui/icons";
import cn from "clsx";
import ProgressSpinner from "../../../../elements/progress/ProgressSpinner";
import { HlsPlayer, Spacer, Styled } from "../../../../tools";
import { mediaState, mediaUri } from "../../../../actions/mediaActions";
import Hls from "hls.js";
import NativePlayer from "../../../../tools/player/native-player";
import { CamviewThumbFHD_2 } from "../../../../images";
import WrappedHls from "./playback/WrappedHls";
import MediaMeta from "./MediaMeta";
import { useSelector } from "react-redux";

const MediaPlayback = ({
  video: givenVideo,
  backIcon,
  onClose,
  className,
  loading,
  children,
}) => {
  const video = useSelector(
    (s) => s.media.globalVideos[givenVideo?.stream_id] ?? givenVideo
  );
  const [meta, showMeta] = useState(false);
  const hlsOk = useMemo(() => Hls.isSupported(), []);
  const Icon = styled(backIcon || SubdirectoryArrowLeft)`
    color: white;
  `;
  const handleClose = (e) => {
    e.stopPropagation();
    onClose?.();
  };
  return (
    <>
      <Container className={cn(className, "media-playback")}>
        <Header>
          <HeaderTitle>
            {video?.meta?.title ?? video?.course?.name ?? "Freie Aufnahme"}
          </HeaderTitle>
          <HeaderMore>{children}</HeaderMore>
          <Spacer />
          <IconButton onClick={() => showMeta(true)}>
            <Info color={"primary"} />
          </IconButton>
          <IconButton onClick={handleClose}>
            <Icon />
          </IconButton>
        </Header>
        <Wrapper>
          {/*<PlayArrow />*/}
          {video?.stream_id && video.persistent === mediaState.KEEP && (
            <>
              <VideoContainer nohls={!hlsOk}>
                {video?.meta?.missing_source ? (
                  <div>Quelledateien fehlen</div>
                ) : (
                  <>
                    {hlsOk && (
                      <WrappedHls
                        video={video}
                        banner={false}
                        preload={"none"}
                        poster={
                          video?.poster_id
                            ? mediaUri.poster(video.stream_id, video.poster_id)
                            : null
                        }
                        autoplay
                        streamId={video.stream_id}
                        src={mediaUri.playback(
                          video.club_id,
                          video.stream_id,
                          video.playlist_name
                        )}
                        controls
                        setupOptions={{
                          startPosition: 0,
                          debug: false,
                          liveDurationInfinity: false,
                          liveMaxLatencyDuration: undefined,
                          liveSyncDuration: undefined,
                          lowLatencyMode: false,
                          startFragPrefetch: false,
                        }}
                        levelControls
                      />
                    )}
                    {!hlsOk && (
                      <NativePlayer
                        autoplay
                        preload={"none"}
                        poster={
                          video?.poster_id
                            ? mediaUri.poster(video.stream_id, video.poster_id)
                            : CamviewThumbFHD_2
                        }
                        source={mediaUri.playback(
                          video.club_id,
                          video.stream_id,
                          video.playlist_name,
                          true
                        )}
                      />
                    )}
                  </>
                )}
              </VideoContainer>
            </>
          )}
          {video?.stream_id && video?.persistent !== mediaState.KEEP && (
            <div>Kein Video zum Abspielen gefunden</div>
          )}
          <ProgressContainer
            className={cn("media-playback-loader", { active: loading })}
          >
            <ProgressSpinner size={100} />
          </ProgressContainer>
        </Wrapper>
      </Container>
      <MediaMeta video={video} open={meta} onClose={() => showMeta(false)} />
    </>
  );
};

const Container = styled.div`
  background: black;
`;
const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0 max(env(safe-area-inset-right), 1em) 0
    max(env(safe-area-inset-left), 1em);
  height: 60px;
  background: #1a1a1a;
  svg {
    color: white;
  }
`;
const HeaderTitle = styled.h1``;
const HeaderMore = styled.div``;
const Wrapper = styled.main`
  color: #888;
  width: 100%;
  position: relative;
  height: calc(100vh - 60px);
  background: #000;
  display: grid;
  place-items: center;
  overflow: hidden;

  svg {
    width: 5em;
    height: 5em;
  }
`;
const VideoContainer = styled(Styled("div", "nohls"))`
  aspect-ratio: 16/9;
  display: grid;
  .hls-container,
  &.nohls video {
    max-width: 100%;
    max-height: calc(100vh - 60px);
  }
`;
const ProgressContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  place-items: center;

  &.active {
    display: grid;
  }
`;

export const MediaPlaybackOverlay = ({
  open,
  dontFix,
  className,
  ...props
}) => {
  if (!open) return null;
  return (
    <>
      {!dontFix && <OverlayFixed />}
      <Overlay className={cn("media-playback-overlay", className)} {...props} />
    </>
  );
};
const OverlayFixed = createGlobalStyle`
	body, html {
		overflow: hidden;
	}
`;
const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 10;
`;

export default MediaPlayback;
