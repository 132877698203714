import React from "react";
import styled from "styled-components/macro";
import { isArray, isString } from "lodash";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { Star, StarOutline } from "@material-ui/icons";

const groupAttributes = (attributes) => {
  const map = {};
  for (const item of attributes) {
    const attribute = item.attribute;
    if (!attribute) {
      console.error("no attribute in item", item);
      continue;
    }
    if (item.attribute_id in map) {
      if (!isArray(map[item.attribute_id].value)) {
        map[item.attribute_id].value = [map[item.attribute_id].value];
      }
      map[item.attribute_id].value.push(item.value);
    } else {
      map[item.attribute_id] = { ...item, attribute: { ...item.attribute } };
    }
  }
  return Object.values(map);
};

const RangeView = ({ value }) => {
  return (
    <RangeWrapper>
      {[1, 2, 3, 4, 5].map((key) => {
        const cmp = Number(value);
        const Component = cmp >= key ? Star : StarOutline;
        return <Component key={key} className={`cmp-${value}`} />;
      })}
    </RangeWrapper>
  );
};

const ListView = ({ value }) => {
  return value.join(", ");
};

const AttributeView = ({ source }) => {
  if (source.attribute.type === "range") {
    return <RangeView value={source.value} />;
  }
  if (typeof source.value === "string") {
    return <ListView value={[source.value]} />;
  }
  return <ListView value={source.value} />;
};

const EventAttributeView = ({ event, attributes: givenAttributes }) => {
  const attributes = groupAttributes(givenAttributes);
  return (
    <>
      <Table size={"small"}>
        <TableBody>
          {attributes.map((attr) => {
            return (
              <TableRow id={attr.course_attribute_id}>
                <StyledTableCell>{attr.attribute.name}</StyledTableCell>
                <StyledTableCell>
                  <AttributeView source={attr} />
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};
const StyledTableCell = styled(TableCell)`
  border: none !important;
`;
const RangeWrapper = styled.div`
  position: relative;
  top: 3px;
`;

export default EventAttributeView;
