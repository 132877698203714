import React from "react";
import styled, { css } from "styled-components/macro";
import { Portal } from "@material-ui/core";
import PropTypes from "prop-types";

const dark = css`
  background: rgba(0, 0, 0, 0.7);
`;

const light = css`
  background: rgba(255, 255, 255, 0.4);
`;

const CenteredView = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: grid;
  align-items: center;
  justify-content: center;
  ${(props) => props.bg && props.bg === "dark" && dark}
  ${(props) => props.bg && props.bg === "light" && light}
`;

const CenteredPortal = (props) => {
  return (
    <Portal>
      <CenteredView {...props} />
    </Portal>
  );
};

CenteredPortal.propTypes = {
  bg: PropTypes.oneOf(["dark", "light"]),
};

export default CenteredPortal;
