import {STREAM_ACTION} from "../actions/streamActions";
import {isFunction} from "lodash";

const defaultState = {}

export const streamReducer = (state = defaultState, {type, data}) => {
	switch (type) {
		case 'RESET':
			return defaultState
		case STREAM_ACTION.SET:
			data = isFunction(data) ? data(state) : data
			return data
		default: return state
	}
}