import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { useVoucherConfirm } from "../../../actions/eventActions";
import { LoaderButton } from "../../../tools";
import { useSnackbar } from "notistack";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const EventConfirmVoucher = ({ eventId, onClose, updateEvent }) => {
  const { loading, confirmVoucher } = useVoucherConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const confirm = async () => {
    if (loading) return;
    const event = await confirmVoucher(eventId, true);
    enqueueSnackbar(
      t(
        "components.pages.event.EventConfirmVoucher.voucher-used",
        "Ihr Gutschein wurde eingelöst"
      ),
      { variant: "success" }
    );
    updateEvent?.(event);
    onClose?.();
  };

  return (
    <>
      <Dialog open={true} onClose={onClose}>
        <DialogContent>
          <DialogContentText>
            {t(
              "components.pages.event.EventConfirmVoucher.description",
              "Um diesen Kurs ansehen zu können, müssen Sie ihren Gutschein verwenden."
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            {t("labels.cancel", "Abbrechen").capital()}
          </Button>
          <LoaderButton loading={loading} color={"primary"} onClick={confirm}>
            {t(
              "components.pages.event.EventConfirmVoucher.use-voucher",
              "Verwende den Gutschein"
            )}
          </LoaderButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

EventConfirmVoucher.propTypes = {
  eventId: PropTypes.number.isRequired,
  onClose: PropTypes.func,
  updateEvent: PropTypes.func,
};

export default EventConfirmVoucher;
