import React from "react";
import { API_URL } from "../../constants";
import { HlsPlayer } from "../../tools";

const HlsPlayerScreen = ({ stream, setupOptions, ...props }) => {
  if (!stream) return null;

  return (
    <HlsPlayer
      streamId={stream.stream_id}
      roomId={stream.room_id}
      autoplay
      controls
      setupOptions={setupOptions}
      src={`${API_URL}/streams/${stream.club_id}/${stream.stream_id}/playlist.m3u8`}
      {...props}
    />
  );
};

export default HlsPlayerScreen;
