import React from "react";
import styled from "styled-components/macro";
import { Person } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import { Styled } from "../../../../tools";
import { formatDistanceToNow } from "../../../../lib/date";
import { useTranslation } from "react-i18next";

const Message = ({ message, ...props }) => {
  const { t } = useTranslation();
  return (
    <Item {...props}>
      <Header>
        <DateTime>
          {formatDistanceToNow(new Date(message?.created_at ?? DateTime.now()))}
        </DateTime>
        <NameWrapper>
          {message.is_instructor && (
            <Tooltip
              title={t(
                "components.pages.event.chat.EventChatMessages.instructor",
                "Instruktor"
              )}
            >
              <PersonIcon />
            </Tooltip>
          )}
          <Name>
            {message?.name ||
              t(
                "components.pages.event.chat.EventChatMessages.anonymous",
                "Anonymous"
              )}
          </Name>
        </NameWrapper>
      </Header>

      <Content>{message?.content || ""}</Content>
    </Item>
  );
};

const Item = styled.li`
  padding: 0.5em 1em;
  display: grid;
  gap: 0.3em;
  align-items: baseline;
  margin: 0.2em 0;
`;

const Header = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: min-content auto;
  align-items: center;
`;
const DateTime = styled.time`
  font-size: 0.68em;
  order: 2;
  white-space: nowrap;
`;
const NameWrapper = styled.div`
  font-size: 0.8em;
  font-weight: bold;
  order: 1;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
`;
const PersonIcon = styled(Person)`
  height: 1rem !important;
  margin-right: -7px;
`;
const Name = styled.div``;
const Content = styled.div`
  padding: 0.1em 0.5em;
  font-size: 0.9em;
  order: 3;
  width: 100%;
  line-height: 1.3em;
`;

const EventChatMessages = ({ event, messages, reverse, ...props }) => {
  return (
    <>
      <List reverse={reverse} {...props}>
        {messages.map((message) => {
          return <Message key={message.message_id} message={message} />;
        })}
      </List>
    </>
  );
};

const List = styled(Styled("ul", "reverse"))`
  display: flex;
  flex-direction: column;
  margin: -0.2em 0;
  &.reverse {
    flex-direction: column-reverse;
  }
`;

export default EventChatMessages;
