import StyledDialog from "../elements/misc/StyledDialog";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components/macro";
import { v4 } from "uuid";
import { LoaderButton } from "../tools";
import { usePasswordResetRequest } from "../actions/userActions";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const PasswordForgottenDialog = ({ id, onClose, ...props }) => {
  const { t } = useTranslation();
  const formId = id ?? v4();
  const mailId = v4();
  const [mail, setMail] = useState("");
  const { loading, request } = usePasswordResetRequest();
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = async (e) => {
    e.preventDefault();
    await request(mail, true);
    enqueueSnackbar(
      t(
        "components.PasswordForgottenDialog.email-sent",
        "Wir haben eine E-Mail versendet."
      ),
      { variant: "success" }
    );
    onClose?.();
  };
  return (
    <StyledDialog {...props}>
      <StyledDialogContent>
        <Header>
          {t(
            "components.PasswordForgottenDialog.header-title",
            "Passwortwiederherstellung"
          )}
        </Header>
        <Form id={formId} onSubmit={onSubmit}>
          <DialogContentText>
            {t(
              "components.PasswordForgottenDialog.description",
              "Geben Sie ihre E-Mail an und wir senden Ihnen einen Link."
            )}
          </DialogContentText>
          <TextField
            required
            variant={"outlined"}
            label={t(
              "components.PasswordForgottenDialog.email-label",
              "Ihre E-Mail"
            ).capital()}
            autoComplete={"current-email"}
            id={mailId}
            type={"email"}
            value={mail}
            onChange={(e) => setMail(e.target.value)}
          />
        </Form>
      </StyledDialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t("labels.cancel", "Abbrechen").capital()}
        </Button>
        <LoaderButton
          loading={loading}
          color={"primary"}
          form={formId}
          type={"submit"}
        >
          {t("labels.send", "Senden").capital()}
        </LoaderButton>
      </DialogActions>
    </StyledDialog>
  );
};
const StyledDialogContent = styled(DialogContent)`
  display: grid;
  gap: 2em;
  margin: 1em;
`;
const Header = styled.h2`
  font-weight: bolder;
  font-size: 1.2em;
`;
const Form = styled.form`
  display: grid;
  gap: 1em;
`;

export default PasswordForgottenDialog;
