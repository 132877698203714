import React, { useEffect, useRef } from "react";
import cn from "clsx";

const _buildUrl = (url, params = {}) => {
  let p = Object.entries(params);
  if (!p.length) return url;
  p = p.map(([key, value]) => `${key}=${value}`).join("&");
  return `${url}?${p}`;
};

const NativePlayer = React.forwardRef(
  ({ source, params, className, ...props }, ref) => {
    const video = useRef(ref);
    const url = _buildUrl(source, params);
    useEffect(() => {
      video?.current?.play?.();
    }, [!video?.current]);
    return (
      <video
        controls
        playsInline
        className={cn(className, "native-player")}
        {...props}
        src={url}
        ref={ref}
      />
    );
  }
);

export default NativePlayer;
