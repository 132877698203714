import React, { useEffect, useState } from "react";
import { formatDurationOfSeconds } from "../../lib/date";
import PropTypes from "prop-types";

/**
 *
 * @param givenValue duration in seconds
 * @param update interval update in miliseconds
 * @param direction update value direction (1 -> increases, -1 decreases)
 * @return {string|*}
 * @constructor
 */
const Duration = ({ value: givenValue, update, direction }) => {
  const [value, setValue] = useState(givenValue);
  useEffect(() => {
    setValue(givenValue ?? 0);
    let timer = null;
    if (update > 0) {
      timer = window.setInterval(() => {
        const diff = (update / 1000) * direction;
        setValue((v) => v + diff);
        console.debug("updating value", value, diff);
      }, update);
    }
    return () => {
      window.clearInterval(timer);
    };
  }, [givenValue, update]);

  return formatDurationOfSeconds(value, { simple: true });
};

Duration.propTypes = {
  update: PropTypes.number,
  value: PropTypes.number,
  direction: PropTypes.number,
};
Duration.defaultProps = {
  update: 0,
  value: 0,
  direction: 1,
};

export default Duration;
