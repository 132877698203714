import {uniqBy} from "lodash";
import {useState} from "react";
import {useError} from "./errorActions";
import {DELETE, GET, PUT} from "../lib";
import {useDispatch, useSelector} from "react-redux";

export const VOUCHER_ACTIONS = {
	SET: 'VOUCHER_SET'
}

export const voucherState__set = (vouchers = []) => ({type: VOUCHER_ACTIONS.SET, vouchers})
export const voucherState__add = (...voucher) => voucherState__set(list => uniqBy([...voucher, ...list], 'voucher_id'))
export const voucherState__remove = (...voucher) => voucherState__set(list => {
	const s = new Set(voucher.map(v => v.voucher_id || v))
	return list.filter(v => !s.has(v.voucher_id))
})


export const useVoucherList = () => {
	const [loading, setLoading] = useState(false)
	const [error, handle, clearError] = useError(true)
	const dispatch = useDispatch()
	const vouchers = useSelector(s => s.vouchers.list || [])
	
	const load = async (throws = null) => {
		try {
			setLoading(true)
			const list = await GET('/voucher')
			dispatch(voucherState__set(list))
			return list
		} catch (e) {
			return handle(e, throws)
		} finally {
			setLoading(false)
		}
	}
	
	return [vouchers, loading, error, {load, clearError}]
}

export const useVoucherCreation = () => {
	const [loading, setLoading] = useState(false)
	const [error, handle, clearError] = useError(true)
	const dispatch = useDispatch()
	
	const create = async (userId, times, throws = null) => {
		try {
			setLoading(true)
			const {voucher, used} = await PUT('/voucher', {user_id: userId, times})
			dispatch(voucherState__add(voucher))
			return [voucher, used]
		} catch (e) {
			return handle(e, throws)
		} finally {
			setLoading(false)
		}
	}
	return [loading, error, {create, clearError}]
}

export const useVoucherRemoval = () => {
	const [loading, setLoading] = useState(false)
	const [error, handle, clearError] = useError(true)
	const dispatch = useDispatch()
	
	const remove = async (voucherId, throws = null) => {
		try {
			setLoading(true)
			const voucher = await DELETE(`/voucher/id/${voucherId}`)
			dispatch(voucherState__remove(voucher))
			return voucher
		} catch (e) {
			return handle(e, throws)
		} finally {
			setLoading(false)
		}
	}
	return [loading, error, {remove, clearError}]
}