import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
// import { parse as defaultParse } from "date-fns";
import { format, parse } from "../../lib/date";
import { useTranslation } from "react-i18next";
// import { de } from "date-fns/locale";

// const parse = (source, how, refDate = null) =>
//   defaultParse(source, how, refDate || new Date(), { locale: de });
// const format = (what, how) => defaultFormat(what, how, { locale: de });

const transform = (origin, refDate = null, t = null) => {
  let how = "Hmm";
  let value = origin.replace(/\D/g, "").trim();
  switch (value.length) {
    case 1:
    case 2:
      how = "H";
      break;
    case 3:
    case 4:
      how = "Hmm";
      break;
    default:
      return null;
  }
  let next = parse(value, how, refDate);
  if (next.valueOf()) {
    return format(
      next,
      t ? t("tools.datepicker.datepicker.transform", "HH:mm") : "HH:mm"
    );
  }
  return null;
};

const validateDate = (dateValue, t = null) => {
  // eslint-disable-next-line
  const parts = dateValue
    .replace(/^[\.\s]+|[\.\s]+$/g, "")
    .split(".")
    .filter((v, i) => i < 3);
  let date;
  try {
    switch (parts.length) {
      case 1:
        date = parse(
          parts[0],
          t ? t("tools.datepicker.datepicker.validateDate.day", "d") : "d"
        );
        break;
      case 2:
        date = parse(
          `${parts[0]}.${parts[1]}`,
          t
            ? t("tools.datepicker.datepicker.validateDate.day-and-month", "d.M")
            : "d.M"
        );
        break;
      case 3:
        date = parse(
          parts.join("."),
          parts[2].length > 2
            ? t
              ? t(
                  "tools.datepicker.datepicker.validateDate.date-long",
                  "d.M.yyyy"
                )
              : "d.M.yyyy"
            : t
            ? t("tools.datepicker.datepicker.validateDate.date-short", "d.M.yy")
            : "d.M.yy"
        );
        break;
      default:
    }
    if (!date.valueOf()) {
      throw Error("failed to parse");
    }
    return [
      true,
      format(
        date,
        t
          ? t(
              "tools.datepicker.datepicker.validateDate.date-format",
              "dd.MM.yyyy"
            )
          : "dd.MM.yyyy"
      ),
    ];
  } catch (e) {
    return [false, dateValue];
  }
};

const validateTime = (time, refDate = null, t = null) => {
  const next = transform(time, refDate, t);
  return [Boolean(next), next || time];
};

const handleData = (value, baseDate = null, t = null) => {
  const parts = value.trim().split(/\s+/);
  if (parts.length === 2) {
    const [dateOk, dateString] = validateDate(parts[0], t);
    const [timeOk, timeString] = validateTime(parts[1], baseDate, t);
    if (timeOk && dateOk) {
      value = [dateString, timeString].join(" ");
      return [
        value,
        parse(
          value,
          t
            ? t(
                "tools.datepicker.datepicker.handleData.date-time",
                "dd.MM.yyyy HH:mm"
              )
            : "dd.MM.yyyy HH:mm",
          baseDate
        ),
      ];
    }
    return [value, null];
  } else if (parts.length === 1) {
    const [timeOk, timeString] = validateTime(parts[0], baseDate, t);
    if (timeOk) {
      let date = parse(
        timeString,
        t ? t("tools.datepicker.datepicker.handleData.time", "HH:mm") : "HH:mm",
        baseDate
      );
      return [
        format(
          date,
          t
            ? t(
                "tools.datepicker.datepicker.handleData.date-time",
                "dd.MM.yyyy HH:mm"
              )
            : "dd.MM.yyyy HH:mm"
        ),
        date,
      ];
    }
    return [value, null];
  } else {
    return [value, null];
  }
};

const init = (value, t = null) => {
  if (typeof value === "number") {
    return new Date(value);
  }
  if (typeof value === "string") {
    if (!value.trim()) {
      return "";
    }
    return init(value);
  }
  if (value instanceof Date) {
    return format(
      value,
      t
        ? t("tools.datepicker.datepicker.init", "dd.MM.yyyy HH:mm")
        : "dd.MM.yyyy HH:mm"
    );
  }
  throw Error("No correct format given!");
};

const DateTimePicker = ({
  onChange,
  onProvide,
  baseDate,
  preview: showPreview,
  value: givenValue,
  ...props
}) => {
  const [value, setValue] = useState(init(givenValue || ""));
  const [error, setError] = useState(false);
  const [preview, setPreview] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    !showPreview && setPreview("");
  }, [showPreview]);

  return (
    <TextField
      {...props}
      type={"text"}
      onChange={(e) => {
        const tmp = e.target.value;
        setValue(tmp);
        if (showPreview) {
          const [value, date] = handleData(tmp, baseDate, t);
          if (tmp.trim() && date) {
            setPreview(value);
          } else if (!value.trim()) {
            setPreview("");
          } else if (!date) {
            setPreview(t("tools.datepicker.datepicker.invalid", "ungültig"));
          }
        }
      }}
      value={value}
      placeholder={"tt.mm.jjjj ss:mm"}
      error={error}
      helperText={
        preview &&
        t("tools.datepicker.datepicker.preview", "Vorschau: {{preview}}", {
          preview,
        })
      }
      onBlur={() => {
        setPreview("");
        const [newVal, date] = handleData(value || "", baseDate, t);
        if (value.trim() && date) {
          setError(false);
          onProvide && onProvide(date);
          setValue(newVal);
        } else if (!value.trim()) {
          onProvide && onProvide(null);
        } else if (!date) {
          setError(true);
        }
      }}
    />
  );
};

export default DateTimePicker;
