import React from "react";
import styled from "styled-components/macro";
import { Timelapse } from "@material-ui/icons";
import * as PropTypes from "prop-types";

const EventStateComingUp = ({ children, message, ...props }) => {
  return (
    <Teaser {...props}>
      <TeaserIcon />
      <TeaserText>{message}</TeaserText>
      {children}
    </Teaser>
  );
};
EventStateComingUp.defaultProps = {
  message: "components.pages.event.EventStateComingUp.default",
};
EventStateComingUp.propTypes = {
  message: PropTypes.string,
};
export default EventStateComingUp;
const Teaser = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  gap: 20px;
`;
const TeaserIcon = styled(Timelapse)`
  width: 60px !important;
  height: 60px !important;
  fill: #c5d60c !important;
`;
const TeaserText = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
`;
