import React from "react";
import styled from "styled-components/macro";
import DateToggle from "../../../../../admin/media/components/DateToggle";

const WrappedHlsMeta = ({ video, children, ...props }) => {
  if (video) {
    return (
      <Wrapper>
        <Title>
          {video?.meta?.title ?? video?.course?.name ?? "Freie Aufnahme"}
        </Title>
        <Datetime>
          <DateToggle source={video?.created_at} />
        </Datetime>
        {children}
      </Wrapper>
    );
  }
  return children;
};

const Wrapper = styled.div`
  color: white;
  display: grid;
  gap: 1em;
  justify-items: center;
`;
const Title = styled.h1`
  font-weight: bold;
  font-size: 1.2em;
`;
const Datetime = styled.div`
  font-size: 0.9em;
`;
export default WrappedHlsMeta;
