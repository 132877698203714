// let process = process || {};
export const IS_DEV = process.env.NODE_ENV !== "production";
export const BUILD = parseInt(process.env.REACT_APP_BUILD) || 0;
export const BUILD_DATE = new Date(
  parseInt(process.env.REACT_APP_BUILD_DATE) || 0
);
window.BUILD = BUILD;
window.BUILD_DATE = BUILD_DATE;
export const ENV = process.env.REACT_APP_ENV || "dev";
export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000";
export const APP_NAME = process.env.REACT_APP_NAME || "Machmit.tv";
export const SELF_URL = process.env.REACT_APP_URL || "http://localhost:3000";
export const COLOR = {
  PRIMARY: "#c5d60c",
  EVENT_PAST: "#a72222",
  ERROR: "#a72222",
  ADMIN_PRIMARY: "#616161",
  WARNING: "#dc6242",
};
export const CLIPBOARD_API = "clipboard" in navigator;
export const DATETIME_FORMAT_WEEKDAY = `EEEEEE '-' dd.MM.yyyy 'um' HH:mm 'Uhr'`;
// t("date.datetime-format-weekday", "EEEEEE '-' dd.MM.yyyy 'um' HH:mm 'Uhr'")
export const DATETIME_FORMAT = `dd.MM.yyyy 'um' HH:mm 'Uhr'`;
// t("date.datetime-format", "dd.MM.yyyy 'um' HH:mm 'Uhr'")
export const DATE_FORMAT = `dd.MM.yyyy`;
// t("date.date-format", "dd.MM.yyyy")
export const DATE_FORMAT_WEEKDAY = `EEEEEE '-' dd.MM.yyyy`;
// t("date.date-format-weekday", "EEEEEE '-' dd.MM.yyyy")
export const TIME_FORMAT = `HH:mm`;
// t("date.time-format", "HH:mm")
export const DATE_TIMELINE_FORMAT = `EEEE d.M. 'um' HH:mm 'Uhr'`;
// t("date.date-timeline-format", "EEEE d.M. 'um' HH:mm 'Uhr'")
export const WEEKDAY_FULL = "EEEE";
// t("date.weekday-full", "EEEE")
export const WEEKDAY_ONE = "EEEEE";
// t("date.weekday-one", "EEEEE")
export const WEEKDAY_TWO = "EEEEEE";
// t("date.weekday-two", "EEEEEE")
export const WEEKDAY_THREE = "EEE";
// t("date.weekday-three", "EEE")

export const FORMAT_RELATIVE_DATE = {
  lastWeek: "'Letzten' EEEE 'um' HH:mm",
  yesterday: "'Gestern um' HH:mm",
  today: "'Heute um' HH:mm",
  tomorrow: "'Morgen um' HH:mm",
  nextWeek: "EEEE 'um' HH:mm",
  other: "EEEE, d.M. 'um' HH:mm",
};
// t("relative-date.lastWeek", "'Letzten' EEEE 'um' HH:mm")
// t("relative-date.yesterday", "'Gestern um' HH:mm")
// t("relative-date.today", "'Heute um' HH:mm")
// t("relative-date.tomorrow", "'Morgen um' HH:mm")
// t("relative-date.nextWeek", "EEEE 'um' HH:mm")
// t("relative-date.other", "EEEE, d.M. 'um' HH:mm")

export const HLS_PLAYBACK_OPTIONS = {
  enableWorker: true,
  // maxBufferLength: 5,
  liveSyncDuration: 0.4,
  liveMaxLatencyDuration: 2,
  liveDurationInfinity: true,
  lowLatencyMode: true,
  // backBufferLength: 2,
  // highBufferWatchdogPeriod: 1,
};

export const HLS_PLAYBACK_OPTIONS_EXT = {
  // maxBufferLength: 30,
  liveSyncDuration: 3,
  liveMaxLatencyDuration: 6,
  liveDurationInfinity: true,
  lowLatencyMode: true,
  // capLevelToPlayerSize: true,
  // debug: true,
  // backBufferLength: 10,
  // highBufferWatchdogPeriod: 1,
};
