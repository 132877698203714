import { css } from "styled-components/macro";

export const Plate = css`
  border-radius: 10px;
  box-shadow: inset 0 0 1px 0.5px rgba(255, 255, 255, 0.8),
    0 3px 3px -2px rgb(0 0 0 / 80%), 0px 3px 4px 0px rgb(0 0 0 / 94%),
    0px 1px 8px 0px rgb(0 0 0 / 92%);
  background: linear-gradient(
    145deg,
    rgba(226, 221, 221, 0.6) 0%,
    rgba(255, 255, 255, 0.8) 100%
  );
  color: rgba(0, 0, 0, 0.8);
`;

export const PlatePlain = css`
  background: linear-gradient(
    145deg,
    rgb(189, 185, 185) 0%,
    rgb(232, 230, 230) 100%
  );
`;

export const DialogStyle = css`
  > div > div {
    ${Plate};
    background: linear-gradient(
      145deg,
      rgba(159, 157, 157, 0.96) 0%,
      rgba(221, 220, 220, 1) 100%
    );
  }
`;

export const Glass = css`
  background-color: rgba(255, 255, 255, 0.95) !important;
  text-shadow: 0 0 0.4em white;
  box-shadow: inset 0 0 1.5px rgba(50, 50, 50, 0.8),
    0 4px 8px rgba(0, 0, 0, 0.8);
  @supports (backdrop-filter: blur(5px)) {
    background-color: rgba(255, 255, 255, 0.5) !important;
    backdrop-filter: blur(5px);
  }
`;

export const AdminDayRow = css`
  background: rgb(250, 250, 250);
  background: linear-gradient(
    0deg,
    rgba(250, 250, 250, 0) 0%,
    rgba(250, 250, 250, 1) 100%
  );
  td {
    padding: 1.5em 0 0 0;
    border-bottom: none !important;
  }
`;
