import { isNumber } from "lodash";
import bgs from "../images/fitnessBackgrounds";
import { API_URL } from "../constants";

export const reduceOptionsBy = (needles, list) => {
  for (const id in needles) {
    if (needles[id]) {
      return list[id];
    }
  }
  return null;
};

export const getOpacity = (value) => {
  if (isNumber(value)) {
    value = Math.max(0, value);
    value = Math.min(100, value);
    return value / 100;
  }
  return null;
};

export const getIndex = (index, add = 0) => {
  return (index + add) % bgs.length;
};

export const getImageUrl = (image) =>
  `${API_URL}/files/images/${image?.club_id ?? 0}/${image?.image_id ?? 0}.${
    image?.extension ?? "jpeg"
  }`;

export const StateDecorator = (
  method,
  action = { stop: false, prevent: false }
) => (state) => (e) => {
  action?.stop && e?.stopPropagation?.();
  action?.prevent && e?.preventDefault?.();
  if (typeof state === "function") {
    state = state(e);
  }
  method(state);
};

export const array2object = (list, how) => {
  const map = {};
  for (const item of list) {
    map[how(item)] = item;
  }
  return map;
};

export const median = (items) => {
  const sorted = items.sort();
  const middle = Math.floor(sorted.length / 2);
  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }
  return sorted[middle];
};

export const secondsTo = (seconds, source) => {
  const back = {};
  let callee;
  let remainder = seconds;
  if ("days" in source) {
    callee = source.hours in Math ? source.hours : "floor";
    back.days = Math[callee](remainder / 86400);
    remainder = remainder % 86400;
  }
  if ("hours" in source) {
    callee = source.hours in Math ? source.hours : "floor";
    back.hours = Math[callee](remainder / 3600);
    remainder = remainder % 3600;
  }
  if ("minutes" in source) {
    callee = source.hours in Math ? source.hours : "floor";
    back.minutes = Math[callee](remainder / 60);
    remainder = remainder % 60;
  }
  if ("seconds" in source) {
    callee = source.hours in Math ? source.hours : "floor";
    back.seconds = Math[callee](remainder);
  }
  return back;
};
