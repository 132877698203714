import React from "react";
import { Route, Switch } from "react-router";
import CourseTimeLine from "../pages/courses/CourseTimeLine";
import NotFoundPage from "../../pages/NotFoundPage";
import EventView from "../pages/event/EventView";
import ProfileView from "../pages/profile/ProfileView";
import ResetPasswordConfirmPage from "../pages/password/ResetPasswordConfirmPage";
import BookmarkedPage from "../pages/bookmarked/BookmarkedPage";
import OverviewWeekPage from "../pages/overview/OverviewWeekPage";
import MediaPage from "../pages/media/MediaPage";
import MediaDetailPage from "../pages/media/MediaDetailPage";

export default () => {
  return (
    <Switch>
      <Route exact path={"/"} component={CourseTimeLine} />
      <Route exact path={"/bg/:bg_id"} component={CourseTimeLine} />
      <Route path={"/event/:event_id/:bg?"} component={EventView} />
      <Route path={"/profile/:bg?"} component={ProfileView} />
      <Route path={"/bookmarked"} component={BookmarkedPage} />
      <Route path={"/media/view/:id"} component={MediaDetailPage} />
      <Route path={"/media/:page?"} component={MediaPage} />
      <Route path={"/overview/week/:base?"} component={OverviewWeekPage} />
      <Route
        path={"/reset/password/:token"}
        component={ResetPasswordConfirmPage}
      />
      <Route component={NotFoundPage} />
    </Switch>
  );
};
