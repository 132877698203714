import { LoginPage } from "./Login";
import { ConnectedRouter } from "connected-react-router";
import { history } from "../lib";
import { Route, Switch } from "react-router";
import { CreateClubView } from "./CreateClub";
import { ClubListView } from "./ClubList";
import { ClubDetailView } from "./ClubDetail";
// import { Frame } from "./Frame";
import { SUBSCRIPTIONS, usePubSub, useSSE, useSubscription } from "../actions";
import React, { useEffect, Suspense } from "react";
import { set401Handler } from "../lib/myfetch";
import { useLogout } from "../actions/userActions";
import { SnackbarProvider, useSnackbar } from "notistack";
import { TokenViewRoutes } from "../view";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { de } from "date-fns/locale";
import ClientRootRoutes from "./routes/ClientRootRoutes";
import RegisterPage from "../pages/RegisterPage";
// import TestVideo from "../pages/TestVideo";
import { miscState__setSseOnline } from "../actions/miscActions";
// import Debug from "../pages/Debug";
import { PageTitle } from "../tools";
import { useTranslation } from "react-i18next";
import JoinClubPage from "../pages/JoinClubPage";
import StreamTest from "./StreamTest";

const FrameView = React.lazy(() => import("./Frame"));

const Internal = ({ children }) => {
  useSSE({
    debug: console.debug,
    onStateChange: (state, sse, dispatch) => {
      dispatch(miscState__setSseOnline(state));
    },
  });
  return <Switch>{children}</Switch>;
};

function App() {
  // handle logout by session timeout
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [publish] = usePubSub();
  const logout = useLogout();
  useSubscription(SUBSCRIPTIONS.SESSION_TIMOUT, () => {
    logout();
    enqueueSnackbar(
      t(
        "components.App.access-timedout",
        "Ihre Zugang ist abgelaufen, bitte neu einloggen"
      ),
      {
        variant: "warning",
      }
    );
  });
  useEffect(() => {
    if ("ontouchstart" in window) {
      document.body.classList.add("touchable");
    } else {
      document.body.classList.add("untouchable");
    }
    set401Handler((json, response) =>
      publish(SUBSCRIPTIONS.SESSION_TIMOUT, json, response)
    );
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageTitle />
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path={"/clubs"} component={ClubListView} />
          <Route path={"/clubs/create"} component={CreateClubView} />
          <Route path={"/club/:tag"} component={ClubDetailView} />
          <Route exact path={"/user"} component={LoginPage} />
          <Route path={"/user/invite/:invite"} component={LoginPage} />
          <Route path={"/view/:token_id/:bg?"} component={TokenViewRoutes} />
          <Route path="/stream-test" component={StreamTest} />
          <Route
            path={["/register/invite/:invite", "/register/:email?"]}
            component={RegisterPage}
          />

          <Route path={"/join/club/:slug/:token"} component={JoinClubPage} />
          <Route
            path={"/session"}
            render={() => (
              <LoginPage
                error={t("components.App.login-failed", "Login gescheitert")}
              />
            )}
          />
          {/* <Route path={"/debug"} component={Debug} /> */}
          <Internal>
            <Route
              path={"/admin"}
              render={() => (
                <Suspense fallback={"Initiating admin"}>
                  <FrameView />
                </Suspense>
              )}
            />

            <Route component={ClientRootRoutes} />
          </Internal>
        </Switch>
      </ConnectedRouter>
    </>
  );
}

const Wrapped = () => (
  <SnackbarProvider
    maxSnack={5}
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
    preventDuplicate
    autoHideDuration={3000}
  >
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
      <App />
    </MuiPickersUtilsProvider>
  </SnackbarProvider>
);

export default Wrapped;
