import React from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import PropTypes from "prop-types";
import Color from "color";

const Fixed = styled.div`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
`;

const BodyStyle = createGlobalStyle`
	body {
		background-color: ${(props) =>
      props.backgroundColor
        ? `${props.backgroundColor} !important`
        : "inherit"};
		min-height: 100vh;
		position: relative;
	}
`;

const BackgroundContainer2 = styled(Fixed)`
  background-color: ${(props) =>
    props.backgroundColor ? `${props.backgroundColor} !important` : "inherit"};
  background-image: ${(props) =>
    `linear-gradient(0deg, ${props.backgroundColor} 0%, ${Color(
      props.backgroundColor
    ).fade(1)} 25%, ${Color(props.backgroundColor).fade(1)} 75%, ${
      props.backgroundColor
    } 100%), url(${props.background})` || "inherit"};
  background-size: ${(props) => props.size || "auto, cover"};
  background-repeat: ${(props) => props.repeat || "no-repeat, no-repeat"};
  background-position: ${(props) => props.position || "center, 90%"};
`;

const BackgroundContainer = createGlobalStyle`
	body {
		background-color: ${(props) =>
      props.backgroundColor
        ? `${props.backgroundColor} !important`
        : "inherit"};
		background-image: ${(props) =>
      `linear-gradient(0deg, ${props.backgroundColor} 0%, ${Color(
        props.backgroundColor
      ).fade(1)} 25%, ${Color(props.backgroundColor).fade(1)} 75%, ${
        props.backgroundColor
      } 100%), url(${props.background})` || "inherit"};
		background-size: ${(props) => props.size || "100%, cover"};
		background-repeat: ${(props) => props.repeat || "no-repeat, no-repeat"};
		background-position: ${(props) => props.position || "bottom, center"};
      ${(p) =>
        p.filter &&
        `
        backdrop-filter: ${p.filter};
        
      `}
	}
`;

const FilterLayer = styled(Fixed)`
  position: fixed;
  width: inherit;
  height: inherit;
  bottom: 0;
  right: 0;
  background-color: transparent;
`;

const Background = ({
  background,
  backgroundColor,
  filter,
  size,
  repeat,
  position,
}) => {
  return (
    <>
      <BodyStyle backgroundColor={backgroundColor} />
      <BackgroundContainer
        background={background}
        backgroundColor={backgroundColor}
        size={size}
        repeat={repeat}
        position={position}
        filter={filter}
      />
      {/*{filter && (
        <FilterLayer
          style={{
            backdropFilter: filter || null,
          }}
        />
      )}*/}
    </>
  );
};

Background.propTypes = {
  background: PropTypes.string,
  backgroundColor: PropTypes.string,
  filter: PropTypes.string,
};
Background.defaultProps = {
  backgroundColor: "#444",
};

export default Background;
