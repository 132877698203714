import React from "react";
import styled from "styled-components/macro";
import {
  Icon_Fitness as Icon,
  Icon_Fitness_Active as Icon_Active,
} from "../../images";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

const AppIcon = ({ online: givenOnline }) => {
  const { push } = useHistory();
  const online = useSelector((s) => givenOnline ?? s.misc.sseOnline ?? false);
  return (
    <MachmitIconWrapper
      onClick={(e) => {
        e.stopPropagation();
        push("/");
      }}
    >
      <MachmitIcon src={online ? Icon_Active : Icon} alt={"AppIcon"} />
    </MachmitIconWrapper>
  );
};
const MachmitIconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  transition: 0.2s ease;
  cursor: pointer;

  &:hover {
    /*box-shadow: inset 0 1px 1px rgba(200, 200, 255, 0.3),
      1px 0 5px rgba(200, 255, 255, 0.2),
      inset 0 -1px 1px rgba(200, 200, 255, 0.3),
      -1px 0 2px rgba(200, 255, 255, 0.2);*/
    box-shadow: inset 0 0 1.5px rgba(255, 255, 255, 0.8), 0 5px 10px black;
    //backdrop-filter: blur(15px);
  }
`;
const MachmitIcon = styled.img`
  max-height: 40px;
`;

export default AppIcon;
