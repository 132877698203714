import React, { useEffect, useRef, useState } from "react";
import EventPresentationAccessWrapper from "./EventPresentationAccessWrapper";
import EventPresentationSSE from "./EventPresentationSSE";
import PlayerScreenUI from "../../../elements/misc/PlayerScreen";
import styled from "styled-components/macro";
import EventChatUI from "./chat/EventChat";
import { useSnackbar } from "notistack";
import { Camera, Close, Fullscreen, FullscreenExit } from "@material-ui/icons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import EventChatOverlay from "./chat/EventOverlayChat";
import { Styled } from "../../../tools";
import { useHls } from "../../../tools/player/hls-player";
import OnlineEventUi from "./chat/EventOnline";
import { useUser } from "../../../actions/userActions";
import { COLOR } from "../../../constants";
import KeyHandler, { KEYUP } from "react-key-handler";
import { useStateDecorator } from "../../../actions";
import { useTranslation } from "react-i18next";

const EventPresentation = ({ token, event, onClose, SSE }) => {
  const closer = (e) => {
    if (onClose && e.which === 27) {
      onClose();
    }
  };
  const [user] = useUser();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [chatVisible, setChatVisible] = useStateDecorator(
    event?.chat_active ?? false
  );
  const hlsOk = useHls();
  useEffect(() => {
    window.addEventListener("keyup", closer, true);
    return () => {
      window.removeEventListener("keyup", closer);
    };
  }, []);
  return (
    <>
      <EventPresentationAccessWrapper
        token={token}
        event={event}
        onClose={onClose}
      >
        <EventPresentationSSE event={event} token={user ? null : token} />
        <PresentationWrapper>
          <PlayerScreen
            chatDisabled={!event?.chat_active}
            showViewers
            onViewersClick={event?.chat_active ? setChatVisible(true) : null}
            viewersProps={{
              style: { cursor: chatVisible ? "default" : "pointer" },
            }}
            streamProps={{
              token,
              levelControls: true,
              controls: false,
              onError: (e) => {
                alert(`Error on stream [ ${e?.target?.error?.code} ]`);
              },
            }}
            onPlayerError={(e, errorMessage) => {
              enqueueSnackbar(
                errorMessage ??
                  e?.response?.text ??
                  e?.reason ??
                  t(
                    "components.pages.event.EventPresentation.default-stream-error",
                    "Durch einen Fehler konnte der Stream nicht angeschaut/fortgesetzt werden."
                  ),
                {
                  variant: "error",
                  persist: true,
                  action: (key) => (
                    <>
                      <Button
                        color={"inherit"}
                        onClick={() => closeSnackbar(key)}
                      >
                        <Close />
                      </Button>
                    </>
                  ),
                }
              );
              onClose?.();
            }}
            title={
              event?.course?.name ??
              t(
                "components.pages.event.EventPresentation.your-course-label",
                "Dein Kurs"
              )
            }
            stream={event?.room?.stream ?? null}
            setupOptions={event?.room?.hls_options ?? {}}
            open={true}
            onClose={onClose}
            overlay={hlsOk ? HlsOverlay : null}
          />
          {chatVisible && user && (
            <>
              <Spacer />
              <EventChat event={event} onHide={setChatVisible(false)} />
            </>
          )}
        </PresentationWrapper>
      </EventPresentationAccessWrapper>
    </>
  );
};

const SelfiCamView = ({ onClose }) => {
  const video = useRef();
  const [error, setError] = useState("");
  const [stream, setStream] = useState(null);
  const { t } = useTranslation();

  const accessStream = async (constraints) => {
    let _stream = null;
    try {
      _stream = await navigator.mediaDevices.getUserMedia(constraints);
      video.current.srcObject = _stream;
      setStream(_stream);
      video.current.onloadedmetadata = (e) => {
        video?.current?.play?.();
      };
    } catch (e) {
      setError(
        e.message ??
          t(
            "components.pages.event.EventPresentation.unknown-error",
            "Unbekannter Fehler"
          )
      );
      console.error(e);
      throw e;
    }
  };
  useEffect(() => {
    if (video?.current) {
      try {
        accessStream({ video: true, audio: false });
      } catch (e) {}
    }
  }, [Boolean(video?.current)]);
  const quit = () => {
    setError("");
    onClose?.();
  };
  useEffect(() => {
    return () => {
      stream?.getTracks?.().forEach?.((track) => {
        track?.stop?.();
      });
    };
  }, [stream?.id ?? 0]);
  return (
    <>
      <Selficam ref={video}>
        {/*<SelfiCloseButton onClick={setShowSelfi(false)}>*/}
        {/*  <CloseIcon />*/}
        {/*</SelfiCloseButton>*/}
      </Selficam>
      <Dialog open={Boolean(error)} onClose={quit}>
        <DialogContent>
          <DialogContentText>
            {t(
              "components.pages.event.EventPresentation.error-camera-access",
              "Fehler beim Kamerazugriff: {{error}}",
              { error }
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={quit}>{t("labels.ok", "Ok").capital()}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const Selficam = styled.video`
  width: auto !important;
  //height: calc((20% / (16 / 9)));
  display: block;
  height: 25% !important;
  position: absolute;
  left: 0;
  bottom: 0;
  background: transparent;
`;

const _camAccessible = () => {
  if (!("navigator" in window)) return false;
  return Boolean(navigator.mediaDevices);
};

const Overlay = ({ handle }) => {
  const [showSelfi, _setShowSelfi] = useState(false);
  const [camAvailable, setCamAvailable] = useState(false);
  const { t } = useTranslation();
  const setShowSelfi = (state) => (e) => {
    e?.stopPropagation();
    _setShowSelfi(state);
  };
  useEffect(() => {
    setCamAvailable(_camAccessible());
  }, []);
  return (
    <>
      {camAvailable && (
        <KeyHandler
          keyValue={"s"}
          keyEventName={KEYUP}
          onKeyHandle={setShowSelfi(!showSelfi)}
        />
      )}
      <KeyHandler
        keyValue={"f"}
        keyEventName={KEYUP}
        onKeyHandle={handle.active ? handle.exit : handle.enter}
      />
      <ScreenOverlay>
        <ScreenNavigation>
          {camAvailable && (
            <Tooltip
              placement={"top"}
              title={
                showSelfi
                  ? t(
                      "components.pages.event.EventPresentation.hotkey-hide-camera",
                      "Kamera ausblenden (s)"
                    )
                  : t(
                      "components.pages.event.EventPresentation.hotkey-show-camera",
                      "Kamera einblenden (s)"
                    )
              }
            >
              <IconButton onClick={setShowSelfi(!showSelfi)}>
                <CameraIcon active={showSelfi} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            title={
              handle.active
                ? t(
                    "components.pages.event.EventPresentation.hotkey-close-fullscreen",
                    "Vollbild beenden (f)"
                  )
                : t(
                    "components.pages.event.EventPresentation.hotkey-show-fullscreen",
                    "Vollbild anzeigen (f)"
                  )
            }
            placement={"top"}
          >
            <FullscreenToggle>
              <FullscreenButton active={!handle.active} onClick={handle.enter}>
                <FullscreenIcon />
              </FullscreenButton>
              <FullscreenButton active={handle.active} onClick={handle.exit}>
                <FullscreenExitIcon />
              </FullscreenButton>
            </FullscreenToggle>
          </Tooltip>
        </ScreenNavigation>
        {showSelfi && <SelfiCamView onClose={setShowSelfi(false)} />}
      </ScreenOverlay>
    </>
  );
};

const HlsOverlay = (handle) => (
  <>
    <Overlay handle={handle} />
    {handle.active && <ChatOverlay />}
    <OnlineNotice />
  </>
);

const OnlineNotice = styled(OnlineEventUi)`
  position: absolute;
  top: 0.2em;
  left: 1em;
  line-height: 1.3em;
  color: #222;
  text-shadow: 0 0 3px #ddd;
  // max-height: ${(props) => props.maxHeight || "150px"};
`;

const CameraIcon = styled(({ active, ...props }) => <Camera {...props} />)`
  color: ${(props) => (props.active ? COLOR.PRIMARY : "white")};
`;

const PresentationWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 20;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: black;
  padding: 1em;
  //overflow-y: auto;
  @media all and (max-width: 1020px) {
    display: block;
    overflow-y: auto;
    padding: 0;
  }
`;

const Spacer = styled.div`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  @media all and (max-width: 1020px) {
    height: 0;
  }
`;
const PlayerScreen = styled(PlayerScreenUI)`
  box-shadow: none;
  max-width: 100%;
  @media all and (max-width: 1020px) {
    flex-shrink: 0;
    border-radius: 0;
    margin: 0;
    padding: 0;
    .player-screen-footer {
      display: none;
    }
  }
  video {
    display: flex;
    width: 100%;
    max-width: 100vw;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  .hls-stream-selector {
    right: -100%;
    opacity: 0;
    transition: 2500ms;
    margin-top: 1em;
    margin-right: 0.5em;
    z-index: 100;
    .hls-current-selection {
      text-shadow: 0 0 2px black;
    }
  }
  &:hover {
    .hls-stream-selector {
      right: 0;
      opacity: 1;
      transition: 400ms;
    }
  }
`;
const EventChat = styled(EventChatUI)`
  flex-grow: 1;
`;

const ScreenOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  //z-index: 2147483647;
  overflow: hidden;
`;

const ScreenNavigation = styled.div`
  display: flex;
  opacity: 0;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  justify-content: flex-end;
  align-content: center;
  transform: translateY(100%);
  padding: 0.5em;
  transition: all 300ms ease;
  .player-screen-body:hover & {
    transform: translateY(0);
    opacity: 1;
  }
`;

const ChatOverlay = styled(EventChatOverlay)`
  position: absolute;
  right: 2em;
  max-height: 50vh;
  bottom: 45vh;
`;

const FullscreenToggle = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;
const FullscreenButton = styled(Styled(IconButton, "active"))`
  position: absolute !important;
  opacity: 0;
  right: 0;
  bottom: 0;
  width: 50px !important;
  height: 50px !important;
  transform: scaleY(0);
  transition: all 300ms ease;
  &.active {
    transform: scale(1);
  }
  ${ScreenOverlay}:hover & {
    opacity: 1;
  }
`;
const FullscreenIcon = styled(Fullscreen)`
  color: white;
  font-size: 1.5em !important;
  text-shadow: 0 0 5px black;
  transition: all 300ms ease;
`;
const FullscreenExitIcon = styled(FullscreenExit)`
  color: white;
  font-size: 1.5em !important;
  text-shadow: 0 0 5px black;
  transition: all 300ms ease;
`;

export default EventPresentation;
