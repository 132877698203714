import { MISC_ACTIONS } from "../actions/miscActions";

const defaultState = {
  sseOnline: false,
};

export const miscReducer = (state = defaultState, { type, sseOnline }) => {
  switch (type) {
    case "RESET":
      return defaultState;
    case MISC_ACTIONS.SET_SSE_ONLNE:
      if (typeof sseOnline === "function") {
        sseOnline = sseOnline(state.sseOnline);
      }

      return {
        ...state,
        sseOnline: Boolean(sseOnline),
      };
    default:
      return state;
  }
};
