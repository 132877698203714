import React from "react";
import styled from "styled-components/macro";
import { Plate } from "../../../lib/style/Templates";

const CourseIntro = ({ clubName, description, onClick }) => {
  return (
    <IntroWrapper>
      <Title onClick={onClick}>{clubName}</Title>
      {description && (
        <>
          <Divider />
          <Description>{description}</Description>
        </>
      )}
    </IntroWrapper>
  );
};
CourseIntro.defaultProps = {
  clubName: "Dein Clubname",
};

const IntroWrapper = styled.div`
  display: grid;
  justify-items: center;
`;
const Title = styled.h1`
  font-size: 50px;
  line-height: 70px;
  text-align: center;
  color: #c5d60c;
  font-weight: 500;
  max-width: 100%;
`;
const Divider = styled.div`
  height: 0.1px;
  box-shadow: 0 0 3px rgba(255, 255, 255, 0.9);
  margin: 20px 0 30px;
  width: 100%;
  max-width: 500px;
`;
const Description = styled.p`
  ${Plate};
  display: inline-block;
  padding: 1rem 1rem;
  line-height: 1.5rem;
  margin: 40px auto 0;
  max-width: 550px;
  @media all and (max-width: 960px) {
    font-size: 1rem;
    line-height: 1.7rem;
    margin: 30px auto 70px;
  }
`;

export default CourseIntro;
