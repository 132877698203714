import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import { getAuthToken, getStreamToken } from "../../lib/myfetch";
import {
  HLS_PLAYBACK_OPTIONS,
  HLS_PLAYBACK_OPTIONS_EXT,
} from "../../constants";
import { useTranslation } from "react-i18next";
import { useStreamErrorReport } from "../../actions/streamActions";
import styled from "styled-components/macro";
import StreamSelector from "../../components/test/StreamSelector";

const _getErrorMessage = (code, t) => {
  switch (code) {
    case 400:
      return t(
        "tools.player.hls-player.error-400",
        "Notwendige Daten für den Stream fehlen!"
      );
    case 401:
      return t(
        "tools.player.hls-player.error-401",
        "Sie sind nicht authentifiziert."
      );
    case 403:
      return t(
        "tools.player.hls-player.error-403",
        "Sie sind nicht authorisiert, diesen Stream anzusehen."
      );
    case 404:
      return t(
        "tools.player.hls-player.error-404",
        "Der Stream wurde nicht gefunden."
      );
    case 409:
      return t(
        "tools.player.hls-player.error-409",
        "Der Stream wurde vermutlich gestoppt, da Sie anderweitig einen Stream begonnen haben."
      );
    default:
      return null;
  }
};

const videoEvents = ["abort", "ended", "error", "pause", "waiting", "suspend"];
const deb = (e) => {
  console.debug(
    `VIDEO-HLS: event=${e.type} message="${e.message ?? "n/a"}" full=%o`,
    e
  );
};

const handleAlert = (...args) => alert(...args);

const HlsPlayer = ({
  src,
  autoplay,
  roomId,
  streamId,
  debugRoot,
  token,
  streamToken: givenStreamToken,
  onError,
  reloadDelay,
  reloadTimes,
  setupOptions,
  levelControls,
  children,
  onAlert,
  debug,
  ...props
}) => {
  const ref = useRef();
  const { t } = useTranslation();
  const [retry, setRetry] = useState(1);
  const [reloadRemaing, setReloadRemaining] = useState(reloadTimes);
  const [hls, setHls] = useState(null);
  const { reportInternal, reportToken } = useStreamErrorReport();
  const hlsDebug = (...args) => {
    if (debug || setupOptions?.debug) {
      console.debug("HLS-Event:", ...args);
    }
  };
  useEffect(() => {
    let _hls, error;

    const handleHlsError = (type, data) => {
      console.debug(
        `Hls: Having errors > type=${data.type} details=${data.details} fatal=${data.fatal} full=%o`,
        data
      );
      console.debug(
        "Hls: Got media error",
        "fatal=",
        data.fatal,
        "endend",
        ref?.current?.ended,
        "paused=",
        ref?.current?.paused,
        "readyState=",
        ref?.current?.readyState
      );
      switch (data.type) {
        case Hls.ErrorTypes.NETWORK_ERROR:
          if (
            data.response?.code === 403 ||
            data.response?.code === 400 ||
            data.response?.code === 401 ||
            data.response?.code === 409
          ) {
            console.debug(
              "Hls: Destroying stream through code",
              data.response?.code
            );
            onError?.(data, _getErrorMessage(data?.response?.code, t));
            _hls?.startLoad();
            ref?.current?.play?.();
            // setHls(null);
            if (data.response?.code === 403) {
              if (token) {
                reportToken(streamId, token, {
                  streamToken: givenStreamToken,
                  reason: data.response,
                });
              } else {
                reportInternal(streamId, { reason: data.response });
              }
            }
            break;
          }
          if (
            data?.response?.code === 404 ||
            data.details === "manifestParsingError"
          ) {
            console.debug(
              "Hls: 404 detected, maybe the stream is not ready yet..."
            );
            _hls?.destroy();

            // setHls(null);
            if (reloadRemaing > 0) {
              console.debug(`Hls: Respawning in ${reloadDelay} ms`);
              window.setTimeout(
                setRetry.bind(null, setRetry(retry + 1)),
                reloadDelay
              );
              setReloadRemaining(reloadRemaing - 1);
            } else {
              console.debug(
                `Hls: No reloads remaining (${reloadRemaing}), thus not respawning`
              );
              onError?.(data, _getErrorMessage(404, t));
            }
          }
          break;
        case Hls.ErrorTypes.MEDIA_ERROR:
          _hls?.startLoad?.();
          if (data.fatal) {
            _hls?.recoverMediaError();
            // ref?.current?.play?.();
          }
          // ref?.current?.play?.();
          break;
        default:
          break;
      }
    };
    const bootstrap = (source = "none") => {
      if (Hls.isSupported()) {
        hlsDebug("booting HLS", source);
        const playbackOptions = {
          ...HLS_PLAYBACK_OPTIONS,
          ...HLS_PLAYBACK_OPTIONS_EXT,
          ...(setupOptions ?? {}),
        };
        window.playback = playbackOptions;
        let setup = {
          xhrSetup: (xhr, url) => {
            // xhr.setRequestHeader("Connection", "keep-alive");
            const auth = getAuthToken();
            const streamToken = givenStreamToken ?? getStreamToken();
            // console.error("Given Stream Token", streamToken);
            auth && xhr.setRequestHeader("Authorization", `Bearer ${auth}`);
            token && xhr.setRequestHeader("X-Token", token);
            roomId && xhr.setRequestHeader("X-Room-ID", roomId);
            streamId && xhr.setRequestHeader("X-Stream-ID", streamId);
            debugRoot && xhr.setRequestHeader("X-Debug-Root", debugRoot);
            streamToken && xhr.setRequestHeader("X-Stream-Token", streamToken);
          },
          ...playbackOptions,
        };
        console.debug("using setup:", setup);
        _hls?.destroy();
        _hls = new Hls(setup);
        setHls(_hls);
        window.HLS = _hls;

        _hls.on(Hls.Events.MEDIA_ATTACHED, () => {
          _hls.loadSource(src);
        });
        _hls.on(Hls.Events.MANIFEST_PARSED, () => {
          console.debug("HLS", "MANIFEST", "has been parsed", ref?.current);
          autoplay && ref?.current?.play();
        });
        _hls.attachMedia(ref.current);
        _hls.on(Hls.Events.DESTROYING, hlsDebug);
        _hls.on(Hls.Events.LEVEL_SWITCHING, hlsDebug);
        //Hls.Events.LEVEL_SWITCHED
        _hls.on(Hls.Events.LEVEL_SWITCHED, hlsDebug);
        _hls.on(Hls.Events.FRAG_LOADED, hlsDebug);
        _hls.on(Hls.Events.LEVEL_LOADED, hlsDebug);
        _hls.on(Hls.Events.FRAG_BUFFERED, hlsDebug);
        _hls.on(Hls.Events.ERROR, handleHlsError);
      } else {
        try {
          const params = [];
          const auth = getAuthToken();
          const streamToken = givenStreamToken ?? getStreamToken();
          streamId && params.push(`stream_id=${streamId}`);
          auth && params.push(`jwt=${auth}`);
          streamToken && params.push(`streamToken=${streamToken}`);

          const url = `${src}?${params.join("&")}`;
          ref.current.src = url;
          ref.current.controls = true;
          ref.current.type = "application/x-mpegURL";
          ref.current.autoplay = false;
          ref.current.playsinline = true;
          ref.current.crossorigin = "anonymous";
          ref.current.onerror = (e, b, c) => {
            console.error(
              `Got error ${e} - ${ref.current.error?.code} / ${ref.current.src} / ${url}`
            );
          };
          ref.current.src = src;
        } catch (e) {
          onAlert(`Error: ${e.message}`);
        }
      }
    };
    const videoError = (e) => {
      error = e;
      console.debug("videoError -> saved", e);
    };
    const videoPause = (e) => {
      if (error) {
        error = null;
        ref.current.play();
        console.debug("videoPause -> error detected and stream restarted");
      }
    };
    if (ref?.current) {
      bootstrap("bootup");
      for (const ev of videoEvents) {
        ref.current.addEventListener(ev, deb, { passive: true });
      }
      ref.current.addEventListener("error", videoError);
      ref.current.addEventListener("pause", videoPause);
    }
    return () => {
      setHls(null);
      hlsDebug("clearing HLS", ref?.current);
      delete window.HLS;
      if (_hls) {
        _hls.off(Hls.Events.ERROR, handleHlsError);
        _hls.off(Hls.Events.DESTROYING, hlsDebug);
        _hls.off(Hls.Events.LEVEL_SWITCHING, hlsDebug);
        //Hls.Events.LEVEL_SWITCHED
        _hls.off(Hls.Events.LEVEL_SWITCHED, hlsDebug);
        _hls.off(Hls.Events.FRAG_LOADED, hlsDebug);
        _hls.off(Hls.Events.LEVEL_LOADED, hlsDebug);
        _hls.off(Hls.Events.FRAG_BUFFERED, hlsDebug);
        _hls.stopLoad();
        _hls.detachMedia();
        _hls.destroy();
      }
      if (ref?.current) {
        ref.current.stop?.();

        for (const ev of videoEvents) {
          ref.current.removeEventListener(ev, deb);
        }
        ref.current.removeEventListener("pause", videoPause);
        ref.current.removeEventListener("error", videoError);
      }
    };
  }, [retry, ref?.current]);

  return (
    <Container className={"hls-container"}>
      <video ref={ref} {...props} />
      {levelControls && <StreamSelector player={ref} hls={hls} />}
      {children}
    </Container>
  );
};
HlsPlayer.isSupported = Hls.isSupported;
HlsPlayer.defaultProps = {
  reloadDelay: 2000,
  reloadTimes: 10,
  onAlert: handleAlert,
};

const Container = styled.div`
  position: relative;
  video {
    background: black;
    width: 100%;
    height: 100%;
  }
`;

export default HlsPlayer;

export const useHls = () => {
  const [supported] = useState(Hls.isSupported());
  return supported;
};
