import { uniqBy } from "lodash";
import { useState } from "react";
import { useError } from "./errorActions";
import { POST } from "../lib";
import { useDispatch } from "react-redux";

export const VIEW_ACTIONS = {
  RESET: "VIEW_RESET",
  ONLINE: "VIEW_SET_ONLINE",
  ONLINE_ALL: "VIEW_SET_ONLINE_ALL",
  OFFLINE: "VIEW_SET_OFFLINE",
  SET_MESSAGES: "VIEW_SET_MESSAGES",
  SET_MESSAGE_ORDER: "VIEW_SET_MESSAGE_ORDER",
  SET_ACTIVE: "VIEW_ACTIVE",
};

export const viewState__resetView = () => ({ type: VIEW_ACTIONS.RESET });
export const viewState__online = (user) => ({
  type: VIEW_ACTIONS.ONLINE,
  userId: user?.user_id ?? user,
});
export const viewState__offine = (user) => ({
  type: VIEW_ACTIONS.OFFLINE,
  userId: user?.user_id ?? user,
});
export const viewState__onlineAll = (users) => ({
  type: VIEW_ACTIONS.ONLINE_ALL,
  users,
});
export const viewState__setMessages = (messages) => ({
  type: VIEW_ACTIONS.SET_MESSAGES,
  messages,
});
export const viewState__addMessages = (...message) =>
  viewState__setMessages((list) => uniqBy([...message, ...list], "message_id"));

export const viewState__removeMessages = (...message) =>
  viewState__setMessages((list) => {
    const rm = new Set(message.map((m) => m?.message_id ?? m));
    return list.filter((m) => !rm.has(m?.message_id ?? 0));
  });

export const viewState__setOrder = (order = "asc") => ({
  type: VIEW_ACTIONS.SET_MESSAGE_ORDER,
  order,
});

export const viewState__active = (active) => ({
  type: VIEW_ACTIONS.SET_ACTIVE,
  active,
});

export const useMessenger = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const send = async (eventId, message, token = null, throws = null) => {
    try {
      setLoading(true);
      const response = await POST(`/event/message/${eventId}`, {
        message,
        token,
      });
      dispatch(viewState__addMessages(response));
      return response;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, clearError, send };
};
