import ScrollImage from "../../images/scroll-down-primary.png";
import React from "react";
import styled from "styled-components/macro";

const ScrollDown = ({ title }) => {
  return (
    <Wrapper>
      {title && <Content>{title}</Content>}
      <ScrollDownImage src={ScrollImage} alt={"scroll down"} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 150px 0 200px;
  text-align: center;
  @media all and (max-width: 960px) {
    margin: 75px 0 100px;
  }
`;
const Content = styled.h4`
  text-shadow: 0 0 0.3rem black;
`;
const ScrollDownImage = styled.img`
  max-height: 80px;
  object-fit: cover;
  position: relative;
  margin-top: -10px;
`;

export default ScrollDown;
