import { get, isPlainObject } from "lodash";
import i18n from "../lib/i18n";

let authToken = null;
export const getAuthToken = () =>
  (authToken ?? localStorage.getItem("auth_token")) || null;
export const setAuthToken = (token = null) => {
  if (null === token) {
    authToken = null;
    localStorage.removeItem("auth_token");
    localStorage.removeItem("user");
  } else {
    authToken = token;
    localStorage.setItem("auth_token", token);
  }
};

let streamToken = null;
export const getStreamToken = () =>
  streamToken ?? (localStorage.getItem("stream_token") || null);
export const setStreamToken = (token = null) => {
  if (null === token) {
    streamToken = null;
    localStorage.removeItem("stream_token");
  } else {
    streamToken = token;
    localStorage.setItem("stream_token", token);
  }
};

let BASE_URL = null;
export const _setBaseUrl = (url) => {
  // eslint-disable-next-line
  BASE_URL = typeof url === "string" ? url.replace(/[\/\s]+$/, "") : url;
};

let Handler401 = null;

export const set401Handler = (callback) => (Handler401 = callback);

let defaultOptions = {
  mode: "cors",
  // credentials: 'include',
  cache: "no-store",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

export const _setDefaultOptions = (options) => {
  defaultOptions =
    typeof options === "function" ? options(defaultOptions) : options;
};

const _getAuth = () => {
  const token = getAuthToken();
  return !token
    ? {}
    : {
      Authorization: `Bearer ${token}`,
    };
};

const json = async (url, method = "GET", options = {}, body = undefined) => {
  options = {
    ...(defaultOptions || {}),
    method,
    headers: {
      ...get(defaultOptions, "headers", {}),
      ..._getAuth(),
      ...get(options, "headers", {}),
    },
  };
  url = BASE_URL ? BASE_URL + url : url;
  if (body !== undefined) {
    if (isPlainObject(body) && (method === "GET" || method === "DELETE")) {
      console.debug("what, got some crazy things here", body);
      url = new URL(url);
      url.search = new URLSearchParams(body).toString();
    } else {
      console.debug("ajaxing", url, body);
      if (body instanceof FormData) {
        options.body = body;
        delete options.headers["Content-Type"];
        console.debug("optinos are now", options);
      } else {
        options.body = !(typeof body === "string")
          ? JSON.stringify(body)
          : body;
      }
    }
  } else {
    console.debug("ajaxing without body", url, body);
  }
  let response;
  try {
    response = await fetch(url, options);
  } catch (e) {
    console.error(e)
    throw {
      code: 409,
      error: "api.errors.connection",
      message: "api.errors.connection",
    };
  }
  const json = response.status === 204 ? {} : await response.json();
  if (!response.ok || json.error) {
    if (response.status === 401 && typeof Handler401 === "function") {
      Handler401(json, response);
    }
    throw json;
  }
  return json;
};

export const _get = (url, body = undefined, options = {}) =>
  json(url, "GET", options, body);
export const _post = (url, body = undefined, options = {}) =>
  json(url, "POST", options, body);
export const _put = (url, body = undefined, options = {}) =>
  json(url, "PUT", options, body);
export const _delete = (url, body = undefined, options = {}) =>
  json(url, "DELETE", options, body);
export const _upload = (url, data) => json(url, "POST", {}, data);
export const _login = async (
  email,
  password,
  club_id = null,
  club_slug = null,
  invite_token = null,
  options = {}
) => {
  const url = club_id ? "/club/auth" : "/user/auth";
  const params = { email, password, club_slug, invite_token };
  if (club_id) {
    params.club_id = club_id;
  }
  return new Promise((resolve, reject) => {
    _post(url, params, options)
      .then((response) => {
        if (response && response.token) {
          setAuthToken(response.token);
          delete response.token;
          resolve(response);
        } else {
          reject(new Error("Failed to save jwt token"));
        }
      })
      .catch(reject);
  });
};

export const sleep = (time = 0) =>
  new Promise((resolve) => {
    window.setTimeout(resolve, time);
  });
