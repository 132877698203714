import React from "react";
import styled from "styled-components/macro";
import cn from "clsx";

const MediaList = ({ className, children, ...props }) => {
  return (
    <>
      <Wrapper className={cn(className, "media-list")} {...props}>
        {children}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  align-items: flex-start;
  gap: 2em;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  @media all and (max-width: 600px) {
    gap: 1em;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

export default MediaList;
