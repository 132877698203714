import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { store } from "./lib/store";
import App from "./components/App";
import { setFetchBaseURL } from "./lib";
import "material-icons/iconfont/material-icons.css";
import "./lib/style/styles.css";
import "video.js/dist/video-js.min.css";
import "react-contexify/dist/ReactContexify.css";
import "./lib/overrides";
import { API_URL } from "./constants";
import { setDefault } from "./lib/date";
import "./lib/i18n";
import Fallback from "./components/frame/Fallback";

if (process.env.NODE_ENV !== "development") {
  // disabling debug in live!
  console.debug = (...args) => {};
  console.info = (...args) => {};
  console.warn = (...args) => {};
  console.log = (...args) => {};
}

setDefault("de");

setFetchBaseURL(API_URL);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<Fallback />}>
        <App />
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
