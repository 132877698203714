import React, { useState } from "react";
import styled from "styled-components/macro";
import { Plate } from "../../../lib/style/Templates";
import { Button } from "@material-ui/core";
import ProfileChangePasswordDialog from "./ProfileChangePasswordDialog";
import { useTranslation } from "react-i18next";

const ProfileActions = ({ onChangePassword }) => {
  const { t } = useTranslation();
  const [changePassword, _setChangePassword] = useState(false);
  const setChangePassword = (state) => (e) => {
    e?.stopPropagation?.();
    _setChangePassword(state);
  };
  return (
    <>
      <Wrapper>
        {/*<Option onClick={handle}>E-Mail ändern</Option>*/}
        <Option onClick={setChangePassword(true)}>
          {t(
            "components.pages.password.profile.ProfileActions.change-password-label",
            "Passwort ändern"
          )}
        </Option>
      </Wrapper>

      <ProfileChangePasswordDialog
        open={changePassword}
        onClose={setChangePassword(false)}
      />
    </>
  );
};

const Wrapper = styled.div`
  ${Plate};
  padding: 2em;
`;

const Option = styled(Button).attrs({ fullWidth: true })`
  padding: 0.8rem 1rem !important;
`;

export default ProfileActions;
