import React, { useMemo, useState } from "react";
import { Button as MuiButton, TextField } from "@material-ui/core";
import styled from "styled-components/macro";
import { useHistory, useParams } from "react-router";
import { Image_Adobe_4 as Signup } from "../images";
import Background from "../components/frame/Background";
import { v4 } from "uuid";
import { useUserRegistration } from "../actions/userActions";
import { useSnackbar } from "notistack";
import { Glass } from "../lib/style/Templates";
import { useTranslation } from "react-i18next";
import AppLogo from "../elements/svg/AppLogo";
import { Link } from "react-router-dom";
import PoweredBy from "../components/frame/PoweredBy";

export const RegisterLink = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  return (
    <>
      <Button variant={"contained"} onClick={(e) => push("/register")}>
        {t("pages.RegisterPage.register-label", "Registrieren")}
      </Button>
    </>
  );
};

const Button = styled(MuiButton)`
  ${Glass};
`;

const RegisterBox = ({ defaultEmail }) => {
  const { invite } = useParams();
  const [club_slug, invite_token] = useMemo(() => {
    const parts = (invite ?? "").split(";");
    if (parts.length === 2) {
      return parts;
    }
    return [null, null];
  }, [invite]);
  const [loading, , { register }] = useUserRegistration();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState(defaultEmail || "");
  const [password, setPassword] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();
  const formId = v4();
  const firstNameId = v4();
  const lastNameId = v4();
  const emailId = v4();
  const passwordId = v4();
  const { t } = useTranslation();
  return (
    <RegisterBoxWrapper
      id={formId}
      onSubmit={async (e) => {
        e.preventDefault();
        if (loading) return;
        const { notice } = await register(
          {
            first_name: firstName,
            last_name: lastName,
            email,
            password,
            club_slug,
            invite_token,
          },
          true
        );
        enqueueSnackbar(
          t("pages.RegisterPage.register-success", "Registrierung erfolgreich"),
          { variant: "success" }
        );
        if (notice) {
          const tr = t;
          enqueueSnackbar(
            tr(
              notice,
              "Anmeldungslink konnte nicht angewendet werden. Bitte rufen Sie den Einladungslink erneut auf."
            ),
            { variant: "warning", autoHideDuration: 30000 }
          );
        }
        push("/");
      }}
    >
      <Entry>
        <TextField
          label={t("labels.first-name", "Vorname").capital()}
          variant={"outlined"}
          autoComplete={"new-first-name"}
          id={firstNameId}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Entry>
      <Entry>
        <TextField
          label={t("labels.last-name", "Nachname").capital()}
          variant={"outlined"}
          autoComplete={"new-last-name"}
          id={lastNameId}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Entry>
      <Entry>
        <TextField
          required
          type={"email"}
          label={t("labels.email", "E-Mail").capital()}
          variant={"outlined"}
          autoComplete={"new-email"}
          id={emailId}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Entry>
      <Entry>
        <TextField
          required
          type={"password"}
          label={t("labels.password", "Passwort").capital()}
          variant={"outlined"}
          autoComplete={"new-password"}
          id={passwordId}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Entry>
      <Entry>
        <MuiButton fullWidth type={"submit"}>
          {t("pages.RegisterPage.register-label", "Registrieren")}
        </MuiButton>
      </Entry>
    </RegisterBoxWrapper>
  );
};

const RegisterBoxWrapper = styled.form`
  display: grid;
  gap: 12px;
  border-radius: 20px;
  box-shadow: inset 0 2.5px 1.5px 0.8px white, 0 10px 20px rgba(0, 0, 0, 0.8);
  padding: 40px 30px 20px;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
`;
const Entry = styled.div``;

const RegisterPage = () => {
  const { email } = useParams();
  return (
    <>
      <Background background={Signup} />
      <Wrapper>
        <InnerWrapper>
          <Link to={"/"}>
            <AppLogo dual maxWidth={"250px"} />
          </Link>
          <RegisterBox defaultEmail={email} />
        </InnerWrapper>
        <PoweredBy />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: grid;
  justify-content: center;
`;
const InnerWrapper = styled.div`
  max-width: 960px;
  width: 100%;
  padding-top: 50%;
  display: grid;
  gap: 2em;
`;

export default RegisterPage;
