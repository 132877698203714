import React from 'react'
import {reduceOptionsBy as reduceBy} from "./methods";

const Flex = ({as: Component, style, fullWidth,
	                     inline, align: justifyContent, valign: alignItems, spaceBetween, spaceAround, spaceEvenly, left, right, center, top, bottom, middle, stretch, baseline,
	                     direction: flexDirection, row, rowReverse, column, columnReverse, shrink: flexShrink, basis: flexBasis, grow: flexGrow,
						wrap: flexWrap, nowrap, wrapReverse, order, ...props
}) => {
	flexDirection = flexDirection || reduceBy([row, rowReverse, column, columnReverse], ['row', 'row-reverse', 'column', 'column-reverse'])
	const isCol = flexDirection === 'column' || flexDirection === 'column-reverse'
	justifyContent = justifyContent || reduceBy([isCol ? top : left, isCol  ? middle: center, isCol ? bottom: right, spaceEvenly, spaceAround, spaceBetween], ['flex-start', 'center', 'flex-end', 'space-evenly', 'space-around', 'space-between'])
	alignItems = alignItems || reduceBy([isCol ? left: top, isCol ? center :  middle, isCol ? right : bottom, stretch, baseline], ['flex-start', 'center', 'flex-end', 'stretch', 'baseline'])
	flexWrap = flexWrap || reduceBy([nowrap, wrapReverse], ['nowrap', 'wrap-reverse'])
	flexWrap = typeof flexWrap === 'boolean' ? 'wrap' : flexWrap
	fullWidth = fullWidth ? {width: '100%'} : {}
	return <Component style={{alignItems, justifyContent, display: inline ? 'inline-flex' : 'flex', flexDirection, flexShrink, flexBasis, flexGrow, flexWrap, order, ...fullWidth, ...style}} {...props}/>
}

Flex.defaultProps = {
	as: 'div'
}

export default Flex