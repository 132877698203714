import React from "react";
import styled from "styled-components/macro";
import { PlayArrow } from "@material-ui/icons";
import * as PropTypes from "prop-types";

const EventStateStart = ({ message, children, onClick, ...props }) => {
  return (
    <StartCourse {...props}>
      <StartInfo>{message}</StartInfo>
      <StartButton onClick={onClick}>
        <PlayIcon />
      </StartButton>
      {children}
    </StartCourse>
  );
};
EventStateStart.defaultProps = {
  message: "components.pages.event.EventStateStart.default",
};
EventStateStart.propTypes = {
  message: PropTypes.string,
};

export default EventStateStart;

const StartCourse = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  gap: 20px;
`;
const StartInfo = styled.div`
  color: rgba(255, 255, 255, 0.8);
`;
const StartButton = styled.button`
  position: relative;
  display: grid;
  justify-content: center;
  align-content: center;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background: #c5d60c;
  outline: none;
  transition: all 0.1s ease-in-out;
  border: none;
  box-shadow: inset 0 0 1.5px white, 0 20px 40px black;
  &:active {
    transform: scale(0.95);
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5),
      inset 0 3px 8.5px 2px rgba(0, 0, 0, 0.3), 0 10px 20px black;
  }
`;
const PlayIcon = styled(PlayArrow)`
  width: 50px !important;
  height: 50px !important;
  ${StartButton} & {
    transition: all 0.6s ease-in-out;
  }
  ${StartButton}:hover & {
    transition: all 0.6s ease-in-out;
    transform: scale(1.4);
  }
`;
