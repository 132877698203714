import { uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DELETE, GET, POST, PUT } from "../lib";
import { useError } from "./errorActions";
import { eventState__remove } from "./eventActions";

export const COURSE_ACTIONS = {
  SET_LIST: "COURSES_SET_LIST",
  SET_GROUPS: "COURSES_SET_GROUPS",
};

export const courseState__set = (courses = []) => ({
  type: COURSE_ACTIONS.SET_LIST,
  courses,
});
export const courseState__add = (...course) =>
  courseState__set((list) => {
    return uniqBy([...course, ...list], "course_id");
  });
export const courseState__remove = (...course) =>
  courseState__set((list) => {
    const remove = new Set(course.map((c) => c.course_id || c));
    return list.filter((c) => !remove.has(c.course_id));
  });

export const courseState__setGroups = (courseId, groups = []) => ({
  type: COURSE_ACTIONS.SET_GROUPS,
  groups,
  courseId,
});

export const useAdminCourses = (init = false) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const courses = useSelector((state) => state.courses.courses);
  const dispatch = useDispatch();

  const load = async (throws = null) => {
    try {
      setLoading(true);
      const list = await GET("/course/admin/all");
      dispatch(courseState__set(list));
      return list;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init && load();
    // eslint-disable-next-line
  }, [init]);

  return [courses, loading, error, { load, clearError }];
};

export const useCourseCreation = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const courses = useSelector((state) => state.courses.courses);
  const dispatch = useDispatch();

  const create = async (params, throws = null) => {
    try {
      setLoading(true);
      const course = await PUT("/course/admin", params);
      dispatch(courseState__add(course));
      return course;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return [courses, loading, error, { create, clearError }];
};

export const useCourseRemoval = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const remove = async (courseId, throws = null) => {
    try {
      setLoading(true);
      const response = await DELETE(`/course/admin/${courseId}`);
      if (!("reason" in response)) {
        response?.course && dispatch(courseState__remove(response.course));
        response?.events?.length &&
          dispatch(eventState__remove(courseId, ...response.events));
      }
      return response;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, clearError, remove };
};

export const useCourseUpdate = (courseId) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const course = useSelector(
    (state) =>
      state.courses.courses.find((c) => c.course_id == courseId) || null
  );
  const dispatch = useDispatch();
  const update = async (data, courseID = null, throws = null) => {
    courseID = courseID || courseId;
    try {
      if (loading) return;
      setLoading(true);
      const updatedCourse = await POST(`/course/admin/${courseID}`, data || {});
      dispatch(courseState__add(updatedCourse));
      return updatedCourse;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return [course, loading, error, { update, clearError }];
};

export const useAdminCourse = (courseId = null, init = false) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  // eslint-disable-next-line
  const course = useSelector((state) =>
    courseId ? state.courses.courses.find((c) => c.course_id == courseId) : null
  );
  const dispatch = useDispatch();

  const load = async (course_id = courseId, throws = null) => {
    try {
      setLoading(true);
      let url = `/course/admin/id/${course_id}`;
      const course = await GET(url);
      dispatch(courseState__add(course));
      return course;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    init && courseId && load();
    // eslint-disable-next-line
  }, [courseId, init]);
  return [course, loading, error, { load, clearError }];
};

export const useAdminCourseGroups = (courseId) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const groups = useSelector((s) => s.courses.groups[courseId] || []);
  const dispatch = useDispatch();

  const load = async (courseID = null, throws = null) => {
    courseID = courseID || courseId;
    try {
      if (loading) return;
      setLoading(true);
      const { course, groups: g } = await GET(
        `/course/admin/id/${courseID}/groups`
      );
      dispatch(courseState__add(course));
      dispatch(courseState__setGroups(courseID, g));
      return [course, g];
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  const assign = async (groupId, courseID = null, throws = null) => {
    courseID = courseID || courseId;
    try {
      if (loading) return;
      setLoading(true);
      const { course, groups: g } = await PUT(
        `/course/admin/id/${courseID}/group/${groupId}`
      );
      dispatch(courseState__add(course));
      dispatch(courseState__setGroups(courseID, g));
      return [course, g];
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  const revoke = async (groupId, courseID = null, throws = null) => {
    courseID = courseID || courseId;
    try {
      if (loading) return;
      setLoading(true);
      const { course, groups: g } = await DELETE(
        `/course/admin/id/${courseID}/group/${groupId}`
      );
      dispatch(courseState__add(course));
      dispatch(courseState__setGroups(courseID, g));
      return [course, g];
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return [groups, loading, error, { load, assign, revoke, clearError }];
};

export const useAvailableCourseAttributes = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [attributes, setAttributes] = useState([]);
  const [times, setTimes] = useState(0);

  const load = async (id, throws = null) => {
    try {
      setLoading(true);
      const { attributes } = await GET(`/course/${id}/attributes/available`);
      setAttributes(attributes);
      setTimes(times + 1);
      return { attributes };
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { attributes, loading, error, clearError, load, times };
};

export const useCourseAttributeList = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [attributes, setAttributes] = useState([]);
  const [times, setTimes] = useState(0);

  const load = async (courseId, throws = null) => {
    try {
      setLoading(true);
      const { attributes } = await GET(`/course/${courseId}/attributes/list`);
      setAttributes(attributes);
      setTimes(times + 1);
      return { attributes };
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { attributes, times, loading, error, clearError, load };
};

export const useCourseAttributeCreation = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const create = async (courseId, attributeId, value, throws = null) => {
    try {
      setLoading(true);
      const { course, attribute, course_attribute } = await PUT(
        `/course/${courseId}/attributes`,
        {
          attribute_id: attributeId,
          value,
        }
      );
      dispatch(courseState__add(course));
      return { course, attribute, course_attribute };
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, clearError, create };
};

export const useCourseAttributeEdit = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const edit = async (course_attribute_id, value, throws = null) => {
    try {
      setLoading(true);
      const { course, attribute, course_attribute } = await POST(
        `/course/attribute/${course_attribute_id}`,
        {
          value,
        }
      );
      dispatch(courseState__add(course));
      return { course, attribute, course_attribute };
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, clearError, edit };
};

export const useCourseAttributeRemoval = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const remove = async (course_attribute_id, throws = null) => {
    try {
      setLoading(true);
      const { course, attribute, course_attribute } = await DELETE(
        `/course/attribute/${course_attribute_id}`
      );
      dispatch(courseState__add(course));
      return { course, attribute, course_attribute };
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, clearError, remove };
};
