import React, { useEffect, useState } from "react";
import { useEventAccessUpdate } from "../../../actions/eventActions";
import EventPresentationAccessDenied from "./EventPresentationAccessDenied";

const EventPresentationAccessWrapper = ({
  event,
  token,
  children,
  onClose,
  errorContainer,
}) => {
  const { loading, updateAccess } = useEventAccessUpdate();
  const [hasAccess, setAccess] = useState(null);
  const checkForAccess = async () => {
    const access = await updateAccess(
      event?.event_id ?? 0,
      token ?? null,
      true
    );
    setAccess(access);
  };

  useEffect(() => {
    if (event) {
      // noinspection JSIgnoredPromiseFromCall
      checkForAccess();
    }
  }, [event?.event_id ?? 0]);

  if (null === hasAccess) {
    return null;
  }
  if (false === hasAccess) {
    // access has been denied
    return (
      errorContainer || (
        <EventPresentationAccessDenied
          onClose={onClose}
          event={event}
          loading={loading}
          onUpdate={checkForAccess}
        />
      )
    );
  }
  return <>{children}</>;
};

export default EventPresentationAccessWrapper;
