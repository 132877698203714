import React from "react";
import styled from "styled-components/macro";
import { LinearProgress } from "@material-ui/core";
import * as PropTypes from "prop-types";
import cn from "clsx";

const ProgressBar = styled(
  ({ loading, className, defaultValue = 0.8, ...props }) => (
    <LinearProgress className={cn(className, { loading })} {...props} />
  )
)`
  opacity: ${(props) => (props.loading ? props.defaultValue : 0)};
`;

ProgressBar.propTypes = {
  loading: PropTypes.bool,
  defaultValue: PropTypes.number,
};

export default ProgressBar;
