import React, { useEffect, useState } from "react";
import Hls from "hls.js";
import cn from "clsx";
import StreamLevelResolution from "./StreamLevelResolution";
import styled from "styled-components/macro";
import { COLOR } from "../../constants";
import Color from "color";

const StreamSelector = ({ hls, player, debug, ...props }) => {
  const [open, setOpen] = useState(false);
  const [levels, setLevels] = useState([]);
  const [currentLevel, setCurrentLevel] = useState(hls?.loadLevel ?? 0);
  const [chosenLevel, setChosenLevel] = useState(hls?.loadLevel ?? -1);
  useEffect(() => {
    const closer = () => {
      setOpen(false);
    };
    document.body.addEventListener("click", closer, false);
    return () => {
      document.body.removeEventListener("click", closer);
    };
  }, []);
  useEffect(() => {
    if (hls) {
      console.debug("hls in streamselector", hls.levels);
      setLevels(hls.levels);
      hls.on(Hls.Events.MANIFEST_LOADED, function (_, data) {
        console.debug("level data", data.levels, hls.levels);
        setLevels(hls.levels);
      });
      hls.on(Hls.Events.LEVEL_SWITCHED, function (_, data) {
        console.debug("Level changed", data, chosenLevel, currentLevel);
        setCurrentLevel(data.level);
        chosenLevel !== -1 && setChosenLevel(data.level);
      });
    }

    return () => {
      hls?.off(Hls.Events.MANIFEST_LOADED);
    };
  }, [hls]);
  const handleLevel = (index) => () => {
    if (hls) {
      console.debug("loading level", index);
      hls.loadLevel = index;
      hls.nextLevel = index;
      setChosenLevel(index);
    }
    setOpen(false);
  };
  const handleToggle = (e) => {
    setOpen((isOpen) => !isOpen);
  };
  if (levels.length < 2) {
    return null;
  }
  return (
    <>
      <StreamWrapper
        {...props}
        className={cn({ open }, "hls-stream-selector")}
        onClick={(e) => e.stopPropagation()}
      >
        <CurrentStream
          className={"hls-stream-selector--current-container"}
          onClick={handleToggle}
        >
          <CurrentStreamLevel
            className={cn(
              { active: chosenLevel === -1 },
              "hls-current-selection"
            )}
          >
            Auto <StreamLevelResolution p level={levels[currentLevel]} />
          </CurrentStreamLevel>
          <CurrentStreamLevel className={cn({ active: chosenLevel !== -1 })}>
            <StreamLevelResolution
              level={levels[chosenLevel] ?? levels[currentLevel]}
              p
            />
          </CurrentStreamLevel>

          <StreamIcon>⚙</StreamIcon>
        </CurrentStream>
        <StreamList className={"hls-stream-selector--list"}>
          {debug && (
            <pre style={{ padding: ".5em" }}>
              Chosen={chosenLevel}
              <br />
              Current={currentLevel}
            </pre>
          )}
          <StreamLevel
            className={cn("hls-stream-selector--list-item selected", {
              active: chosenLevel === -1,
              current: chosenLevel === -1,
            })}
            onClick={handleLevel(-1)}
          >
            Auto{" "}
            {chosenLevel === -1 ? (
              <StreamLevelResolution level={levels[currentLevel]} closures p />
            ) : (
              ""
            )}
          </StreamLevel>
          {levels.map((data, level) => {
            return (
              <StreamLevel
                key={level}
                onClick={handleLevel(level)}
                className={cn("hls-stream-selector--list-item", {
                  active: level === chosenLevel,
                  current: level === currentLevel && chosenLevel !== -1,
                })}
              >
                <StreamLevelResolution level={data} p />
              </StreamLevel>
            );
          })}
        </StreamList>
      </StreamWrapper>
    </>
  );
};
const StreamWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
`;
const CurrentStream = styled.div`
  display: grid;
  align-items: center;
  align-content: center;
  grid-template-columns: max-content min-content;
  gap: 0.4em;
  padding: 0.2em 0.4em;
  color: white;
  cursor: pointer;

  ${StreamWrapper}.open {
  }
`;
const StreamIcon = styled.div`
  font-size: 1.3em;
`;
const StreamList = styled.div`
  background: rgba(255, 255, 255, 0.85);
  border-radius: 0.5em;
  display: none;
  position: absolute;
  right: 0.5em;
  overflow: hidden;

  ${StreamWrapper}.open & {
    display: block;
  }
`;
const StreamLevel = styled.div`
  min-width: 120px;
  padding: 0.35em 0.75em;
  text-align: center;

  &.active {
    background: ${Color(COLOR.PRIMARY).darken(0.25).hex()};
    color: white;
  }

  &.current {
    font-weight: bold;
  }

  &:hover {
    background: ${COLOR.PRIMARY};
    color: white;
    cursor: pointer;
  }
`;
const CurrentStreamLevel = styled.div`
  display: none;

  &.active {
    display: block;
  }
`;

export default StreamSelector;
