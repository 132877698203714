import { IMAGE_ACTIONS } from "../actions/imageActions";
import { imageSort } from "../lib/Sorting";

const defaultState = {
  items: [],
};

export const imageReducer = (state = defaultState, { type, images }) => {
  switch (type) {
    case "RESET":
      return defaultState;
    case IMAGE_ACTIONS.SET:
      if (typeof images === "function") {
        images = images(state.items);
      }
      return {
        ...state,
        items: images.sort(imageSort.byIdAsc),
      };
    default:
      return state;
  }
};
