import React from "react";
import styled from "styled-components/macro";
import AppLogo from "../../elements/svg/AppLogo";
import cn from "clsx";

const PoweredBy = ({ className, concise, dark, ...props }) => {
  return (
    <>
      <Wrapper
        href={"https://livedo.de/website"}
        target={"livedo-website"}
        className={cn(className, { concise, dark })}
        {...props}
      >
        <Label className={cn({ dark })}>Powered by</Label>
        <AppLogo dual />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.a`
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 5px;
  right: 5%;
  text-decoration: none;
  svg {
    height: 25px;
    width: 90px;
    display: inline-block;
  }
  opacity: 0.5;
  &.dark {
    opacity: 0.7;
  }
  transition: opacity 2200ms;
  &.concise {
    opacity: 1;
  }
  &:hover {
    opacity: 1;
    transition: opacity 400ms;
  }
`;

const Label = styled.span`
  color: white;
  font-size: 12px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  &.dark {
    color: #ddd;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
  }
`;

export default PoweredBy;
