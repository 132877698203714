import React, { useState } from "react";
import { Divider, Drawer, IconButton } from "@material-ui/core";
import styled from "styled-components/macro";
import {
  Close,
  Save,
  Straighten,
  ThumbUp,
  ThumbUpOutlined,
  Timelapse,
  Visibility,
} from "@material-ui/icons";
import { useSelector } from "react-redux";
import { Styled } from "../../../../tools";
import Duration from "../../../../elements/time/Duration";
import Size from "../../../../elements/Size";
import { useMediaLikes } from "../../../../actions/mediaActions";

const MediaMeta = ({ video, children, onClose, ...props }) => {
  const [open, setOpen] = useState(false);
  const { like } = useMediaLikes();
  const liked = useSelector((s) =>
    Boolean(s.media.globalLikes[video?.stream_id])
  );

  const handleClose = (e) => {
    if (onClose) {
      onClose(e);
    } else {
      setOpen(false);
    }
  };
  const handleLike = () => {
    if (!video?.stream_id) return;
    like(video.stream_id);
  };
  return (
    <>
      <Drawer open={open} anchor={"right"} onClose={handleClose} {...props}>
        <Wrapper>
          <TitleWrapper>
            <Title>Optionen</Title>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </TitleWrapper>
          <Divider />
          <Section>
            <Visibility />
            <SectionText>{video?.meta?.views ?? 0} Sichten</SectionText>
          </Section>
          <Section highlight={liked} interactive onClick={handleLike}>
            <LikeIcon active={liked} />
            <SectionText>{video?.meta?.likes ?? 0} Likes</SectionText>
          </Section>
          <Divider />
          <Section>
            <Timelapse />
            <SectionText>
              <Duration value={video?.meta?.duration} />
            </SectionText>
          </Section>
          <Section>
            <Save />
            <SectionText>
              <Size value={video?.meta?.size_dir ?? 0} />
            </SectionText>
          </Section>
        </Wrapper>
        {typeof children === "function"
          ? children({ open, setOpen, video, ...props })
          : children}
      </Drawer>
    </>
  );
};

const LikeIcon = ({ active, ...props }) => {
  return active ? <ThumbUp {...props} /> : <ThumbUpOutlined {...props} />;
};

const Wrapper = styled.div`
  display: grid;
  gap: 2em;
  min-width: 20em;
  max-width: 100%;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.h1`
  padding: 1em;
  font-size: 1.2em;
  font-weight: bold;
`;
const Section = styled(Styled("div", "highlight", "interactive"))`
  svg {
    width: 3em;
    height: 3em;
  }

  display: grid;
  place-items: center;

  &.highlight {
    svg {
      color: var(--color-primary);
    }
  }

  &.interactive {
    cursor: pointer;
  }
`;
const SectionText = styled.div``;
export default MediaMeta;
