import { uniqBy } from "lodash";
import { useState } from "react";
import { useError } from "./errorActions";
import { useDispatch, useSelector } from "react-redux";
import { DELETE, GET, POST, UPLOAD } from "../lib";
import { clubState__add } from "./clubActions";

export const IMAGE_ACTIONS = {
  SET: "SET_IMAGES",
};

export const imageState__set = (images = []) => ({
  type: IMAGE_ACTIONS.SET,
  images,
});
export const imageState__add = (...image) =>
  imageState__set((list) => uniqBy([...image, ...list], "image_id"));
export const imageState__remove = (...image) =>
  imageState__set((list) => {
    const rm = new Set(image.map((img) => img?.image_id ?? img));
    return list.filter((item) => !rm.has(item.image_id));
  });

export const useAdminImages = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const images = useSelector((state) => state.images.items);
  const dispatch = useDispatch();

  const load = async (throws = null) => {
    try {
      setLoading(true);
      const items = await GET("/image/admin/all");
      dispatch(imageState__set(items));
      return items;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return {
    images,
    loading,
    error,
    clearError,
    load,
  };
};

export const useImageUpload = (uri = null, dispatchResponse = true) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const upload = async (image, throws = null) => {
    try {
      setLoading(true);
      const data = new FormData();
      console.debug("using image:", image);
      data.append("image", image);
      const response = await UPLOAD(uri ?? "/image/admin/upload", data);
      dispatchResponse && dispatch(imageState__add(response));
      return response;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, clearError, upload };
};

export const useImageRemoval = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const remove = async (imageId, throws = null) => {
    try {
      setLoading(true);
      const response = await DELETE("/image/admin/id/" + imageId);
      dispatch(imageState__remove(response));
      return response;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, clearError, remove };
};

export const useImageAssign = () => {
  const types = {
    icon: "icon",
    event: "event",
    profile: "profile",
    home: "home",
  };
  const [loading, setLoading] = useState(null);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const assign = async (type, imageId = null, throws = null) => {
    try {
      if (!(type in types)) {
        throw new Error(
          "`useImageAssign::assign(type, imageId, throws)` has given wrong type!"
        );
      }
      setLoading(type);
      const nextClub = await POST(`/image/admin/assign/${type}`, {
        image_id: imageId,
      });
      dispatch(clubState__add(nextClub));
      return nextClub;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(null);
    }
  };
  return { loading, error, clearError, types, assign };
};
