import { useEffect, useState } from "react";
import { DELETE, GET, LOGIN, POST, PUT } from "../lib";
import { useDispatch, useSelector } from "react-redux";
import { setAuthToken } from "../lib/myfetch";
import { get, uniqBy } from "lodash";
import { useLastClub } from "./clubActions";
import { useReset } from "./index";
import { useError } from "./errorActions";
import { useHistory } from "react-router";

export const USER_ACTIONS = {
  SET: "USER_SET",
  SET_LIST: "USER_LIST_SET",
};

export const userState__set = (user = null) => ({
  type: USER_ACTIONS.SET,
  user,
});
export const userState__setList = (users = []) => ({
  type: USER_ACTIONS.SET_LIST,
  users,
});
export const userState__add = (...user) =>
  userState__setList((list) => uniqBy([...user, ...list], "user_id"));
export const userState__remove = (...user) =>
  userState__setList((list) => {
    const s = new Set(user.map((u) => u.user_id || u));
    return list.filter((u) => !s.has(u.user_id));
  });

let loginUrl = null;
const getLoginUrl = () => loginUrl ?? localStorage.getItem("login-url") ?? null;
const setLoginUrl = (url = null) => {
  loginUrl = url;
  if (null === url) {
    localStorage.removeItem("login-url");
  } else {
    localStorage.setItem("login-url", loginUrl);
  }
};
let registerUrl = null;
const getRegisterUrl = () =>
  registerUrl ?? localStorage.getItem("register-url") ?? null;
const setRegisterUrl = (url = null) => {
  registerUrl = url;
  if (url === null) {
    localStorage.removeItem("register-url");
  } else {
    localStorage.setItem("register-url", url);
  }
};

export const useUser = () => {
  const user = useSelector((state) => state.users.user);
  const dispatch = useDispatch();
  const setUser = (user = null) => dispatch(userState__set(user));

  return [user, setUser];
};

export const useLogout = () => {
  const [, setUser] = useUser();

  return () => {
    setUser(null);
    setAuthToken(null);
  };
};

export const useUserCreate = () => {
  const [user, setUser] = useUser();
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);

  const create = async (
    auth,
    club_id,
    email,
    password,
    admin = false,
    onSuccess,
    throws = null
  ) => {
    try {
      setLoading(true);
      console.log("useing auth", auth);
      const response = await PUT("/user/admin", {
        auth,
        club_id,
        email,
        password,
        admin,
      });
      setUser(response);
      onSuccess && onSuccess(user);
      return user;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return [user, loading, error, { clearError, createUser: create }];
};

export const useUserLogout = () => {
  const [user, setUser] = useUser();
  const [lastClub] = useLastClub();
  const { push } = useHistory();
  // const [loading, setLoading] = useState(false)
  // const [error, , clearError] = useError(true)

  const logout = () => {
    return new Promise((resolve) => {
      console.debug("user has been signed out");
      setUser(null);
      setAuthToken(null);
      resolve(null);
    });
  };

  return [
    user,
    false,
    null,
    {
      logoutUser: logout,
      clearError: () => {},
      updatePage: push.bind(
        null,
        lastClub ? `/club/${lastClub.slug}/login` : "/user"
      ),
    },
  ];
};

export const useUserLogin = () => {
  const [user, setUser] = useUser();
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [invite, setInvite] = useState(null);
  const [, setLastClub] = useLastClub();
  const reset = useReset();

  /**
   *
   * @param {string} email
   * @param {string} password
   * @param {number|undefined} club_id
   * @param {boolean|null} throws
   * @return {Promise<{clubs}|*>}
   */
  const login = async (email, password, club_id = undefined, throws = null) => {
    try {
      console.error("loogin nice", "invite=", invite);

      setLoading(true);
      const inviteList = invite ?? [];
      const response = await LOGIN(email, password, club_id, ...inviteList);
      const { user, relation } = response;
      reset();
      if (relation && user && user.clubs) {
        const club = user.clubs.find((c) => c.club_id === relation.club_id);
        if (club) {
          club.admin = get(relation, "is_admin", false);
          setLastClub(club);
        }
      } else {
        setLastClub(null);
      }
      setUser(user);
      return user;
    } catch (e) {
      console.debug("got exception", e);
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  return [user, loading, error, { loginUser: login, clearError, setInvite }];
};

export const usePasswordResetRequest = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);

  const request = async (email, throws = null) => {
    try {
      setLoading(true);
      return await GET(`/user/request/password/${email}`);
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, clearError, request };
};

export const usePasswordResetConfirm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [ok, setOk] = useState(false);

  const confirm = async (token, throws = false) => {
    try {
      setLoading(true);
      const response = await GET(`/user/password/reset/${token}`);
      setOk(response);
      return response;
    } catch (e) {
      if (throws) {
        throw e;
      }
      setError(e);
      return null;
    } finally {
      setLoading(false);
    }
  };
  const clearError = () => setError(null);
  return { ok, loading, error, clearError, confirm };
};

export const useUserPasswordChange = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);

  const change = async (old_password, new_password, throws = null) => {
    try {
      setLoading(true);
      return await POST("/user/password/change", {
        old_password,
        new_password,
      });
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, clearError, change };
};

export const useUserRegistration = () => {
  const [, setUser] = useUser();
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [, setLastClub] = useLastClub();
  const reset = useReset();
  /**
   *
   * @param {object} data
   * @param {string} [data.first_name]
   * @param {string} [data.last_name]
   * @param {string} data.email
   * @param {string} data.password
   * @param throws
   * @return {Promise<{user, token, relation}|*>}
   */
  const register = async (data, throws = null) => {
    try {
      setLoading(true);
      const { user, token, relation, notice } = await POST(
        "/user/register",
        data
      );
      setAuthToken(token);
      reset();
      if (relation && user && user.clubs) {
        const club = user.clubs.find((c) => c.club_id === relation.club_id);
        if (club) {
          club.admin = get(relation, "is_admin", false);
          setLastClub(club);
        }
      } else {
        setLastClub(null);
      }
      setUser(user);
      return { user, token, relation, notice };
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return [loading, error, { register, reset, clearError }];
};

export const useUserConfirmation = () => {
  const [, setUser] = useUser();
  const [loadingCode, setLoadingCode] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [error, handle, clearError] = useError(true);

  const requestCode = async (throws = null) => {
    try {
      setLoadingCode(true);
      return await GET("/user/request_confirmation_number");
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoadingCode(false);
    }
  };

  const confirm = async (code, throws = null) => {
    try {
      setLoadingConfirm(true);
      const { user, relation } = await POST("/user/confirm", { code });
      setUser(user);
      return { user, relation };
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoadingConfirm(false);
    }
  };

  return {
    loadingCode,
    loadingConfirm,
    error,
    clearError,
    requestCode,
    confirm,
  };
};

export const useUserList = (init = false) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const users = useSelector((state) => state.users.users);
  const dispatch = useDispatch();

  const load = async (throws = null) => {
    try {
      setLoading(true);
      const _users = await GET("/user");
      dispatch(userState__setList(_users));
      return _users;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  const loadOne = async (userId, throws = null) => {
    try {
      setLoading(true);
      const _user = await GET("/user/id/" + userId);
      dispatch(userState__add(_user));
      return _user;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init && load();
    // eslint-disable-next-line
  }, [init]);
  return [users, loading, error, { load, loadOne, clearError }];
};

export const useUserAdminAssignment = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const create = async (data, throws = null) => {
    try {
      setLoading(true);
      const { user, relation } = await PUT("/user", data);
      dispatch(userState__add(user));
      return { user, relation };
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return [loading, error, { create, clearError }];
};

export const useUserAdminRemoval = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const remove = async (userId, throws = null) => {
    try {
      setLoading(true);
      const ids = await DELETE("/user/id/" + userId);
      dispatch(userState__remove(...ids));
      return ids;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return [loading, error, { remove, clearError }];
};

export const useUserAdminDetail = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const load = async (userId, throws = null) => {
    try {
      setLoading(true);
      const user = await GET(`/user/id/${userId}`);
      dispatch(userState__add(user));
      return user;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  return [loading, error, { load, clearError }];
};

export const useUserAdminUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  /**
   *
   * @param {object} data
   * @param {number} data.user_id
   * @param {boolean} [data.admin]
   * @param throws
   * @return {Promise<object>}
   */
  const update = async (data, throws = null) => {
    try {
      setLoading(true);
      const user = await POST(`/user`, data);
      dispatch(userState__add(user));
      return user;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  return [loading, error, { update, clearError }];
};
