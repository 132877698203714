import React from "react";
import styled from "styled-components/macro";
import { Info } from "@material-ui/icons";
import * as PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

const EventStateNotHappending = ({ message, children, ...props }) => {
  const { t } = useTranslation();
  const _message = message ?? (
    <Trans
      t={t}
      i18nKey={"components.pages.event.EventStateNotHappening.default"}
      defaults={
        "Der Kurs findet nicht statt.<br/> Vorraussichtlich wurde die benötigte Teilnehmeranzahl nicht erreicht."
      }
      components={{ br: <br /> }}
    />
  );
  return (
    <CourseFinished {...props}>
      <InfoIcon />
      <FinishedText>{_message}</FinishedText>
    </CourseFinished>
  );
};
EventStateNotHappending.propTypes = {
  message: PropTypes.string,
};

export default EventStateNotHappending;

const CourseFinished = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 15px;
  justify-items: center;
`;
const InfoIcon = styled(Info)`
  width: 4em !important;
  height: 4em !important;
  fill: #eca412 !important;
`;
const FinishedText = styled.p`
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.25em;
  line-height: 2em;
`;
