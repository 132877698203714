import React from 'react'
import {Flex} from "../lib";
import {useHistory} from "react-router";
import {useClubs} from "../actions/clubActions";


export const ClubListView = () => {
	const [clubs] = useClubs(true)
	const history = useHistory()
	
	return <div style={{width: '100%', minHeight: '100vh'}}>
		<Flex column>
			{clubs.sort((l, r) => l.name.localeCompare(r.name)).map(club => {
				return <div key={club.club_id} style={{padding: 20, cursor: 'pointer'}} onClick={() => history.push(`/club/${club.tag}`)}>{club.name}</div>
			})}
		</Flex>
	</div>
}
