import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "videojs-contrib-quality-levels";
import { getAuthToken } from "../../lib/myfetch";

const updateSources = (sources) => {
  return sources.map((source) => {
    if (source.src?.endsWith?.(".mpd")) {
      source.type = "application/dash+xml";
    }
    return source;
  });
};

const Player = ({
  roomId,
  streamId,
  debugRoot,
  liveui,
  videoProps,
  sources: givenSources,
  controls,
  autoplay,
  containerProps,
  ...props
}) => {
  const sources = updateSources(givenSources);
  const ref = useRef();
  useEffect(() => {
    console.debug("running register", ref);
    videojs.Vhs.xhr.beforeRequest = (options) => {
      const auth = getAuthToken();
      if (auth) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${auth}`,
        };
      }
      if (roomId) {
        options.headers = {
          ...options.headers,
          "X-Room-ID": roomId,
        };
      }
      if (streamId) {
        options.headers = {
          ...options.headers,
          "X-Stream-ID": streamId,
        };
      }
      if (debugRoot) {
        options.headers = {
          ...options.headers,
          "X-Debug-Root": debugRoot,
        };
      }
      return options;
    };
    const player =
      ref && ref.current
        ? videojs(
            ref.current,
            {
              ...videoProps,
              liveui,
              sources,
              liveReload: true,
              controls,
              autoplay,
              liveTracker: {
                liveTolerance: 0,
                trackingThreshold: 1,
              },
            },
            function () {
              console.error("player is ready");

              console.debug(
                "player is",
                // this?.tech?.()?.vhs
                // player.tech().vhs.representations()
                this.qualityLevels()
              );
            }
          )
        : null;
    player?.on?.("error", () => {
      console.error("VideoJS: Error:", player?.error?.() ?? "Unknown");
    });
    return () => {
      try {
        player?.dispose();
        console.debug("disposing player");
      } catch (e) {
        console.debug("player disposal", e);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div data-vjs-player {...containerProps}>
      <video {...props} ref={ref} className={"video-js"} />
    </div>
  );
};
export default Player;
