import { get, isArray, uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DELETE, GET, POST, PUT } from "../lib";
import { useError } from "./errorActions";
import { courseState__add } from "./courseActions";
import { clubState__add } from "./clubActions";
import { roomState__add } from "./roomActions";
import { formatISO } from "date-fns";
import { set401Handler, setStreamToken } from "../lib/myfetch";
import { SUBSCRIPTIONS, useSubscription } from "./index";

export const EVENT_ACTIONS = {
  SET_LIST: "EVENT_SET_LIST",
  SET_PURE: "EVENT_SET_PURE",
  SET_GROUPS: "EVENT_SET_GROUPS",
  SET_REGISTERED: "EVENT_SET_REGISTERED",
  UPDATE_NOTIFICATION: "EVENT_UPDATE_NOTIFICATION",
  SET_BOOKMAKRS: "EVENT_SET_BOOKMARKS",
};

export const eventState__setList = (course_id, events = []) => ({
  type: EVENT_ACTIONS.SET_LIST,
  events,
  course_id,
});
export const eventState__add = (course_id, ...event) =>
  eventState__setList(course_id, (list) =>
    uniqBy([...event, ...list], "event_id").filter((e) => e.event_id)
  );
export const eventState__remove = (course_id, ...event) =>
  eventState__setList(course_id, (list) => {
    const remove = new Set(event.map((e) => e.event_id || e));
    return list.filter((e) => !remove.has(e.event_id));
  });

export const eventState__setGroups = (eventId, groups = []) => ({
  type: EVENT_ACTIONS.SET_GROUPS,
  groups,
  eventId,
});

export const eventState__setPure = (events) => ({
  type: EVENT_ACTIONS.SET_PURE,
  events,
});

export const eventState__setRegistered = (eventId, registerState) => ({
  type: EVENT_ACTIONS.SET_REGISTERED,
  eventId,
  registerState,
});

/**
 * Notifications are based on the user, thus each event can have only one notification.
 *
 * Delete notification: Integer (eventId) to delete the notification of this event
 *
 * Add one or more notifications: notification object or array of notification objects
 *
 * @param {number|Object|Array} notification
 * @return {{notification, type: string}}
 */
export const eventState__updateNotification = (notification) => ({
  type: EVENT_ACTIONS.UPDATE_NOTIFICATION,
  notification,
});

export const eventState__setBookmarks = (events) => ({
  type: EVENT_ACTIONS.SET_BOOKMAKRS,
  events,
});

export const useEvents = (admin, course_id, init = false) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const events = useSelector((state) =>
    get(state, ["events", "events", course_id], [])
  );
  const dispatch = useDispatch();

  const load = async (throws = null) => {
    try {
      setLoading(true);
      const list = await GET(
        admin ? `/event/admin/${course_id}/all` : `/event/${course_id}/all`
      );
      dispatch(eventState__setList(course_id, list));
      return list;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init && load();
    // eslint-disable-next-line
  }, [admin, course_id, init]);

  return [events, loading, error, { load, clearError }];
};

export const useAdminEventGroups = (eventId) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const groups = useSelector((s) => s.events.groups[eventId] || []);
  const dispatch = useDispatch();

  const load = async (eventID = null, throws = null) => {
    eventID = eventID || eventId;
    try {
      if (loading) return;
      setLoading(true);
      const { event, groups: g } = await GET(
        `/event/admin/id/${eventID}/groups`
      );
      dispatch(eventState__add(event.course_id, event));
      dispatch(eventState__setGroups(eventID, g));
      return [event, g];
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  const assign = async (groupId, eventID = null, throws = null) => {
    eventID = eventID || eventId;
    try {
      if (loading) return;
      setLoading(true);
      const { event, groups: g } = await PUT(
        `/event/admin/id/${eventID}/group/${groupId}`
      );
      dispatch(eventState__add(event.course_id, event));
      dispatch(eventState__setGroups(eventID, g));
      return [event, g];
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  const revoke = async (groupId, eventID = null, throws = null) => {
    eventID = eventID || eventId;
    try {
      if (loading) return;
      setLoading(true);
      const { event, groups: g } = await DELETE(
        `/event/admin/id/${eventID}/group/${groupId}`
      );
      dispatch(eventState__add(event.course_id, event));
      dispatch(eventState__setGroups(eventID, g));
      return [event, g];
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return [groups, loading, error, { load, assign, revoke, clearError }];
};

export const useFilteredEvents = (admin, course_id = null, init = false) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const events = useSelector((state) =>
    null === course_id ? [] : state.events?.events?.[course_id] ?? []
  );
  const dispatch = useDispatch();
  /**
   *
   * @param {Object} filters
   * @param {string|Date} [filters.starts]
   * @param {string|Date} [filters.ends]
   * @param {string|number} [filters.room_id]
   * @param {string|trainer} [filters.trainer]
   * @param throws
   * @return {any}
   */
  const load = async (filters = {}, throws = null) => {
    try {
      setLoading(true);
      filters.starts = filters.starts || new Date();
      if (filters.starts && filters.starts instanceof Date) {
        filters.starts = formatISO(filters.starts);
      }
      if (filters.ends && filters.ends instanceof Date) {
        filters.ends = formatISO(filters.ends);
      }

      const list = await POST(
        admin
          ? course_id
            ? `/event/admin/${course_id}/filtered`
            : `/event/admin/filtered`
          : `/event/${course_id}/filtered`,
        filters
      );
      dispatch(eventState__setList(course_id, list));
      return list;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    init && load();
    // eslint-disable-next-line
  }, [admin, course_id, init]);

  return [events, loading, error, { load, clearError }];
};

export const useEvent = (admin, event_id, full = false, init = false) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [course, setCourse] = useState(null);
  const events = useSelector((s) => s.events.events);
  const dispatch = useDispatch();

  const load = async (throws = null) => {
    try {
      setLoading(true);
      let url = `/event${admin ? "/admin" : ""}/id/${event_id}`;
      if (full) {
        url += "/full";
      }
      const event = await GET(url);
      if (full) {
        const cid = event.event.course_id;
        dispatch(eventState__add(cid, event.event));
        dispatch(courseState__add(event.course));
        dispatch(clubState__add(event.club));
        event.room && dispatch(roomState__add(event.room));
        setCourse(cid);
        return event.event;
      } else {
        dispatch(eventState__add(event.course_id, event));
        setCourse(event.course_id);
        return event;
      }
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init && load();
    // eslint-disable-next-line
  }, [admin, event_id, init]);
  // eslint-disable-next-line
  return [
    course ? events[course].find((e) => e.event_id == event_id) || null : null,
    loading,
    error,
    { load, clearError },
  ];
};

export const useEventCreation = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const create = async (params, throws = null) => {
    try {
      setLoading(true);
      const { events, failed_events, collisions } = await PUT(
        "/event/admin",
        params
      );
      console.debug("created events", events, failed_events);
      if (events?.length && !collisions?.length) {
        dispatch(eventState__add(events[0].course_id, ...events));
      }

      return { events, failed_events, collisions };
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return [loading, error, { create, clearError }];
};

export const useEventUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const update = async (eventId, params, throws = null) => {
    try {
      setLoading(true);
      const { event, collisions } = await POST(
        `/event/admin/${eventId}`,
        params
      );
      if (!collisions?.length) {
        dispatch(eventState__add(event.course_id, event));
      }
      return { event, collisions };
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, clearError, update };
};

export const useEventAutomation = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const update = async (eventId, params, throws = null) => {
    try {
      setLoading(true);
      const event = await POST(`/event/admin/${eventId}/automation`, params);
      dispatch(eventState__add(event.course, event));
      return event;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, clearError, update };
};

export const useEventRemoval = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const remove = async (event_id, throws = null) => {
    try {
      setLoading(true);
      if (isArray(event_id)) {
        const { events, reason } = await POST(
          "/event/admin/remove",
          event_id.map((event) => event?.event_id ?? event)
        );
        if (!reason && events?.length) {
          dispatch(eventState__remove(events[0].course_id, ...events));
        }
        return { events, reason };
      }
      const { event, reason } = await DELETE("/event/admin/" + event_id);
      !reason && dispatch(eventState__remove(event.course_id, event));
      return { event, reason };
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return [loading, error, { remove, clearError }];
};

export const usePureEvents = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const eventList = useSelector((s) => s.events.list || []);
  const dispatch = useDispatch();

  const reset = () => dispatch(eventState__setPure([]));

  const getNextWeek = async (throws = null) => {
    try {
      setLoading(true);
      const { events, notifications } = await GET("/event/next/week");
      dispatch(eventState__setPure(events));
      notifications.length &&
        dispatch(eventState__updateNotification(notifications));
      return events;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  return [eventList, loading, error, { getNextWeek, clearError, reset }];
};

export const useEventWeekView = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [ids, setIds] = useState([]);
  const events = useSelector((s) =>
    ids.map((id) => s.events.eventMap[id]).filter(Boolean)
  );
  const dispatch = useDispatch();

  const load = async (base = null, throws = null) => {
    try {
      setLoading(true);
      if (base instanceof Date) {
        base = formatISO(base);
      }
      const { events, notifications } = await GET(
        base ? `/event/week/${base}` : "/event/week"
      );
      setIds(events.map((event) => event.event_id));
      events?.length && dispatch(eventState__setPure(events));
      notifications?.length &&
        dispatch(eventState__updateNotification(notifications));
      return { events, notifications };
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, clearError, events, load };
};

export const usePureEvent = (eventId, subscribe = false, onChange = null) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [event, setEvent] = useState(null);
  const dispatch = useDispatch();

  const load = async (eventID = null, throws = null) => {
    try {
      setLoading(true);
      const { event: res, notification } = await GET(
        `/event/id/${eventID || eventId}`
      );
      setEvent((ev) => {
        onChange && onChange(ev, res);
        return res;
      });
      dispatch(
        eventState__updateNotification(notification ?? eventID ?? eventId)
      );
      return res;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  useSubscription(
    SUBSCRIPTIONS.EVENT_UPDATE,
    (nextEvent, eId) => {
      if (subscribe && eventId && eId === eventId) {
        load();
      }
    },
    [eventId ?? 0]
  );
  return [event, loading, error, { load, clearError, setEvent }];
};

export const useEventActiveToggle = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const setActive = async (eventId, active, throws = null) => {
    try {
      setLoading(true);
      const event = await POST(`/event/admin/${eventId}/active`, { active });
      dispatch(eventState__add(event.course_id, event));
      return event;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, clearError, setActive };
};

export const useEventRegistration = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const signIn = async (eventId, throws = null) => {
    try {
      setLoading(true);
      const response = await POST(`/event/id/${eventId}/sign/in`);
      dispatch(
        eventState__add(response?.event?.course_id ?? 0, response.event)
      );
      dispatch(eventState__setRegistered(response?.event?.event_id ?? 0, true));
      return response;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  const signOut = async (eventId, throws = null) => {
    try {
      setLoading(true);
      const response = await POST(`/event/id/${eventId}/sign/out`);
      dispatch(eventState__add(response.event.course_id ?? 0, response.event));
      dispatch(
        eventState__setRegistered(response?.event?.event_id ?? 0, false)
      );
      return response;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  const signState = async (eventId, throws = null) => {
    try {
      setLoading(true);
      const { event, state } = await GET(`/event/id/${eventId}/sign/state`);
      dispatch(eventState__add(event?.course_id ?? 0, event));
      dispatch(eventState__setRegistered(eventId, state));
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, clearError, signIn, signOut, signState };
};

export const useEventApprox = (roomId) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [events, setEvents] = useState(null);

  const load = async (roomID = null, throws = null) => {
    try {
      setLoading(true);
      const foundEvents = await GET(
        `/event/admin/approx/room/${roomID || roomId}`
      );
      setEvents(foundEvents);
      return foundEvents;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return [
    events,
    loading,
    error,
    { load, clearError, clearList: setEvents.bind(null, null) },
  ];
};

export const useVoucherConfirm = (event = null) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [_event, setEvent] = useState(event);

  const confirmVoucher = async (eventId, throws = null) => {
    try {
      setLoading(true);
      const confirmEvent = await POST(`/event/id/${eventId}/use-voucher`);
      setEvent(confirmEvent);
      return confirmEvent;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { event: _event, loading, error, clearError, confirmVoucher };
};

export const useEventAccessUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);

  const updateAccess = async (eventId, tokenId = null, throws = null) => {
    try {
      setLoading(true);
      let url = `/stream/access/event/${eventId}`;
      if (tokenId) {
        url += `/token/${tokenId}`;
      }
      const { access, token } = await GET(url);
      setStreamToken(token);
      return access;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, clearError, updateAccess };
};

export const useEventGrouping = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);

  const load = async (couseId, params = {}, throws = null) => {
    try {
      setLoading(true);
      if (params?.since && params.since instanceof Date) {
        params.since = formatISO(params.since);
      }
      if (params?.till && params.till instanceof Date) {
        params.till = formatISO(params.till);
      }
      return await POST(`/event/admin/grouped/list/${couseId}`, params);
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, clearError, load };
};

export const useEventGroupingDetail = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);

  const load = async (eventId, params = {}, throws = null) => {
    try {
      setLoading(true);
      if (params?.since && params.since instanceof Date) {
        params.since = formatISO(params.since);
      }
      if (params?.till && params.till instanceof Date) {
        params.till = formatISO(params.till);
      }
      return await POST(`/event/admin/grouped/event/${eventId}`, params);
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, clearError, load };
};

export const useAdminChatActive = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();

  const active = async (eventId, active, throws = null) => {
    try {
      setLoading(true);
      const event = await POST(`/event/admin/${eventId}/active`, { active });
      dispatch(eventState__add(event.course_id ?? 0, event));
      return event;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, clearError, active };
};

export const useEventNotification = (eventId = null) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const eventNotification = useSelector((s) =>
    eventId ? s.events.notifications[eventId] ?? null : null
  );
  const dispatch = useDispatch();

  const update = async (eventId, active, throws = null) => {
    try {
      setLoading(true);
      const { notification, event_id, state } = await POST(
        `/event/${eventId}/notify`,
        {
          active,
        }
      );
      dispatch(eventState__updateNotification(state ? notification : event_id));
      return { notification, event_id, state };
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return {
    notification: eventNotification,
    loading,
    error,
    clearError,
    update,
  };
};

export const useEventBookmarks = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const dispatch = useDispatch();
  const events = useSelector((s) => s.events.bookmarks);

  const load = async (throws = null) => {
    try {
      setLoading(true);
      const { events, notifications } = await GET("/event/bookmarked");
      dispatch(eventState__setBookmarks(events));
      dispatch(eventState__updateNotification(notifications));
      return { events, notifications };
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { events, loading, error, clearError, load };
};

export const useLastOfKindEvents = (init = false, dispatchResponse = false) => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [events, setEvents] = useState([]);
  const dispatch = useDispatch();

  const load = async (throws = null) => {
    try {
      setLoading(true);
      const res = await GET("/stats/last-event-of-kind");
      dispatchResponse && dispatch(eventState__setPure(res));
      setEvents(res);
      return res;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init && load();
  }, [init]);

  return { lastEvents: events, loading, error, clearError, load };
};
