import React from "react";

const StreamLevelResolution = ({ level, p, closures }) => {
  if (!level) return null;
  const width = level.width ?? 0;
  const height = level.height ?? 0;
  if (!width || !height) return null;
  let ret = "";
  if (p) {
    ret = `${height}p`;
  } else {
    ret = `${width}x${height}`;
  }
  return closures ? `(${ret})` : ret;
};

export default StreamLevelResolution;
