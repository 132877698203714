import {COURSE_ACTIONS} from "../actions/courseActions";

const defaultState = {
	courses: [],
	groups: {}
}

export const courseReducer = (state = defaultState, {type, courses, groups, courseId}) => {
	switch (type) {
		case 'RESET': return defaultState
		case COURSE_ACTIONS.SET_LIST:
			courses = typeof courses === 'function' ? courses(state.courses) : courses
			return {
				...state,
				courses
			}
		case COURSE_ACTIONS.SET_GROUPS:
			if (typeof groups === 'function') groups = groups(state.groups[courseId] || [])
			return {
				...state,
				groups: {
					...state.groups,
					[courseId]: groups
				}
			}
		default: return state
	}
}