import React, { useMemo } from "react";

const levels = ["Bytes", "KB", "MB", "GB", "TB"];

const get_size = (value, level = 0) => {
  if (value > 1024) {
    return get_size(value / 1024, level + 1);
  }
  return [value, level, levels[level]];
};

const roundup = (value, round) => {
  const expo = Math.pow(10, round);
  return Math.round(value * expo) / expo;
};

export const Size = ({ value, round }) => {
  const [size, , levelText] = useMemo(() => get_size(value ?? 0), [value]);
  return `${roundup(size, round)} ${levelText}`;
};

Size.defaultProps = {
  round: 1,
};

export default Size;
