import React from "react";
import styled, { keyframes } from "styled-components/macro";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const EventPresentationAccessDenied = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <>
      <Wrapper>
        <InnerWrapper>
          {" "}
          <Icon
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11H7v-2h10v2z" />
          </Icon>
          <Message>
            {t(
              "components.pages.event.EventPresentationAccessDenied.denied",
              "Der Zugang wurde Ihnen verwehrt."
            )}
          </Message>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </InnerWrapper>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.99);
  display: flex;
`;

const Zoom = keyframes`
    from {opacity: 0; transform: scale(0);}
    to {opacity: 1; transform: scale(1);}
`;

const InnerWrapper = styled.div`
  margin: auto;
  padding: 4em 8em;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 1em;
  display: grid;
  gap: 1em;
  justify-items: center;
  position: relative;
  opacity: 0;
  transform: scale(0);
  animation: ${Zoom} 400ms forwards;
`;
const Icon = styled.svg`
  width: 4em !important;
  height: 4em !important;
  fill: darkred !important;
`;
const Message = styled.p``;
const CloseButton = styled(IconButton)`
  position: absolute !important;
  top: 0;
  right: 0;
`;
const CloseIcon = styled(Close)``;

export default EventPresentationAccessDenied;
