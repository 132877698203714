import React, { useEffect } from "react";
import { Image_Adobe_1 as bg } from "../../../images";
import styled from "styled-components/macro";
import { PageTitle } from "../../../tools";
import Background from "../../frame/Background";
import UserFrame, { Stripe } from "../../UserFrame";
import Header from "../../frame/Header";
import { useEventBookmarks } from "../../../actions/eventActions";
import { useSelector } from "react-redux";
import { Plate } from "../../../lib/style/Templates";
import { useMediaQuery } from "@material-ui/core";
import { intervalToDuration } from "date-fns";
import { ConnectedEventCard } from "../../../elements/card/EventCard";
import { formatDuration, formatRelative } from "../../../lib/date";
import { COLOR, FORMAT_RELATIVE_DATE } from "../../../constants";
import { sleep } from "../../../lib/myfetch";
import { useHistory } from "react-router";
import { HowToReg } from "@material-ui/icons";
import { de } from "date-fns/locale";
import Timeline, { TimeItem } from "../courses/Timeline";
import { useTranslation } from "react-i18next";

const _eventDisabled = (event) =>
  false === event?.happens ||
  (event?.registration_required &&
    event?.registration_deadline &&
    new Date(event.registration_deadline) < new Date() &&
    (event?.registration_numbers ?? 0) < (event?.registration_threshold ?? 0));

const _date = (event, today = null, trans = null) =>
  formatRelative(new Date(event.starts), today ?? new Date(), {
    locale: {
      ...de,
      formatRelative: (token) =>
        trans
          ? trans(`relative-date.${token}`, FORMAT_RELATIVE_DATE[token])
          : FORMAT_RELATIVE_DATE[token],
    },
    weekStartsOn: 1,
  });

const EventCard = ({ event, today: givenToday, showDate }) => {
  const { push } = useHistory();
  const today = givenToday ?? new Date();
  const startDate = new Date(event.starts);
  const endDate = new Date(event.ends);
  const { t } = useTranslation();
  const duration = intervalToDuration({
    start: startDate,
    end: endDate,
  });
  return (
    <ConnectedEventCard
      event={event}
      disabled={_eventDisabled(event)}
      date={showDate ? _date(event, today, t) : null}
      title={event?.course?.name ?? t("labels.course", "Kurs").capital()}
      trainer={event?.trainer ?? null}
      duration={formatDuration(duration)}
      onClick={async (e) => {
        await sleep(50);
        push(`/event/${event.event_id}`);
      }}
    >
      <EventItem>
        <HowToReg />
        <EventItemText>
          {event?.registration_threshold
            ? t("labels.application-required", "Anmeldung erforderlich")
            : t("labels.application-desired", "Anmeldung erwünscht")}
        </EventItemText>
      </EventItem>
    </ConnectedEventCard>
  );
};

const MobileView = ({ events, notifications, today }) => {
  return (
    <MobileList>
      {events.map((event) => {
        return (
          <EventCard
            key={event.event_id}
            event={event}
            showDate
            today={today}
          />
        );
      })}
    </MobileList>
  );
};

const _FullView = ({ events, notification, today, ...props }) => {
  const { t } = useTranslation();
  return (
    <Timeline alternates leftSide gapSpacing={120} useScroll {...props}>
      {events.map((event) => {
        return (
          <TimeItem key={event.event_id} date={_date(event, today, t)}>
            <EventCard event={event} today={today} />
          </TimeItem>
        );
      })}
    </Timeline>
  );
};
const FullView = styled(_FullView)`
  //margin-top: 12em;
  color: white;
`;

const BookmarkedPage = ({}) => {
  const { events, loading, load } = useEventBookmarks();
  const notifications = useSelector((s) => s.events.notifications);
  const { t } = useTranslation();
  useEffect(() => {
    load();
  }, []);
  const isMobile = useMediaQuery("(max-width: 960px)");
  return (
    <>
      <PageTitle
        title={t(
          "components.pages.bookmarked.BookmarkedPage.page-title",
          "Vorgemerkte Termine"
        )}
      />
      <Background
        background={bg}
        filter={"sepia(0.5) grayscale(0.5) brightness(0.55) blur(1px)"}
      />
      <UserFrame>
        <Stripe />
        <Header />
      </UserFrame>
      <InnerFrame>
        <Subheader>
          {t(
            "components.pages.bookmarked.BookmarkedPage.subheader",
            "Deine vorgemerkten Termine"
          )}
        </Subheader>
        {isMobile ? (
          <MobileView events={events} notifications={notifications} />
        ) : (
          <FullView events={events} notification={notifications} />
        )}
      </InnerFrame>
    </>
  );
};

const InnerFrame = styled.section`
  display: grid;
  gap: 5em;
  justify-content: center;
`;

const Subheader = styled.h2`
  // ${Plate};
  padding: 2em;
  color: ${COLOR.PRIMARY};
  text-align: center;
  font-size: 1.25em;
  margin: 1em auto 2em;
`;
const MobileList = styled.div`
  display: grid;
  gap: 50px;
  justify-content: center;
`;
const EventItem = styled.div`
  margin: 0 20px;
  display: grid;
  gap: 10px;
  grid-template-columns: auto auto;
  justify-content: start;
  align-items: center;
`;
const EventItemText = styled.div``;

export default BookmarkedPage;
