import React, { useMemo } from "react";
import bgs from "../../../images/fitnessBackgrounds";
import { useParams } from "react-router";
import Background from "../../frame/Background";
import UserFrame, { Stripe } from "../../UserFrame";
import Header from "../../frame/Header";
import styled from "styled-components/macro";
import ProfileData from "./ProfileData";
import ProfileActions from "./ProfileActions";
import { useLastClub } from "../../../actions/clubActions";
import { getImageUrl } from "../../../lib/methods";
import { PageTitle } from "../../../tools";
import { useTranslation } from "react-i18next";

const ProfileView = () => {
  const { bg: bgId } = useParams();
  const [club] = useLastClub();
  const { t } = useTranslation();
  const bgIndex = useMemo(
    () => parseInt(bgId) || Math.floor(Math.random() * bgs.length),
    [bgId]
  );
  const bg = club?.profile_image
    ? getImageUrl(club?.profile_image)
    : bgs[bgIndex];

  return (
    <>
      <PageTitle
        title={t(
          "components.pages.password.profile.ProfileView.page-title",
          "Profil"
        )}
      />
      <Background
        background={bg}
        filter={"sepia(0.5) grayscale(0.5) brightness(0.55) blur(1px)"}
      />
      <UserFrame>
        <Stripe />
        <Header />
      </UserFrame>
      <InnerFrame>
        <ProfileData />
        <ProfileActions />
      </InnerFrame>
    </>
  );
};

const InnerFrame = styled.section`
  display: grid;
  gap: 80px;
  justify-content: center;
`;

export default ProfileView;
