import React, { useEffect, useMemo } from "react";
import styled from "styled-components/macro";
import MyTimeline, { TimeItem } from "./Timeline";
import { ConnectedEventCard } from "../../../elements/card/EventCard";
import UserFrame, { Stripe } from "../../UserFrame";
import Background from "../../frame/Background";
import Header from "../../frame/Header";
import { useHistory, useParams } from "react-router";
import CourseIntro from "./CourseIntro";
import ScrollDown from "../../../elements/misc/ScrollDown";
import { usePureEvents } from "../../../actions/eventActions";
import { useLastClub } from "../../../actions/clubActions";
import { get } from "lodash";
import {
  formatDuration,
  formatRelative,
  intervalToDuration,
  startOfDay,
} from "date-fns";
import { de } from "date-fns/locale";
import { PortalProgressSpinner } from "../../../elements/progress/ProgressSpinner";
import { useMediaQuery } from "@material-ui/core";
import { sleep } from "../../../lib/myfetch";
import bgs from "../../../images/fitnessBackgrounds";
import { getImageUrl } from "../../../lib/methods";
import { HowToReg } from "@material-ui/icons";
import { FORMAT_RELATIVE_DATE } from "../../../constants";
import { useTranslation } from "react-i18next";

const CourseTimeLine = ({}) => {
  return (
    <>
      <UserFrame>
        <CourseView />
      </UserFrame>
    </>
  );
};
const getIndex = (index, add = 0) => {
  return (index + add) % bgs.length;
};

const _eventDisabled = (event) =>
  false === event?.happens ||
  (event?.registration_required &&
    event?.registration_deadline &&
    new Date(event.registration_deadline) < new Date() &&
    (event?.registration_numbers ?? 0) < (event?.registration_threshold ?? 0));

const processEvents = (events) => {
  const sections = [];
  let dayStart = null;
  for (const event of events) {
    const day = startOfDay(new Date(event.starts));
    if (dayStart === null || dayStart.valueOf() !== day.valueOf()) {
      sections.push({ starts: day, type: "header" });
      dayStart = day;
    }
    sections.push({ ...event, type: "event" });
  }
  return sections;
};

const CourseView = ({}) => {
  const { bg_id } = useParams();
  const [events, loading, , { getNextWeek }] = usePureEvents();
  const [club] = useLastClub();
  const { push, replace } = useHistory();
  const isMobile = useMediaQuery("(max-width: 960px)");
  useEffect(() => {
    getNextWeek();
  }, []);
  const background = useMemo(() => {
    if (club?.home_image) {
      return getImageUrl(club?.home_image);
    }
    let bgId = parseInt(bg_id);
    if (isNaN(bgId)) {
      bgId = Math.round(Math.random() * (bgs.length - 1));
    }
    return bgs[getIndex(bgId)];
  }, [bg_id, club?.home_image?.image_id]);
  const today = startOfDay(new Date());
  const { t } = useTranslation();
  const trans = t; // important for automated translation to ignore this field
  // const events = processEvents(rawEvents);
  return (
    <>
      <Background
        backgroundColor={"#444"}
        background={background}
        // filter={"sepia(0.5) grayscale(0.5) brightness(0.55) blur(1px)"}
      />
      <Stripe />
      <Header />
      <Wrapper>
        <InnerWrapper>
          <CourseIntro
            onClick={() =>
              replace(
                `/bg/${getIndex(
                  isNaN(parseInt(bg_id)) ? -1 : parseInt(bg_id),
                  1
                )}`
              )
            }
            clubName={
              club?.name ||
              t(
                "components.pages.courses.CourseTimeLine.your-group",
                "Deine Gruppe"
              )
            }
            description={club?.description}
          />
          <ScrollDown
            title={t(
              "components.pages.courses.CourseTimeLine.scrolldown-label",
              "Die nächsten Kurse"
            )}
          />
          {isMobile ? (
            <MobileList>
              {events.map((event, key) => {
                const duration = intervalToDuration({
                  start: new Date(event.starts),
                  end: new Date(event.ends),
                });
                return (
                  <ConnectedEventCard
                    event={event}
                    disabled={_eventDisabled(event)}
                    key={event?.event_id ?? key}
                    favorite={false}
                    showFavorite={false}
                    date={formatRelative(new Date(event.starts), today, {
                      locale: {
                        ...de,
                        formatRelative: (token) =>
                          trans(
                            `relative-date.${token}`,
                            FORMAT_RELATIVE_DATE[token]
                          ),
                      },
                      weekStartsOn: 1,
                    })}
                    title={get(
                      event,
                      "course.name",
                      t("labels.course", "Kurs").capital()
                    )}
                    trainer={get(event, "trainer", null)}
                    duration={formatDuration(duration, { locale: de })}
                    onClick={async (e) => {
                      await sleep(50);
                      push(`/event/${event.event_id}`);
                    }}
                  >
                    {event?.registration_required && (
                      <EventItem>
                        <HowToReg />
                        <EventItemText>
                          {event?.registration_threshold
                            ? t(
                                "labels.application-required",
                                "Anmeldung erforderlich"
                              ).capital()
                            : t(
                                "labels.application-desired",
                                "Anmeldung erwünscht"
                              ).capital()}
                        </EventItemText>
                      </EventItem>
                    )}
                  </ConnectedEventCard>
                );
              })}
            </MobileList>
          ) : (
            <MyTimeline
              alternates
              leftSide
              gapSpacing={isMobile ? 50 : 80}
              useScroll
            >
              {events.map((event) => {
                const duration = intervalToDuration({
                  start: new Date(event.starts),
                  end: new Date(event.ends),
                });
                return (
                  <TimeItem
                    // date={format(new Date(event.starts), DATE_TIMELINE_FORMAT, {
                    //   locale: de,
                    // })}
                    date={formatRelative(new Date(event.starts), today, {
                      locale: {
                        ...de,
                        formatRelative: (token) =>
                          trans(
                            `relative-date.${token}`,
                            FORMAT_RELATIVE_DATE[token]
                          ),
                      },
                      weekStartsOn: 1,
                    })}
                    key={event.event_id}
                  >
                    <ConnectedEventCard
                      event={event}
                      disabled={_eventDisabled(event)}
                      favorite={false}
                      showFavorite={false}
                      title={get(
                        event,
                        "course.name",
                        t("labels.course", "Kurs").capital()
                      )}
                      trainer={get(event, "trainer", null)}
                      duration={formatDuration(duration, { locale: de })}
                      onClick={async (e) => {
                        await sleep(50);
                        push(`/event/${event.event_id}`);
                      }}
                    >
                      {event?.registration_required && (
                        <EventItem>
                          <HowToReg />
                          <EventItemText>
                            {event?.registration_threshold
                              ? t(
                                  "labels.application-required",
                                  "Anmeldung erforderlich"
                                ).capital()
                              : t(
                                  "labels.application-desired",
                                  "Anmeldung erwünscht"
                                ).capital()}
                          </EventItemText>
                        </EventItem>
                      )}
                    </ConnectedEventCard>
                  </TimeItem>
                );
              })}
            </MyTimeline>
          )}
        </InnerWrapper>
      </Wrapper>
      {loading && <PortalProgressSpinner shadow bg={"dark"} />}
    </>
  );
};

const EventItem = styled.div`
  margin: 0 20px;
  display: grid;
  gap: 10px;
  grid-template-columns: auto auto;
  justify-content: start;
  align-items: center;
`;
const EventItemText = styled.div``;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  @media all and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
const InnerWrapper = styled.div`
  max-width: 960px;
  color: white;
  padding-top: 50px;
  width: 100%;
  margin-bottom: 100px;
  @media all and (max-width: 960px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const MobileList = styled.div`
  display: grid;
  gap: 50px;
  justify-content: center;
`;

export default CourseTimeLine;
