import { pick } from "lodash";
import { useState } from "react";
import { useError } from "./errorActions";
import { DELETE, GET, POST } from "../lib";
import { SUBSCRIPTIONS, useSubscription } from "./index";
import { BUILD, BUILD_DATE } from "../constants";

export const STREAM_ACTION = {
  SET: "SET_STREAM",
};

export const streamState__set = (data) => ({ type: STREAM_ACTION.SET, data });
export const streamState__add = (...stream) =>
  streamState__set((base) => {
    const data = {};
    for (const item of stream) {
      data[item.stream_id] = item;
    }
    return { ...base, ...data };
  });
export const streamState__remove = (...stream) =>
  streamState__set((base) =>
    pick(
      base,
      stream.map((s) => Number(s.stream_id || s))
    )
  );

export const useWifiControl = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);

  const update = async (roomId, ssid, password, throws = null) => {
    try {
      setLoading(true);
      return await POST("/room/wifi/" + roomId, { ssid, password });
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  const ping = async (roomId, throws = null) => {
    try {
      setLoading(true);
      return await GET("/room/wifi/" + roomId);
    } catch (e) {
      console.error(e);
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return [loading, error, { update, clearError, ping }];
};

export const useRemoteControl = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);

  const reboot = async (roomId, throws = null) => {
    try {
      setLoading(true);
      return await POST("/room/reboot/" + roomId);
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  const shutdown = async (roomId, throws = null) => {
    try {
      setLoading(true);
      return await POST("/room/shutdown/" + roomId);
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return [loading, error, { reboot, shutdown, clearError }];
};

export const useStreamControl = () => {
  const [loadingStart, setLoadingStart] = useState(false);
  const [loadingStop, setLoadingStop] = useState(false);
  const [loadingPing, setLoadingPing] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loadingStatusRoom, setLoadingStatusRoom] = useState(false);
  const [error, handle, clearError] = useError(true);

  const start = async (roomId, eventId = null, throws = null) => {
    try {
      setLoadingStart(true);
      let url = "/stream/start/" + roomId;
      if (eventId) {
        url += `/event/${eventId}`;
      }
      return await GET(url);
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoadingStart(false);
    }
  };

  const stop = async (roomId, eventId = null, throws = null) => {
    try {
      setLoadingStop(true);
      let url = "/stream/stop/" + roomId;
      if (eventId) {
        url += `/event/${eventId}`;
      }
      return await GET(url);
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoadingStop(false);
    }
  };

  const ping = async (roomId, throws = null) => {
    try {
      setLoadingPing(true);
      const { ok, reason, ping } = await GET("/stream/ping/" + roomId);
      return [ok, ok ? ping : reason];
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoadingPing(false);
    }
  };

  const pingRaw = async (roomId, throws = null) => {
    try {
      setLoadingPing(true);
      const { ok, reason, ping } = await GET("/stream/ping/" + roomId + "/raw");
      return [ok, ok ? ping : reason];
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoadingPing(false);
    }
  };

  const status = async (roomId, throws = null) => {
    try {
      setLoadingStatus(true);
      const { ok, reason, data } = await GET("/stream/status/" + roomId);
      return [ok, ok ? data : reason];
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoadingStatus(false);
    }
  };

  const streamStatusRoom = async (roomId, throws = null) => {
    try {
      setLoadingStatusRoom(true);
      return await GET("/stream/local_status/room/" + roomId);
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoadingStatusRoom(false);
    }
  };

  return [
    loadingStart ||
      loadingStop ||
      loadingPing ||
      loadingStatus ||
      loadingStatusRoom,
    error,
    { start, stop, ping, pingRaw, status, streamStatusRoom, clearError },
    {
      loadingStart,
      loadingStop,
      loadingPing,
      loadingStatus,
      loadingStatusRoom,
    },
  ];
};

export const useCamereServerRestart = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);

  const restart = async (roomId, throws = null) => {
    try {
      setLoading(true);
      const { ok } = await POST("/room/restart/" + roomId);
      return ok;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, restart, clearError };
};

export const useStreamList = () => {
  const [streams, setStreams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);

  useSubscription(SUBSCRIPTIONS.STREAM_REMOVE, (s) => {
    // noinspection EqualityComparisonWithCoercionJS,JSUnresolvedVariable
    setStreams((list) => list.filter((st) => st.stream_id != s.stream_id));
  });

  /**
   *
   * @param {number} roomId
   * @param {object} params
   * @param {number} [params.event_id]
   * @param {number} [params.course_id]
   * @param {number} [params.process_id]
   * @param {string} [params.state]
   * @param {boolean|string} [params.process_exists]
   * @param {boolean|string} [params.persistent]
   * @param {number|string|Date} [params.starts]
   * @param {number|string|Date} [params.ends]
   * @param {null|boolean} throws
   * @return {Promise<null|any>}
   */
  const load = async (roomId, params = {}, throws = null) => {
    try {
      if (loading) return;
      setLoading(true);
      params = params || {};
      if (params.starts && params.starts instanceof Date) {
        params.starts = params.starts.getTime();
      }
      if (params.ends && params.ends instanceof Date) {
        params.ends = params.ends.getTime();
      }
      const result = await GET("/stream/list/" + roomId, params);
      setStreams(result);
      return result;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  const remove = async (streamId, forced = false, throws = null) => {
    try {
      setLoading(true);
      let url = "/stream/id/" + streamId;
      if (forced) {
        url += "/force";
      }
      const response = await DELETE(url);
      if ("stream" in response) {
        // noinspection JSUnresolvedVariable,EqualityComparisonWithCoercionJS
        setStreams((list) =>
          list.filter((s) => s.stream_id != response.stream.stream_id)
        );
      }
      if ("ok" in response) {
        return [response.ok, response.reason || ""];
      }
      return [false, "unknown"];
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  return [streams, loading, error, { load, remove, clearError }];
};

export const useStreamErrorReport = () => {
  const reportInternal = async (streamId, extras = {}) => {
    try {
      const res = await POST(`/stream/report/${streamId}`, {
        ...extras,
        "user-agent": navigator?.userAgent,
        "livedo-build": BUILD,
        "livedo-build-date":
          BUILD_DATE.toJSON?.() ??
          BUILD_DATE.toUTCString?.() ??
          BUILD_DATE.valueOf(),
      });
      console.debug("reported:", res);
    } catch (e) {
      console.error("Could not send error report:", e);
    }
  };
  const reportToken = async (streamId, token, extras = {}) => {
    try {
      const res = await POST(`/stream/report/token/${streamId}/${token}`, {
        ...extras,
        "user-agent": navigator?.userAgent,
        "livedo-build": BUILD,
        "livedo-build-date":
          BUILD_DATE.toJSON?.() ??
          BUILD_DATE.toUTCString?.() ??
          BUILD_DATE.valueOf(),
      });
      console.debug("reported:", res);
    } catch (e) {
      console.error("Could not send error report:", e);
    }
  };
  return { reportInternal, reportToken };
};
