import React, { useEffect } from "react";
import { useClubs } from "../actions/clubActions";
import { get } from "lodash";
import { LoaderIcon } from "../tools";
import { Flex } from "../lib";
import { Route, Switch, useRouteMatch } from "react-router";
import { LoginView } from "./Login";
import { CreateUserView } from "./CreateUser";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

export const ClubDetailView = ({ match }) => {
  const { t } = useTranslation();
  const tag = get(match, "params.tag", null);
  if (!tag) {
    return <div>{t("components.ClubDetail.", "Club-Tag nicht gefunden")}</div>;
  }
  return <ClubView tag={tag} />;
};

const getClub = (clubs, tag) =>
  tag ? clubs.find((c) => c.slug === tag) : null;

const LoginPage = ({ club }) => {
  return (
    <LoginPageWrapper>
      <LoginView club={club} />
    </LoginPageWrapper>
  );
};

const LoginPageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  justify-content: center;
  align-content: start;
  padding-top: 10vh;
`;

const ClubView = ({ tag }) => {
  const { path } = useRouteMatch();
  const [clubs, loading, , { loadClubs }] = useClubs();
  const club = getClub(clubs, tag);
  useEffect(() => {
    !club && loadClubs(tag);
    // eslint-disable-next-line
  }, [!club, tag]);
  if (loading) {
    return (
      <Flex center middle style={{ width: "100%", minHeight: "100vh" }}>
        <LoaderIcon size={50} heavy opacity={60} color={"red"} />
      </Flex>
    );
  }
  return (
    <>
      <Switch>
        <Route
          path={`${path}/login`}
          component={() => <LoginPage club={club} />}
        />
        <Route
          path={`${path}/create-user`}
          component={() => (
            <CreateUserView club={club} style={{ marginTop: "5%" }} />
          )}
        />
      </Switch>
    </>
  );
};
