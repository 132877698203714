import React, { useState } from "react";
import { Flex, PUT } from "../lib";
import { v4 } from "uuid";
import { LoaderButton } from "../tools";
import { Card, CardContent, CardHeader, TextField } from "@material-ui/core";
import { useError } from "../actions/errorActions";
import { useTranslation } from "react-i18next";

const useCreator = () => {
  const [loading, setLoading] = useState(false);
  const [error, handle, clearError] = useError(true);
  const [response, setResponse] = useState(null);

  const callee = async (data, throws = null) => {
    try {
      setLoading(false);
      const _response = await PUT("/club", data);
      setResponse(_response);
      return _response;
    } catch (e) {
      return handle(e, throws);
    } finally {
      setLoading(false);
    }
  };

  return [response, loading, error, { clearError, createClub: callee }];
};

export const CreateClubView = () => {
  const nameId = v4();
  const slugId = v4();
  const authId = v4();
  const [response, loading, error, { createClub }] = useCreator();
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [auth, setAuth] = useState("");
  const { t } = useTranslation();
  return (
    <>
      <style>
        {`body {
					background-color: #888;
					padding: 0;
					margin: 0;
				}`}
      </style>
      <Flex center middle style={{ height: "100vh", width: "100%" }} column>
        <Card>
          <CardHeader
            title={t(
              "components.CreateClub.create-title",
              "Neuen Club anlegen"
            )}
          />
          <CardContent>
            <Flex
              as={"form"}
              column
              onSubmit={(e) => {
                e.preventDefault();
                createClub({ name, slug, auth });
              }}
            >
              <div>
                <TextField
                  variant={"outlined"}
                  label={t("labels.name", "Name").capital()}
                  required
                  autoComplete={"new-name"}
                  id={nameId}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div style={{ marginTop: 20 }}>
                <TextField
                  variant={"outlined"}
                  label={t(
                    "components.CreateClub.slug-label",
                    "Slug"
                  ).capital()}
                  required
                  autoComplete={"new-slug"}
                  id={slugId}
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                />
              </div>
              <div style={{ marginTop: 20 }}>
                <TextField
                  variant={"outlined"}
                  label={t(
                    "components.CreateClub.auth-label",
                    "Auth"
                  ).capital()}
                  type={"password"}
                  required
                  autoComplete={"new-password"}
                  id={authId}
                  value={auth}
                  onChange={(e) => setAuth(e.target.value)}
                />
              </div>
              <Flex center style={{ marginTop: 20 }}>
                <LoaderButton loading={loading} type="submit">
                  {t(
                    "components.CreateClub.create-club-label",
                    "Club anlegen"
                  ).capital()}
                </LoaderButton>
              </Flex>
            </Flex>
          </CardContent>
        </Card>
      </Flex>
    </>
  );
};
CreateClubView.getRoute = (path) => "/club/create";
