import {VOUCHER_ACTIONS} from "../actions/voucherActions";

const defaultState = {
	list: []
}

export const voucherReducer = (state = defaultState, {type, vouchers}) => {
	switch(type) {
		case 'RESET':
			return defaultState
		case VOUCHER_ACTIONS.SET:
			if (typeof vouchers === 'function') vouchers = vouchers(state.list || [])
			return {
				...state,
				list: vouchers
			}
		default:
			return state
	}
}