import React, { useState } from "react";

import styled, { keyframes } from "styled-components/macro";
import { Button, TextField } from "@material-ui/core";
import { v4 } from "uuid";
import { useUserConfirmation, useUserLogout } from "../actions/userActions";
import { LoaderButton } from "../tools";
import { useSnackbar } from "notistack";
import GlobalStyle from "../lib/style/GlobalStyle";
import AppIcon from "../images/icon_fitness.png";
import { APP_NAME } from "../constants";
import { sleep } from "../lib/myfetch";
import { useTranslation } from "react-i18next";

const ConfirmForm = () => {
  const [code, setCode] = useState("");
  const { loadingConfirm, confirm } = useUserConfirmation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const formId = v4();
  const codeId = v4();
  return (
    <CodeWrapper
      id={formId}
      onSubmit={async (e) => {
        e.preventDefault();
        if (loadingConfirm) return false;
        await confirm(code, true);
        enqueueSnackbar(
          t("pages.ConfirmUserPage.welcome", "Willkommen bei {{name}}", {
            name: APP_NAME,
          })
        );
      }}
    >
      <Line>
        <TextField
          required
          variant={"outlined"}
          label={t("pages.ConfirmUserPage.your-code", "Dein Code")}
          autoComplete={"new-code"}
          id={codeId}
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
      </Line>
      <Line>
        <SubmitCodeButton
          type={"submit"}
          loading={loadingConfirm}
          color={"primary"}
          fullWidth
        >
          {t("pages.ConfirmUserPage.send", "Absenden")}
        </SubmitCodeButton>
      </Line>
    </CodeWrapper>
  );
};

const CodeWrapper = styled.form`
  margin: 30px 10px;
  display: inline-grid;
  gap: 15px;
  background: rgba(255, 255, 255, 0.7);
  padding: 30px 20px;
  border-radius: 10px;
  box-shadow: inset 0 0 1.5px 0.5px white, 0 5px 10px rgba(0, 0, 0, 0.8);
`;
const Line = styled.div``;
const SubmitCodeButton = styled(LoaderButton)``;

const ResendCodeButton = () => {
  const { loadingCode, requestCode } = useUserConfirmation();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <MarginWrapper>
      <ResendButton
        color={"secondary"}
        loading={loadingCode}
        onClick={async (e) => {
          if (loadingCode) return false;
          await requestCode(true);
          enqueueSnackbar(
            t(
              "pages.ConfirmUserPage.code-sent",
              "Ein neuer Code wurde Ihnen zugesendet"
            ),
            {
              variant: "success",
            }
          );
        }}
      >
        Neuen Code senden
      </ResendButton>
    </MarginWrapper>
  );
};

const ResendButton = styled(LoaderButton)`
  display: inline-block;
`;

const MarginWrapper = styled.div`
  padding: 30px 20px;
`;

const Logout = () => {
  const [, , , { logoutUser }] = useUserLogout();
  const { t } = useTranslation();
  return (
    <LogoutButton
      color={"secondary"}
      onClick={async () => {
        await sleep(400);
        await logoutUser();
      }}
    >
      {t("pages.ConfirmUserPage.logout", "Abmelden")}
    </LogoutButton>
  );
};
const LogoutButton = styled(Button)`
  margin: 30px 20px !important;
`;

const ConfirmUserPage = ({ user }) => {
  const { t } = useTranslation();
  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <Title>
          <IconWrapper>
            <Icon src={AppIcon} alt={APP_NAME} />
          </IconWrapper>
          <TitleWrapper>
            <Appname>{APP_NAME}</Appname>
            <br />
            &nbsp;
          </TitleWrapper>
        </Title>
        <Subtitle>
          {t(
            "pages.ConfirmUserPage.subtitle-confirm",
            "Bestätige deine E-Mail"
          )}
        </Subtitle>
        <Info>
          {t(
            "pages.ConfirmUserPage.info-confirm",
            "Bitte bestätige deine E-Mailadresse. Dazu haben wir dir eine E-Mail mit einer Nummer geschickt."
          )}
        </Info>
        <Info>
          {t(
            "pages.ConfirmUserPage.info-spam",
            "Schaue auch in deinem Spam-Ordner nach."
          )}
        </Info>
        <ConfirmForm />
        <Info>
          {t(
            "pages.ConfirmUserPage.info-howto",
            "Solltest du keinen Code erhalten haben, klicke unten auf den Button und wir senden dir einen Neuen zu."
          )}
        </Info>
        <ResendCodeButton />
        <Info>{t("pages.ConfirmUserPage.or", "oder")}</Info>
        <Logout />
      </Wrapper>
    </>
  );
};

const LineAnimation = keyframes`
    from {
        transform: scaleY(.1);
    }
    to {
        transform: scaleY(1);
    }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 960px;
  margin: 0 auto 100px auto;
  @media all and (max-width: 1060px) {
    padding: 0 20px;
  }
  &:before {
    position: absolute;
    display: block;
    content: "";
    left: -3%;
    top: 0;
    background: #c5d60c;
    width: 2px;
    height: calc(100vh - 100px);
    border-radius: 1px;
    transform-origin: top;
    animation: ${LineAnimation} 60s infinite alternate;
    @media all and (max-width: 1060px) {
      left: 5px;
    }
  }
`;
const IconWrapper = styled.div`
  display: inline-flex;
  padding: 0.8rem;
  background: #c5d60c;
  clip-path: circle(calc(50% + 4px));
`;
const Icon = styled.img`
  width: 3rem;
  height: 3rem;
  backdrop-filter: drop-shadow(10px 10px black);
`;
const Title = styled.h1`
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.2rem;
  display: grid;
  gap: 20px;
  grid-template-columns: min-content auto;
  align-items: center;
  margin-top: 40px;
  @media all and (max-width: 600px) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`;
const TitleWrapper = styled.div``;
const Appname = styled.span`
  display: inline-block;
  border-bottom: 1px solid #c5d60c;
`;
const Subtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 5rem;
`;
const Info = styled.p`
  line-height: 2rem;
  max-width: 500px;
  display: block;
`;
export default ConfirmUserPage;
